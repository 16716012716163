import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { useTranslation } from 'react-i18next';
import { StyledDiv } from '../TableHelpers/TableStyle';
import { ThemeProvider } from '@mui/material/styles';
import { team } from './TeamRegister';
import Table from '../Skeletons/Table';
import { getMuiTheme } from "../TableHelpers/options";
import { tableOptions } from '../TableHelpers/TableOptions';
import { useEffect } from 'react';

export type TeamsType = {
  isLoading: Boolean, 
  teamList: team[], 
  isFetching: boolean
}

export default function TeamList({
  isLoading, 
  teamList, 
  isFetching} : TeamsType) {
  const { t } = useTranslation('translation');

  const columns = [
    {
     name: "nome",
     label: t('name'),
     options: {
      filter: false,
      sort: true,
      sortThirdClickReset: true
     }
    },
    {
     name: "gerente",
     label: t('profiles.manager'),
     options: {
      filter: false,
      sort: true,
      sortThirdClickReset: true
     }
    },
    {
     name: "status",
     label: t('status'),
     options: {
      customBodyRender: (value: boolean) => (
        value ? <span className='tag is-primary'>{t('active')}</span> : <span className='tag is-danger'>{t('inactive')}</span>
      ),
      filter: false,
      sort: true,
      sortThirdClickReset: true
    }
    }
  ];

  const translatedTextLabels: MUIDataTableOptions = {
    textLabels: {
      body: {
        noMatch: `${t('table.noMatch')}`,
        toolTip: `${t('table.toolTip')}`,
        columnHeaderTooltip: column =>
          `${t('table.columnHeaderTooltip')} ${column.label}`
      },
      pagination: {
        next: `${t('table.next')}`,
        previous: `${t('table.previous')}`,
        rowsPerPage: `${t('table.rowsPerPage')}`,
        displayRows: `${t('table.displayRows')}`
      },
      toolbar: {
        search: `${t('table.search')}`,
        filterTable: `${t('table.filterTable')}`
      },
      filter: {
        title: `${t('table.title')}`,
        reset: `${t('table.reset')}`
      },
      viewColumns: {
        title: `${t('table.viewColumnsTitle')}`
      },
    },
  };

  useEffect(() => {
    console.log(teamList?.filter((elem: any) => elem?.nome?.includes('Notificação - Excelência Operacional')))
  },[teamList])

  return isLoading || isFetching ? (<Table />) : (
    <StyledDiv>
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={t('teamManage.teamList.tableTitle')}
          data={teamList}
          columns={columns}
          options={{...tableOptions, ...translatedTextLabels}}
        />
      </ThemeProvider>
    </StyledDiv>
  )
}