import styled from 'styled-components'

export const body = styled.body` 
  margin: 0;
  height: 100svh;
`

export const Container = styled.div`
  display: flex;
  background-color: #643E8F;
  align-items: center;
  justify-content: center;
  margin: 0;
  height: 100svh;
  button {
    min-width: 100%;
  }
`
export const Box = styled.div`
  margin-top: 3svh;
  margin-bottom: 5svh;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #513F78;
  max-height: 90svhs;
  max-width: 20dvw;
  min-width: 400px;
  border-radius: 5px;
  padding: 50px;
  box-shadow: 8px 8px 15px 5px rgba(37, 28, 58, 0.4);
  animation: go-down 1s;

  @keyframes go-down {
    from {
      transform: translateY(-500px);
    }
    to {
      transform: translateX(0);
    }
  }

  @media screen and (max-height: 600px){
  img {
      width: 120px;
    }
  }
  `

export const UnderLogoText = styled.h3`
  color: white;
  font-size: 16px;
  font-weight:500;
  text-align: center;
`
export const SmallText = styled.p`
  padding: 10px;
  color: white;
  font-size: 10px;
  text-align: center;
`
export const InputLogin = styled.input`
  margin: 5px 0;
  padding: 10px;
  border-radius: 0;
`

export const BtnResetPass = styled.button`
  align-content: center;
  display: flex;
  width: 100%;
  text-justify: center;
  padding: 10px;
  color: white;
  font-size: 10px;
  align-items: center;
  background: none;
  border: none;
`