import { Card } from '@material-ui/core';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { GenericHeader, ModalCard, ModalCardbody } from '../../../StyledComponents/Modal/generic';
import { FormExampleContainer } from '../FormStyle';
import { TitleText } from '../../Teams/style/RegisteredTeamsStyle';
import { PrintTemplateType } from '../Types';
import checkBrightness from '../../../helpers/checkBrightness';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { trackEventMatomo } from '../../../helpers/matomo';

export default function PrintPreviewCreateModal({
  printTemplate,
  image,
  printPreviewModal,
  setPrintPreviewModal,
  setCanShow,
}
  : {
    printTemplate: PrintTemplateType,
    image: string,
    printPreviewModal: Boolean,
    setPrintPreviewModal: Function,
    setCanShow: Function
  }) {
  const { t } = useTranslation('translation');

  const { trackEvent } = useMatomo()

  const componentRef = useRef(null);

  return (
    <Card>
      <TitleText>
        <div className={`modal ${printPreviewModal ? "modal is-active" : "modal"}`}>
          <div className="modal-background" onClick={() => {
            trackEventMatomo('PrintPreviewCreateModal', 'click', 'modal', 'fecha modal')
            setPrintPreviewModal(false);
            setCanShow(true);
          }}
          />
          <ModalCard className="modal-card" onSubmit={(e: React.FormEvent) => e.preventDefault()} style={{ width: '60%' }}>
            <header className="modal-card-head" style={{ display: "flex", flexDirection: "column" }}>
              <GenericHeader>
                Preview de impressão
              </GenericHeader>
            </header>
            <ModalCardbody className="modal-card-body">
              <div style={{
                backgroundColor: printTemplate.color,
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                padding: '10px',
              }}>
                {!printTemplate.logo ? <></> : (
                  <img alt="Logo da empresa" src={image} width={130} />
                )}
                <div style={{ backgroundColor: printTemplate.color, padding: '10px' }}>
                  <p
                    style={{
                      fontSize: `${printTemplate.headerText?.size}px`,
                      fontWeight: printTemplate.headerText?.bold ? 'bold' : 500,
                      fontStyle: printTemplate.headerText?.italic ? 'italic' : 'normal',
                      color: !printTemplate.color?.length ? 'black' : (checkBrightness(printTemplate?.color) ? 'black' : 'white'),
                      fontFamily: 'Sans Serif'
                    }}
                  >
                    {printTemplate.headerText?.text}
                  </p>
                </div>
              </div>
              <FormExampleContainer ref={componentRef}>
                <>
                  <h3 style={{
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontWeight: '600',
                    color: 'black',
                    display: 'flex'
                  }}>
                    {'Nome da empresa'}
                  </h3>
                  <div className='columns' style={{ alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-around', fontWeight: '600' }}>
                    <div className='column' style={{ alignItems: 'start', display: 'flex', flexDirection: 'column' }}>
                      <p style={{ fontSize: '14px' }}>{`Cliente`}</p>
                      <p style={{ fontSize: '14px' }}>{`Local:`}</p>
                      <p style={{ fontSize: '14px' }}>{`Conjunto:`}</p>
                      <p style={{ fontSize: '14px' }}>{`Unidade:`}</p>
                      <p style={{ fontSize: '14px' }}>{`Local Realizado:`}</p>
                      <p style={{ fontSize: '14px' }}>{`Scoring:`}</p>
                    </div>
                    <div className='column' style={{ alignItems: 'start', display: 'flex', flexDirection: 'column' }}>
                      <p style={{ fontSize: '14px' }}>{`Equipes:`}</p>
                      <p style={{ fontSize: '14px' }}>{`Usuário:`}</p>
                      <p style={{ fontSize: '14px' }}>{`Inicio:`}</p>
                      <p style={{ fontSize: '14px' }}>{`Fim:`}</p>
                      <p style={{ fontSize: '14px' }}>{`Aprovação:`}</p>
                    </div>
                  </div>

                </>
                <div>
                  <h1 style={{
                    width: '100%',
                    alignItems: 'center',
                    fontWeight: '600',
                    color: 'gray',
                    display: 'flex',
                    fontFamily: 'sans-serif',
                    textDecoration: 'underline',
                    fontSize: '20px',
                  }}>Nome do Item</h1>
                  <ul>
                    <h3 style={{
                      width: '100%',
                      alignItems: 'center',
                      fontWeight: '600',
                      color: 'gray',
                      display: 'flex',
                      fontFamily: 'sans-serif',
                      fontSize: '12px',
                    }}>(0) Nome do SubItem</h3>

                  </ul>
                </div>
              </FormExampleContainer>
              <div style={{ backgroundColor: printTemplate.color, padding: '10px' }}>
                <p
                  style={{
                    fontSize: `${printTemplate.footerText?.size}px`,
                    fontWeight: printTemplate.footerText?.bold ? 'bold' : 500,
                    fontStyle: printTemplate.footerText?.italic ? 'italic' : 'normal',
                    color: (!printTemplate.color ? 'black' : printTemplate.color && checkBrightness(printTemplate.color) ? 'black' : 'white')
                  }}
                >
                  {printTemplate.footerText?.text}
                </p>
              </div>
              <button
                style={{ marginTop: '10px' }}
                className='button is-danger'
                onClick={() => {
                  trackEventMatomo('PrintPreviewCreateModal', 'click', 'button', 'fecha modal')
                  setPrintPreviewModal(false)
                  setCanShow(true);
                }}
              >
                {t('close')}
              </button>
            </ModalCardbody>
          </ModalCard>
        </div>
      </TitleText>
    </Card>
  )
}
