import { Accordion, AccordionSummary } from '@material-ui/core';
import { useState } from 'react';
import { ChamadosListContainer, TitleChamado } from './Style';
import { ChamadoChecklist, chamadoConfigType, chamadoConfigTypeSubitem } from '../Filling/Types';
import { MdOutlineExpandMore } from 'react-icons/md';
import { trackEventMatomo } from '../../helpers/matomo';

type props = {
  chamados: ChamadoChecklist[] | undefined,
  chamadosConfig: chamadoConfigType | undefined | chamadoConfigTypeSubitem,
  chamadosObj: ChamadoChecklist[] | undefined,
}

export default function TicketsListComponent({ chamados, chamadosConfig }: props) {
  const [expanded, setExpanded] = useState(false);

  return (
    <div style={{ marginTop: '10px' }}>
      <Accordion expanded={expanded}>
        <AccordionSummary
          expandIcon={<MdOutlineExpandMore color="#18A689" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          IconButtonProps={{
            onClick: () => {
              trackEventMatomo('abrir chamados', 'click', 'acordeon', 'abre acordeon')
              setExpanded(!expanded)
            }
          }}
        >
          <div>
            <p>{`Chamados abertos: ${chamados ? chamados?.length : 0}`}</p>
            {chamadosConfig?.limiteChamado === 0 ? <p>Limite de chamados: Ilimitado</p> :
              <p>{`Limite de chamados: ${chamadosConfig ? chamadosConfig.limiteChamado : '-'}`}</p>
            }
          </div>
        </AccordionSummary>
        {chamados?.map((chamado, chamIndex) => (
          <ChamadosListContainer key={chamIndex} style={{ margin: '15px' }}>
            <TitleChamado style={{ fontFamily: 'sans-serif' }}>{chamado.selecionado?.nome}</TitleChamado>
            <div style={{ display: 'flex' }}>
              <p>Instrução:</p>
              <span>{chamado.textChamado || '---'}</span>
            </div>
            <div style={{ display: 'flex' }}>
              <p>Prazo:</p>
              <span>{chamado.dateChamado ? new Date(chamado.dateChamado).toLocaleString('pt-br') : '---'}</span>
            </div>
          </ChamadosListContainer>
        ))}
      </Accordion>
    </div>
  )
}
