import { Form } from "../../StyledComponents/Input/generic";
import React, { useCallback, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next";
import Clients from "../../service/Clients";
import AttToken from "../../helpers/attToken";
import { TextField } from "@mui/material";
import { useMask } from '@react-input/mask';
import { trackEventMatomo } from "../../helpers/matomo";

const clientsService = new Clients();

export type customer = {
  id: string,
  customerName: string,
  contato: string,
  status: string,
  createdAt: string,
}

export default function AddCustomer() {
  const [customerName, setCustomerName] = useState("");
  const [phone, setPhone] = useState("");
  const [isCustomerSaved, setIsCustomerSaved] = useState(false);
  const { t } = useTranslation('translation');
  const inputRef = useMask({ mask: '(__) ____-_____', replacement: '_' });

  const addNewCustomer = useCallback(async () => {
    try {
      const token = await AttToken()
      if (token) {
        const body = { ...token };
        await clientsService.createClient(body, {
          contato: phone,
          dataCadastro: Date.now(),
          empresa: body.userEmpresa,
          foto: ' ',
          listaGerencia: [],
          nome: customerName,
          responsavel: ' ',
          status: 'ativo',
          locais: [],
          tags: [],
        });
        setCustomerName('');
        setPhone('');
        setIsCustomerSaved(true);
        toast.success(t<string>('customerPage.registeredWaning'));
      }
    } catch (err) {
      console.log(err)
    }
  }, [customerName, phone, t])

  return (
    <Form onSubmit={(e: React.SyntheticEvent) => {
      e.preventDefault();
      addNewCustomer()
    }
    }>
      {isCustomerSaved && (
        <ToastContainer />
      )}
      <TextField
        onClick={() => trackEventMatomo('Adicionar cliente', 'click', 'input', 'editar valor')}
        label={t('name')}
        fullWidth
        style={{ marginBottom: '10px' }}
        inputProps={{ maxLenght: 40 }}
        value={customerName}
        type="text"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCustomerName(e.target.value)}
      />
      <TextField
       onClick={() => trackEventMatomo('Adicionar cliente', 'click', 'input', 'editar valor')}
        label={t('tel')}
        inputRef={inputRef}
        type='tel'
        fullWidth
        style={{ marginBottom: '10px' }}
        inputProps={{ maxLenght: 40 }}
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
      />
      <button
        onClick={() => trackEventMatomo('Adicionar cliente', 'click', 'button', 'salvar')}
        disabled={customerName === '' || phone.length < 14}
        className="button is-success is-small"
        type="submit"
      >
        {t('save')}
      </button>
    </Form>
  )
}