import { BroadcastChannel } from 'broadcast-channel';
import { toast } from 'react-toastify';
import Login from './service/Login';

const logoutService = new Login();
const logoutChannel = new BroadcastChannel('logout');

const loginData = localStorage.getItem('loginData');

async function logoutRealG() {
  toast.warn('Deslogando!')
  if (loginData) {
    const parsed = JSON.parse(loginData);
    await logoutService.logout(parsed?.userToken);
  }
  window.location.href = window.location.origin + "/login";
  localStorage.clear();
}

export const logout = async () => {
  logoutRealG()
  logoutChannel.postMessage('logout')
}

export const logoutAllTabs = () => {
  logoutChannel.onmessage = async (event) => {
    console.log(event)
    logoutRealG()
    !logoutChannel.isClosed && logoutChannel.close()
  }
}