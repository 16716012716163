import { Accordion, AccordionSummary, TextField } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useEffect, useState } from 'react';
import Pesquisa, { perguntasType, pesquisaType } from '../../service/Pesquisas';
import { useQuery } from '@tanstack/react-query';
import AttToken from '../../helpers/attToken';
import { FaEye } from 'react-icons/fa';
import ModalAlteranativas from './ModalAlteranativas';
import { useTranslation } from 'react-i18next';
import { Pagination, Skeleton } from '@mui/material';
import { trackEventMatomo } from '../../helpers/matomo';

const pesquisaService = new Pesquisa();

export default function ResearchesModels() {
  const { t } = useTranslation('translation');
  const [expanded, setExpanded] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [alteranativas, setAlternativas] = useState<string[]>([]);
  const [search, setSearch] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [paginated, setPaginated] = useState<pesquisaType[]>([]);

  const { data, isLoading } = useQuery({
    queryKey: ['GET_RESEARCHES'],
    queryFn: async () => {
      const token = await AttToken()
      if (token) {
        const response = await pesquisaService.getAll(token)
        return response
      }
    },
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  })

  const lastPageIndex = currentPage * itemsPerPage;
  const firstPageIndex = lastPageIndex - itemsPerPage;

  useEffect(() => {
    if (data) {
      const currentPageData = data?.sort((a: pesquisaType, b: pesquisaType) => { return a.dataCadastro < b.dataCadastro ? 1 : -1 })
        ?.filter((e: pesquisaType) => e.nome.toLowerCase().includes(search.toLowerCase()))
      search ? setPaginated(currentPageData?.slice(0, 5)) : setPaginated(currentPageData?.slice(firstPageIndex, lastPageIndex))
    }
  }, [firstPageIndex, lastPageIndex, data, search])

  let pages = [];

  for (let i = 1; i <= Math.ceil(data?.length / itemsPerPage); i++) {
    pages.push(i)
  }


  const tipoResposta = (value: string) => {
    switch (value) {
      case "alternativa":
        return 'Alternativa'
      case 'multiple':
        return 'Múltiplo'
      case 'textoCurto':
        return 'Texto curto'
      case 'textoLongo':
        return 'Texto longo'
      case 'horario':
        return 'Horário'
      case 'data':
        return 'Data'
      case 'numero':
        return 'Numero'
      case 'foto':
        return 'Foto'
      default: return ''
    }
  }

  return (
    <>
      <div style={{ padding: '0px 30px 0px 30px' }}>
        {!isLoading ? <>
          <div style={{ marginBottom: '5px' }}>
            {data.length > 5 &&
              <>
                <Pagination
                  key={Math.random()}
                  style={{ display: `${!pages?.length ? 'none' : ''}` }}
                  showFirstButton
                  showLastButton
                  shape="rounded"
                  variant="outlined"
                  count={pages?.length}
                  defaultPage={currentPage}
                  onChange={(eve, pageNumber) => {
                    setCurrentPage(pageNumber);
                    trackEventMatomo('Pesquisa legado', 'click', 'button', 'muda página')

                  }}
                />
                <TextField
                  onClick={() => trackEventMatomo('Pesquisa legado', 'click', 'input', 'edita valor')}
                  fullWidth
                  label="Pesquisar"
                  value={search}
                  onChange={(event) => setSearch(event.target.value.toLowerCase())}
                  style={{ marginBottom: '10px' }}
                />
              </>
            }
          </div>
          {paginated?.map((modelo: pesquisaType) => (
            <Accordion style={{ width: '100%', margin: '0px 0px 10px 0px' }} expanded={expanded === modelo.id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                IconButtonProps={{
                  onClick: () => {
                    trackEventMatomo('Pesquisa legado', 'click', 'acordeon', 'abre/fecha')
                    if (expanded === modelo.id) {
                      setExpanded('')
                    } else {
                      setExpanded(modelo.id);
                    }
                  }
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span style={{ color: '#18A689', fontWeight: 600, fontSize: '1.5em' }}>{modelo.nome}</span>
                  <span style={{ fontWeight: 300, fontSize: '.8em' }}>criado por: <span style={{ fontWeight: 500 }}>{modelo.userNome}
                  </span> em {new Date(modelo.dataCadastro).toLocaleString()}
                    &nbsp;versão: {modelo.versao}</span>
                </div>
              </AccordionSummary>
              <table className='table' style={{ width: '100%' }}>
                <thead style={{ backgroundColor: '#EEEEEE' }}>
                  <tr>
                    <th>Questão</th>
                    <th>Pergunta</th>
                    <th>Tipo de resposta</th>
                  </tr>
                </thead>
                <tbody>
                  {modelo.perguntas.map((pergunta: perguntasType, index) => (
                    <tr key={index}>
                      <ModalAlteranativas alternativas={alteranativas} openModal={openModal} setOpenModal={setOpenModal} />
                      <td width={'80px'} style={{ textAlign: 'center' }}>{pergunta.id}</td>
                      <td width={'74%'}>
                        {pergunta.questao}
                      </td>
                      <th>
                        {pergunta?.alternativas
                          && <button onClick={() => {
                            trackEventMatomo('Pesquisa legado', 'click', 'button', 'abre modal')
                            setAlternativas(pergunta.alternativas)
                            setOpenModal(!openModal)
                          }} className='button is-small'><FaEye size={25} /></button>}&nbsp;
                        {tipoResposta(pergunta.tipo)}
                      </th>
                    </tr>
                  ))
                  }
                </tbody>
              </table>
            </Accordion>
          ))}
        </>
          : <>
            <Skeleton height={60} width={300} />
            <Skeleton height={20} width={60} style={{ marginTop: '-.5em' }} />
            <Skeleton height={60} style={{ marginTop: '-.5em' }} />
            <Skeleton height={135} style={{ marginTop: '-1.8em' }} />
          </>}
      </div>
    </>

  )
}
