import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react'
import AttToken from '../../helpers/attToken';
import { AxiosError } from 'axios';
import Notifications from '../../service/Notifications';
import { useTranslation } from 'react-i18next';
import MUIDataTable, { MUIDataTableMeta, MUIDataTableOptions } from 'mui-datatables';
import { tableOptions } from '../TableHelpers/TableOptions';
import Table from '../Skeletons/Table';
import { GenericHeader } from '../../StyledComponents/Modal/generic';
import { BiPencil } from 'react-icons/bi';
import Button from '../Skeletons/Button';
import { toast } from 'react-toastify';
import { trackEventMatomo } from '../../helpers/matomo';



const notificationService = new Notifications();
export default function NotificationOldConfigs() {
  const [confirmationModal, setConfirmationModal] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentIdentifier, setCurrentIdentier] = useState('');
  const [confirmating, setConfirmating] = useState(false);
  const [isShowingFiltered, setIsShowingFiltered] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const { t } = useTranslation('translation');

  const {
    data,
    isLoading,
    isRefetching,
    refetch
  } = useQuery({
    queryKey: ['Configs'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const result = await notificationService.getConfigsByEmpresa(token);
        return result
      }
    },
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
    // refetchOnMount: 'always',
    onError: () => {
      console.log(AxiosError)
    }
  });

  useEffect(() => {
    if (confirmationModal && data && data.configs && data.configs.length) {
      const findCurrent = isShowingFiltered ? filteredData.find((e: any) => e.id === confirmationModal) : data.configs.find((e: any) => e.id === confirmationModal)
      setCurrentIdentier(findCurrent.identifier)
    }
  }, [confirmationModal, data, isShowingFiltered, filteredData]);

  const filterData = () => {
    const filtered = data.configs.filter((e: any) => data.similarFind && Object.keys(data.similarFind).includes(e.identifier));
    setFilteredData(filtered);
  }

  const columns = [
    {
      name: "checklistNome",
      label: 'Formulário',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "membrosNomes",
      label: 'Usuários que Recebem Essa Notifcação',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "clienteNome",
      label: 'Cliente',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "conjuntoNome",
      label: 'Conjunto',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "equipeNome",
      label: 'Equipe',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "localNome",
      label: 'Local',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "notifiNome",
      label: 'Nome da Notificação',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "equipeRecebeNome",
      label: 'Equipe que recebe a notificação',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "id",
      label: 'Editar Configuração',
      options: {
        customBodyRender: (value: string, tableData: MUIDataTableMeta) => {
          if (value) {
            return (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <button
                  className="button is-info"
                  onClick={() => {
                    trackEventMatomo('notificação legado', 'click', 'button', 'abre modal de edição')
                    setConfirmationModal(value)
                  }}
                >
                  <BiPencil />
                </button>
              </div>
            )
          }
        },
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
  ]

  const translatedTextLabels: MUIDataTableOptions = {
    textLabels: {
      body: {
        noMatch: `${t('table.noMatch')}`,
        toolTip: `${t('table.toolTip')}`,
        columnHeaderTooltip: column =>
          `${t('table.columnHeaderTooltip')} ${column.label}`
      },
      pagination: {
        next: `${t('table.next')}`,
        previous: `${t('table.previous')}`,
        rowsPerPage: `${t('table.rowsPerPage')}`,
        displayRows: `${t('table.displayRows')}`
      },
      toolbar: {
        search: `${t('table.search')}`,
        filterTable: `${t('table.filterTable')}`
      },
      filter: {
        title: `${t('table.title')}`,
        reset: `${t('table.reset')}`
      },
      viewColumns: {
        title: `${t('table.viewColumnsTitle')}`
      }
    }
  };
  return (
    <div>
      {isLoading || isRefetching ? <Button style={{ marginBottom: '10px' }} /> :
        <button
          style={{ marginBottom: '10px' }}
          className='button is-info'
          disabled={!data || !data.similarFind || !Object.keys(data.similarFind).length}
          onClick={() => {
            trackEventMatomo('tela de notificação legado', 'click', 'button', 'abre modal')
            if (isShowingFiltered) {
              setIsShowingFiltered(false);
            } else {
              filterData();
              setIsShowingFiltered(true);
            }
          }}>
          {(!data || !data.similarFind || !Object.keys(data.similarFind).length) ? 'Nenhuma Configuração duplicada' : isShowingFiltered ? 'Mostrar todas configurações' : 'Mostrar Apenas configurações duplicadas'}
        </button>
      }
      {confirmationModal && (
        <div className={`modal ${confirmationModal ? "modal is-active" : "modal"}`}>
          <div className="modal-background" onClick={() => setConfirmationModal('')}></div>
          <div className="modal-card">
            <header className="modal-card-head" style={{ display: "flex", flexDirection: "column" }}>
              <GenericHeader>
                <h1>Editando Configuração de Notificação</h1>
              </GenericHeader>
            </header>
            <div className="modal-card-body">
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                {data.similarFind && data.similarFind[currentIdentifier] && (
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', margin: '10px' }}>
                    <p>
                      Essa exata configuração se repete {data.similarFind[currentIdentifier].count} vezes
                    </p>
                    <button
                      className="button is-warning"
                      onClick={async () => {
                        trackEventMatomo('tela de notificação legado', 'click', 'button', 'salva')
                        setLoading(true);
                        const token = await AttToken();
                        if (token) {
                          await toast.promise(
                            notificationService.limpaRepetidas(token, currentIdentifier),
                            {
                              pending: 'Limpando Repetidas...',
                              success: 'Configurações Repeditas Deletadas!',
                              error: 'Erro!'
                            }
                          );
                        }
                        setLoading(false);
                        setConfirmationModal('');
                        refetch();
                        setConfirmating(false);
                        setIsShowingFiltered(false);
                      }}
                      disabled={loading}
                    >
                      Limpar Repetidas
                    </button>
                  </div>
                )}
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', margin: '10px' }}>
                  <button
                    className='button is-danger'
                    onClick={async () => {
                      trackEventMatomo('tela de notificação legado', 'click', 'button', 'salva')
                      if (confirmating) {
                        setLoading(true);
                        const token = await AttToken();
                        if (token) {
                          await toast.promise(
                            notificationService.deleteConfig(token, confirmationModal),
                            {
                              pending: 'Deletando configuração...',
                              success: 'Configuração deletada!',
                              error: 'Erro!'
                            }
                          );
                        }
                        setLoading(false);
                        setConfirmationModal('');
                        refetch();
                        setConfirmating(false);
                        setIsShowingFiltered(false);
                      } else {
                        toast.warning('Por favor confirme, essa ação é irreversível')
                        setConfirmating(true);
                      }
                    }}
                    disabled={loading || (data.similarFind && data.similarFind[currentIdentifier])}
                  >
                    {(data.similarFind && data.similarFind[currentIdentifier]) ? 'Antes de apagar, limpe os repetidos' : confirmating ? 'Confirmar (Essa Ação é ireversível)' : 'Deletar Configuração'}
                  </button>
                  { }
                  <button
                    onClick={() => {
                      setConfirmating(false);
                      setConfirmationModal('')
                    }}
                    className='button is-light'
                    disabled={loading}
                  >
                    Sair da Edição
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isLoading || isRefetching ? (<Table />) : <MUIDataTable
        title={'Lista de configurações de notificação legado'}
        data={data ? isShowingFiltered ? filteredData : data.configs : []}
        columns={columns}
        options={{ ...tableOptions, ...translatedTextLabels }}
      />}
    </div>
  )
}
