import {
  TbBoxMultiple1,
  TbBoxMultiple2,
  TbBoxMultiple3,
  TbBoxMultiple4,
  TbBoxMultiple5,
  TbBoxMultiple6,
  TbBoxMultiple7,
  TbBoxMultiple8,
  TbBoxMultiple9 } from "react-icons/tb";

import { RiCheckboxMultipleBlankFill } from "react-icons/ri";

export default function QttyComponent({ qtty, max }: { qtty: number, max?: boolean }) {
  if (max) {
    return <RiCheckboxMultipleBlankFill />
  }
  switch (qtty) {
    case 1:
      return <TbBoxMultiple1 />
    
    case 2:
      return <TbBoxMultiple2 />
    
    case 3:
      return <TbBoxMultiple3 />

    case 4:
      return <TbBoxMultiple4 />

    case 5:
      return <TbBoxMultiple5 />

    case 6:
      return <TbBoxMultiple6 />

    case 7:
      return <TbBoxMultiple7 />

    case 8:
      return <TbBoxMultiple8 />
  
    default:
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <TbBoxMultiple9 />
          +
        </div>
      );
  }
}
