import { BiError } from "react-icons/bi";
import { NotFoundImageDiv } from "../../Checklists/Modals/FormModal/FormModalStyle";
import ModalImagePreview from "../../Reports/ModalDetailPrint/ModalImagePreview";
import { Skeleton } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import Reports from "../../../service/Reports";
import { trackEventMatomo } from "../../../helpers/matomo";

const reportsService = new Reports();

const ImgsForDatails = ({ images }: { images: string[] }) => {

  const [returnedImg, setReturnedImg] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState<string>('');
  const [open, setOpen] = useState(false);

  const getImages = useCallback(async () => {
    setLoading(true)
    const response = await reportsService.getImages(images)
    if (response) {
      setReturnedImg(response)
      setLoading(false)
    }
  }, [images, reportsService])

  useEffect(() => {
    getImages()
  }, [getImages])

  return (loading) ? (<Skeleton width={'100px'} height={'200px'} style={{ marginTop: '-1.8em' }} />) : (
    <>
      {open &&
        <ModalImagePreview
          image={image}
          open={open}
          setOpen={setOpen}
        />}
      <div
        style={{
          overflow: 'auto',
          display: 'flex',
          margin: 0,
          flexWrap: 'wrap',
          backgroundColor: 'white'
        }}>
        {returnedImg && returnedImg?.map((e: string, i: number) => (
          e.length > 0 ?
            <div style={{ height: '150px', width: '120px' }} className='column is-2' key={i}>
              <img
                style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                onClick={() => {
                  trackEventMatomo('imgem PA', 'click', 'img', 'abre imagem')
                  setOpen(true)
                  setImage(e)
                }}
                src={e}
                alt={`Subitem ${i}`}
                key={i} />
            </div>
            : (
              <NotFoundImageDiv className='column is-2' style={{ padding: '15px' }}>
                <BiError size={50} />
                <span>{'(imagem nao encontrada)'}</span>
              </NotFoundImageDiv>
            )
        ))}
      </div >
    </>
  )
}

export default ImgsForDatails;