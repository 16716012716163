import { useQuery } from '@tanstack/react-query'
import "./styles.css";
import AttToken from '../../helpers/attToken'
import PlanoDeAcao, { planoDeAcaoType } from '../../service/PlanoDeAcao'
import { FaRegSquareCheck } from "react-icons/fa6";
import { useContext, useEffect, useMemo, useState } from 'react';
import ModalAvalia from './modal/ModalAvalia';
import { Pagination, Skeleton, TextField } from "@mui/material";
import { BsEye } from 'react-icons/bs';
import { UserContext } from '../../context/UserContext';
import { toast } from 'react-toastify';
import { TabStyle } from '../TabsStyle/TabsStyle';
import { trackEventMatomo } from '../../helpers/matomo';

const planosService = new PlanoDeAcao()

export default function Historico() {
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState<number>(5);
  const [paginated, setPaginated] = useState<planoDeAcaoType[]>([]);
  const [paginatedAva, setPaginatedAva] = useState<planoDeAcaoType[]>([]);
  const [selectedPlano, setSelectedPlano] = useState<planoDeAcaoType>();
  const [tab, setTab] = useState({
    isActiveObject: 'pendentes',
    object: ['pendentes', 'finalizadas']
  });

  const mapNames = {
    pendentes: 'Avaliação de Eficácia',
    finalizadas: 'Planos Avaliados',
  }

  const toggleClass = (index: number) => {
    setTab({ ...tab, isActiveObject: tab.object[index] })
  }

  const toggleActive = (index: number) => {
    if (tab.object[index] === tab.isActiveObject) {
      return "is-active"
    } else {
      return "inactve"
    }
  }

  const { userData } = useContext(UserContext);

  const { data, isLoading } = useQuery({
    queryKey: ['GET_HISTORICO'],
    queryFn: async () => {
      const token = await AttToken()
      if (token) {
        const response = await planosService.getListForInspector(token)
        return response
      }
    },
    refetchOnMount: 'always',
    refetchOnWindowFocus: false
  })

  const getAvaliacao = (value: string) => {
    switch (value) {
      case 'pendente':
        return <span className='tag is-warning'>Pendente</span>
      case 'malSucedido':
        return <span className='tag is-danger'>Malsucedido</span>
      case 'bemSucedido':
        return <span className='tag is-success'>Bem sucedido</span>
    }
  }

  const lastPageIndex = currentPage * itemsPerPage;
  const firstPageIndex = lastPageIndex - itemsPerPage;

  useEffect(() => {
    if (data) {
      const currentPageData = data?.planosFechados
        ?.filter((e: planoDeAcaoType) => e.nome
          ?.normalize("NFD")
          ?.replace(/[\u0300-\u036f]/g, "")
          ?.toLowerCase()
          ?.includes(search
            ?.normalize("NFD")
            ?.replace(/[\u0300-\u036f]/g, "")
            ?.toLowerCase()))
      search ? setPaginated(currentPageData?.slice(0, 5)) : setPaginated(currentPageData?.slice(firstPageIndex, lastPageIndex))
    }
    if (data?.planosAvaliados) {
      const currentPageDataAva = data?.planosAvaliados
        ?.filter((e: planoDeAcaoType) => e.nome
          ?.normalize("NFD")
          ?.replace(/[\u0300-\u036f]/g, "")
          ?.toLowerCase()
          ?.includes(search
            ?.normalize("NFD")
            ?.replace(/[\u0300-\u036f]/g, "")
            ?.toLowerCase()))
      search ? setPaginatedAva(currentPageDataAva?.slice(0, 5)) : setPaginatedAva(currentPageDataAva?.slice(firstPageIndex, lastPageIndex))
    }
  }, [firstPageIndex, lastPageIndex, data, search])

  let pages = [];
  let pagesAva = [];

  for (let i = 1; i <= Math.ceil(data?.planos?.filter((elem: any) => elem.nome?.includes(search))?.length / itemsPerPage); i++) {
    pages.push(i)
  }

  for (let i = 1; i <= Math.ceil(data?.planosAvaliados?.filter((elem: any) => elem.nome?.includes(search))?.length / itemsPerPage); i++) {
    pagesAva.push(i)
  }

  useMemo(() => {
    if (!userData.inspetorPA) {
      toast.error('Seu Usuário não é um Inspetor de Qualidade de Plano de Ação')
    }
  }, [userData.inspetorPA]);

  if (!isLoading && !data?.planosAvaliados?.length && !data?.planosFechados?.length) {
    return (
      <span className='spanInspetor'>
        Nenhum Plano de Ação no Historico!
      </span>
    )
  }

  return (
    <>
      {openModal && selectedPlano && <ModalAvalia
        openModal={openModal}
        setOpenModal={setOpenModal}
        plano={selectedPlano}
        setSelectedPlano={setSelectedPlano}
        tab={tab.isActiveObject as 'pendentes' | 'finalizadas'}
      />}
      {isLoading ? (
        <>
          <Skeleton style={{ marginBottom: '-.9em' }} height={20} width={'150px'} />
          <Skeleton style={{ marginBottom: '-3.8em' }} height={60} />
          <Skeleton height={250} />
        </>
      ) : <>
        <div className="tabs is-boxed">
          <ul>
            {tab.object.map((el, index) => (
              <li
                key={index}
                className={toggleActive(index)}
                onClick={() => {
                  trackEventMatomo('historico PA', 'click', 'li', 'muda aba')
                  toggleClass(index)
                }}>
                <TabStyle theme={toggleActive(index)}>{mapNames[el as keyof typeof mapNames]}</TabStyle>
              </li>
            ))}
          </ul>
        </div>
        {tab.isActiveObject !== 'pendentes' ?
          <>
            {data?.planosAvaliados &&
              <>
                <TextField
                  onClick={() => trackEventMatomo('historico PA', 'click', 'input', 'edita valor')}
                  fullWidth
                  sx={{ marginBottom: '10px' }}
                  label='Digite um nome de Plano para pesquisar...'
                  className='input inputSearch'
                  value={search.toLowerCase()}
                  onChange={((elem) => setSearch(elem.target.value
                    ?.normalize("NFD")
                    ?.replace(/[\u0300-\u036f]/g, "")
                    ?.toLowerCase()
                  ))}
                />
              </>
            }
            {pages?.length > 1
              && <Pagination
                style={{ display: `${!pages?.length ? 'none' : ''}`, marginTop: '5px' }}
                showFirstButton
                showLastButton
                shape="rounded"
                count={pagesAva?.length}
                defaultPage={currentPage}
                onChange={(eve, pageNumber) => {
                  trackEventMatomo('historico PA', 'click', 'button', 'muda página')
                  setCurrentPage(pageNumber);
                }}
              />}
            {data?.planosAvaliados ? paginatedAva?.map((elem: planoDeAcaoType, index: number) => (
              <div style={{ marginTop: '15px' }} className='box' key={index}>
                <div className="divItensTitle">
                  <div className="divTitleAcordeon">
                    <span className="titleAccordeon">
                      {elem.nome}
                    </span>
                    <span className="spanAcordeon">
                      Criado por <b>{elem.criadorNome ? elem.criadorNome : '-'}</b> em {new Date(Number(elem?.dataCriacao))
                        ?.toLocaleString('pt-BR')} a partir do checklist <b>{elem.idExecucao}</b>
                    </span>
                    <span className="spanAcordeon">
                      Responsável:&nbsp;<b>{elem?.responsavelNome}</b>
                    </span>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', width: '25%' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <span style={{ fontSize: '1.2em', color: 'grey', fontWeight: '500', marginRight: '5px' }}>Avaliação:</span>
                        {getAvaliacao(String(elem?.avaliacao))}
                      </div>
                      <span className='spanStatus' style={{ fontSize: '0.8em' }}>Data de Fechamento Do Plano</span>
                      <span className='tag' style={{ width: '100%' }}>{new Date(Number(elem?.dataFechamento))?.toLocaleString('pt-BR')}</span>
                      <span className='spanStatus' style={{ fontSize: '0.8em' }}>Data da Analise</span>
                      <span className='tag' style={{ width: '100%' }}>{new Date(Number(elem?.dataAvaliacao))?.toLocaleString('pt-BR')}</span>
                    </div>
                    <button
                      onClick={() => {
                        trackEventMatomo('historico PA', 'click', 'button', 'fecha modal')
                        setSelectedPlano(elem);
                        setOpenModal(!openModal)
                      }}
                      className='button'>
                      Ver Detalhes&nbsp;
                      <BsEye />
                    </button>
                  </div>
                </div>
              </div >
            )
            ) : <span className='spanInspetor'>
              Nenhum Plano No Historico
            </span>}
          </> :
          <>
            {data?.planosFechados &&
              <TextField
                onClick={() => trackEventMatomo('historico PA', 'click', 'input', 'edita valor')}
                fullWidth
                sx={{ marginBottom: '10px' }}
                label='Digite um nome de Plano para pesquisar...'
                className='input inputSearch'
                value={search.toLowerCase()}
                onChange={((elem) => setSearch(elem.target.value
                  ?.normalize("NFD")
                  ?.replace(/[\u0300-\u036f]/g, "")
                  ?.toLowerCase()
                ))}
              />
            }
            {pages?.length > 1
              && <Pagination
                style={{ display: `${!pages?.length ? 'none' : ''}`, marginTop: '5px' }}
                showFirstButton
                showLastButton
                shape="rounded"
                count={pages?.length}
                defaultPage={currentPage}
                onChange={(eve, pageNumber) => {
                  trackEventMatomo('historico PA', 'click', 'button', 'muda página')
                  setCurrentPage(pageNumber);
                }}
              />}
            {data?.planosFechados ? paginated?.map((elem: planoDeAcaoType, index: number) => (
              <div style={{ marginTop: '15px' }} className='box' key={index}>
                <div className="divItensTitle">
                  <div className="divTitleAcordeon">
                    <span className="titleAccordeon">
                      {elem.nome}
                    </span>
                    <span className="spanAcordeon">
                      Criado por <b>{elem.criadorNome ? elem.criadorNome : '-'}</b> em {new Date(Number(elem?.dataCriacao))
                        ?.toLocaleString('pt-BR')} a partir do checklist <b>{elem.idExecucao}</b>
                    </span>
                    <span className="spanAcordeon">
                      Responsável:&nbsp;<b>{elem?.responsavelNome}</b>
                    </span>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', width: '25%' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <span style={{ fontSize: '1.2em', color: 'grey', fontWeight: '500', marginRight: '5px' }}>Avaliação:</span>
                        {getAvaliacao(String(elem?.avaliacao))}
                      </div>
                      <span className='spanStatus' style={{ fontSize: '0.8em' }}>Data de Fechamento Do Plano</span>
                      <span className='tag' style={{ width: '100%' }}>{new Date(Number(elem?.dataFechamento))?.toLocaleString('pt-BR')}</span>
                    </div>
                    <button
                      onClick={() => {
                        trackEventMatomo('historico PA', 'click', 'button', 'fecha modal')
                        setSelectedPlano(elem);
                        setOpenModal(!openModal)
                      }}
                      className='button'>
                      Avaliar eficácia&nbsp;
                      <FaRegSquareCheck />
                    </button>
                  </div>
                </div>
              </div >
            )
            ) : <span className='spanInspetor'>
              Nenhum Plano No Historico
            </span>}
          </>}
      </>
      }
    </>
  )
}
