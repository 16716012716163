import styled from "styled-components";

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;
  width: 100%;
  padding: 2%;
`;

export const Button = styled.button`
  background-color: white;
  border: none;
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: #b1b1a9;
  font-weight: 400;
  font-size: 1em;
  height: 2.5em;
  align-items: center;
  border-bottom: 0.3em #1f94f1 solid;
  border-radius: 0.2em;
  margin-bottom: -5px;
`;

export const Input = styled.input`
  background-color: white;
  border: none;
  display: flex;
  width: 100%;
  height: 4em;
  border-radius: 0.3em;
  padding-left: 1em;
  outline: 0;
  font-size: small;
`;
