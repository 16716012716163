import styled from "styled-components";

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: #686b6d;
  border: 1px solid lightgray;
  margin: 2%;
  background-color: white;
  max-width: 80vw;
  padding: 20px;
  h1 {
    font-size: 18px;
    font-weight: bold;
    margin: 15px 0;
  }
`

export const StyledSelect = styled.select`
  padding: 10px;
  color: #686b6d;
  width: 60%;
  align-self: center;
  text-align: center;
  font-size: 15px;
  margin-bottom: 15px;
`

export const StyledCheckContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 8px;
  input {
    margin-right: 5px;
  }
`

export const DragNDrop = styled.li`
  font-size: large;
  width: 60%;
  align-content: flex-start;
  display: flex;
  background-color: #243747;
  border-radius: 5px;
  color: white;
  padding: 5px;
  margin: 5px;
  :hover {
    background-color: #72797b;
  }
  input {
    width: 1.3em;
    height: 1.3em;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid #ddd;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    align-self: center;
    :checked {
      /* background-color: #48c77c; */
      background-color: #3EC487;
    }
  }
  label {
    width: 100%;
    cursor: pointer;
    display: flex;
  }
`

export const StyledUl = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
`
