import styled from "styled-components";

export const Navbar = styled.nav`
  width: 100%;
  background-color: white;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px;
  flex-wrap: wrap;
  justify-content: space-between;
  height: fit-content;
  //float:right;
  .header-translate {
    h1 {
      color: grey;
    }
    button {
      margin: 0 30px 0 0;
      font-size: 18px;
    }
    .last {
      margin: 0;
    }
  }
`;

export const Button = styled.button`
  color: grey;
  border: none;
`;
export const NavbarStart = styled.div`
  display: flex;
  float: left;
  margin-left: 10px;
`;

export const NavbarEnd = styled.div`
  display: flex;
  //float:right;
  margin-right: 10px;
`;
