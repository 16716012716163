import styled from "styled-components";

export const ProfileContainer = styled.div`
  display: flex;
  width: fit-content;
  flex-direction: column;
  color: #686b6d;
  border: 1px solid lightgray;
  background-color: white;
  padding: 20px;
  h1 {
    text-align: left;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  span {
    text-align: left;
    font-size: 12px;
    font-weight: bold;
    color: gray;
  }

  input {
    margin: 10px 0;
    width: 45%;
    height: 25px;
    border: 1px solid lightgray;
    border-radius: 4px;
    text-align: center;
    background-color: white;
    transition: 0.5s;
  }
  input:focus {
    outline: none !important;
    border: 1px solid #47aa8d;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  button {
    border: none;
    height: 30px;
    background-color: #47aa8d;
    color: white;
    font-weight: bold;
    border-radius: 4px;
    margin-top: 7px;
    :hover {
      cursor: pointer;
    }
  }
`
