import { MainContainerAvaliation } from './Avaliacao/AvaliacaoStyle'
import { Container } from './Unique/UniqueStyle'
import { SubItemProps } from './Avaliacao/Avaliacao';
import SubItemPhoto from './SubItemPhoto';
import SubItemInputMask from './SubItemInputMask';

export default function CEP({ subItem, indexItem, onChange }: SubItemProps) {

  return (
    <MainContainerAvaliation>
      <Container>
        <SubItemInputMask onChange={onChange} subItem={subItem} indexItem={indexItem} mask={'99999-999'} />
        {subItem.ocorrencia &&  subItem.foto &&
          <SubItemPhoto subItem={subItem} indexItem={indexItem} onChange={onChange} />}
      </Container>
    </MainContainerAvaliation>
  )
}