import { Card } from '@material-ui/core';
import React, { useCallback, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { GenericHeader } from '../../../StyledComponents/Modal/generic';
import { TitleText } from '../../Teams/style/RegisteredTeamsStyle';
import { UserContext } from '../../../context/UserContext';
import { trackEventMatomo } from '../../../helpers/matomo';

export default function RemoveModal({
  openRemove,
  setOpenRemove,
  mutate,
  nameRemove,
}: {
  openRemove: boolean,
  setOpenRemove: Function,
  mutate: Function,
  nameRemove: string,
}) {
  const { t } = useTranslation('translation');
  const { hierarchyLabel } = useContext(UserContext);

  const escape = useCallback((e: any): void => {
    if (e.key === 'Escape' && openRemove) {
      setOpenRemove(false)
    }
  }, [openRemove, setOpenRemove])

  useEffect(() => {
    document.addEventListener('keydown', escape, true)
  }, [escape])

  const nameToRemove = () => {
    switch (nameRemove) {
      case 'local':
        return hierarchyLabel.local
      case 'conjunto':
        return hierarchyLabel.conjunto
      default: return (hierarchyLabel.unidade)
    }
  }

  return (
    <Card>
      <TitleText>
        <div className={`modal ${openRemove ? "modal is-active" : "modal"}`}>
          <div
            className="modal-background"
            onKeyDown={escape}
            onClick={() => {
              trackEventMatomo('Modal remover local', 'click', 'div', 'fechar modal')
              setOpenRemove(false)
            }} />
          <div className="modal-card" onSubmit={(e: React.FormEvent) => e.preventDefault()}>
            <header className="modal-card-head" style={{ display: "flex", flexDirection: "column" }}>
              <GenericHeader>
                {`${t('Managements.remove')}`} {nameToRemove()}?
              </GenericHeader>
            </header>
            <div className='modal-card-foot' style={{ display: "flex", justifyContent: "space-between" }}>
              <button
                className='button is-danger is-small'
                onClick={() => {
                  trackEventMatomo('Modal excluir local', 'click', 'button', 'salva')
                  mutate()
                }}>
                {t('delete')}
              </button>
              <button
                className='button is-small is-warning'
                onClick={() => {
                  trackEventMatomo('Modal excluir local', 'click', 'button', 'fecha')
                  setOpenRemove(false)
                }}>
                {t('cancel')}
              </button>
            </div>
          </div>
        </div>
      </TitleText>
    </Card>
  )
}
