import { useState } from 'react'
import ModalEditTicketsAfter from './ModalDetailPrint/ModalEditTicketsAfter';
import { addTicketType } from './ModalDetailPrint/ModalDetailPrint';
import { ChamadoChecklist, chamadoConfigType, chamadoConfigTypeSubitem } from '../Filling/Types';
import TicketsListComponent from './TicketsListComponent';
import { BiPencil } from 'react-icons/bi';
import { ChecklistReportType } from '../../service/Reports';
import { trackEventMatomo } from '../../helpers/matomo';

type props = {
  addingTickets: addTicketType | undefined,
  chamadosObj: ChamadoChecklist[] | undefined,
  chamadosConfig: chamadoConfigTypeSubitem | chamadoConfigType | undefined,
  formId: string,
  inicio: number,
  commingFromSub: boolean,
  chamadosConfigSub: chamadoConfigTypeSubitem | undefined,
  selected: ChecklistReportType
}

export default function TicketListReportComponent({
  addingTickets,
  chamadosObj,
  chamadosConfig,
  formId,
  inicio,
  commingFromSub,
  chamadosConfigSub,
  selected
}: props) {
  const initialChamados = chamadosObj ? [...chamadosObj] : []
  const [chamadosState, setChamadosState] = useState<ChamadoChecklist[]>(chamadosObj || []);
  const [modalOpen, setModalOpen] = useState(false);

  const ClearAndExit = () => {
    setChamadosState(initialChamados);
    setModalOpen(false);
  }

  const closeModal = () => setModalOpen(false);

  return (
    <div>
      {(!commingFromSub && chamadosConfig ?
        (chamadosConfig.limiteChamado > 0 && chamadosState && chamadosState.length >= chamadosConfig.limiteChamado) ?
          <button className='button' disabled>Este formulário atingiu o numero máximo de chamados</button> :
          <>
            <button
              className='button is-small'
              onClick={() => {
                trackEventMatomo('Relatórios', 'click', 'button', 'abre modal')
                setModalOpen(true);
              }}>
              Adicionar Chamados&nbsp;
              <BiPencil />
            </button>
          </> : chamadosConfigSub ?
          (chamadosConfigSub.limiteChamado > 0 && chamadosState && chamadosState.length >= chamadosConfigSub.limiteChamado) ?
            <button className='button' disabled>Este subitem atingiu o numero máximo de chamados</button> :
            <>
              <button
                className='button is-small'
                onClick={() => {
                  trackEventMatomo('Relatórios', 'click', 'button', 'abre modal')
                  setModalOpen(true);
                }}>
                Adicionar Chamados&nbsp;
                <BiPencil />
              </button>
            </>
          : <></>)}
      <div>
        <h1 style={{ fontSize: '1.1em', fontFamily: 'sans-serif', fontWeight: 500, color: 'gray' }}>Lista de chamados :</h1>
        {modalOpen
          && addingTickets
          && chamadosConfig &&
          <ModalEditTicketsAfter
            chamadosObj={chamadosObj}
            open={modalOpen}
            setOpen={closeModal}
            ClearAndExit={ClearAndExit}
            chamados={chamadosState}
            setChamados={setChamadosState}
            currentElement={addingTickets}
            chamadosConfig={chamadosConfig}
            inicio={inicio}
            formId={formId}
            selected={selected}
          />
        }
      </div>
      <TicketsListComponent chamadosObj={chamadosObj} chamados={chamadosState} chamadosConfig={commingFromSub ? chamadosConfigSub : chamadosConfig} />
    </div>
  )
}
