import styled from "styled-components";

export const IncativeH1 = styled.h1`
  background-color: darkred;
  border-radius: 3px;
  color: white;
  font-weight: bold;
`;

export const ActiveH1 = styled.h1`
  background-color: #47aa8d;
  border-radius: 3px;
  color: white;
  font-weight: bold;
`;
export const ActiveH1v2 = styled.h1`
  background-color: #3e8ed0;
  border-radius: 3px;
  color: white;
  font-weight: bold;
`;

export const EditButton = styled.button`
  background-color: #47aa8d;
  border: none;
  border-radius: 3px;
  color: white;
  align-self: center;
  transition: 0.25s;
  span {
    padding: 5px;
  }
  :hover {
    background-color: #58c78e;
    font-size: 1em;
  }
`;

export const StyledDiv = styled.div`
  button {
    div[class*="MUIDataTableHeadCell-"] {
      text-transform: none;
      font-weight: 500;
    }
  }
  span[class*="-MUIDataTableHeadCell-contentWrapper"] {
    justify-content: center;
    button {
      transition: 0.3s;
      :hover {
        background-color: #58c78e;
        color: white;
      }
    }
  }
`;
