import { useState } from 'react';
import { MainContainerAvaliation } from '../Avaliacao/AvaliacaoStyle'
import { Container } from '../Unique/UniqueStyle'
import ModalSignature from './ModalSignature'
import { SubItemProps } from '../Avaliacao/Avaliacao';
import ModalReassign from './ModalReassign';
import SubItemComentario from '../SubItemComentario';
import SubItemPhoto from '../SubItemPhoto';
import { trackEventMatomo } from '../../../../../helpers/matomo';

export default function Assinatura({ subItem, indexItem, onChange }: SubItemProps) {
  const [openModal, setOpenModal] = useState(false);
  const [olhar, setOlhar] = useState(false);
  const [openReassign, setOpenReassign] = useState(false);

  const clearSignature = () => {
    subItem.assinatura = undefined
  }

  const reassign = () => {
    setOpenReassign(false)
    trackEventMatomo('preenchimento de assinatura', 'click', 'button', 'fecha modal')
  }

  return (
    <MainContainerAvaliation>
      <Container>
        {openReassign && <ModalReassign open={openReassign} setOpen={reassign} setOpenModal={setOpenModal} clear={clearSignature} />}
        <ModalSignature
          setOlhar={setOlhar}
          setOpen={reassign}
          subItem={subItem}
          openModal={openModal}
          setOpenModal={setOpenModal}
          olhar={olhar}
          onChange={onChange}
        />
        {subItem.assinatura ? (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <button onClick={() => {
              trackEventMatomo('preenchimento de assinatura', 'click', 'button', 'ver assinatura')
              setOpenModal(true);
              setOlhar(true)
            }}
              className='button is-small is-fullwidth'
              style={{ backgroundColor: '#A400CF', color: 'white', fontWeight: '700' }}>
              VER ASSINATURA
            </button>
            <button
              onClick={() => {
                trackEventMatomo('preenchimento de assinatura', 'click', 'button', 'alterar assinatura')
                setOpenReassign(true)
              }}
              className='button is-small is-fullwidth'
              style={{ backgroundColor: '#A400CF', color: 'white', fontWeight: '700' }}>
              REASSINAR
            </button>
          </div>
        ) : <button
          onClick={() => { setOpenModal(true) }}
          style={{ backgroundColor: '#A400CF', color: 'white', fontWeight: '700' }}
          className='button is-small is-fullwidth'>
          ASSINAR
        </button>}
        {subItem.assinatura &&
          <>
            {subItem.comentario &&
              <SubItemComentario subItem={subItem} indexItem={indexItem} onChange={onChange} />
            }
            {subItem.foto &&
              <SubItemPhoto subItem={subItem} indexItem={indexItem} onChange={onChange} />
            }
          </>
        }
      </Container>
    </MainContainerAvaliation>
  )
}
