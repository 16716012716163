import { GenericHeader, TagContainer, TagSpan } from "../../StyledComponents/Modal/generic";
import { BoldText, GenericText } from "../Teams/style/RegisteredTeamsStyle";
import { useTranslation } from "react-i18next";
import { Label, Input } from "../../StyledComponents/Input/generic";
import { AiOutlineClose } from "react-icons/ai";
import { memo, useContext } from "react";
import { UserContext } from "../../context/UserContext";
import { ChecklistType } from "../../service/Checklists";
import { ClientType } from "../../service/Clients";
import Manages, { ManagesType } from "../../service/Manages";
import { userBackObj } from "../Users/UserSearch";
import AttToken from "../../helpers/attToken";
import { toast } from "react-toastify";
import queryClient from "../../service/query";
import { Team } from "../../service/Teams";
import { useMutation } from "@tanstack/react-query";
import { trackEventMatomo } from "../../helpers/matomo";

function ChangeManageModal(
  {
    customers,
    checklists,
    teamsList,
    userList,
    selectedManage,
    setSelectedManage,
  }:
    {
      customers: ClientType[],
      checklists: ChecklistType[],
      teamsList: Team[],
      userList: userBackObj[],
      selectedManage: ManagesType,
      setSelectedManage: Function,
    }) {

  const { popUp, openModal, escape } = useContext(UserContext);

  const managesService = new Manages();

  console.log(selectedManage.dataDelecao)

  const { t } = useTranslation('translation');

  const findCustomerName = (value: string) => {
    const customerName = customers?.find((el) => el.id === value);
    if (customerName) return customerName.nome
    else return 'teste';
  }
  const findChecklistName = (value: string) => {
    const checklistName = checklists?.find((el) => el.id === value);
    if (checklistName) return checklistName?.nome
    else return 'teste';
  }

  const findTeamsName = (value: string) => {
    const teamName = teamsList && teamsList?.find((el) => el.teamID === value);
    if (teamName) return teamName.nome
    else return 'teste';
  }
  const findManagesName = (value: string) => {
    const manageName = userList?.find((el) => el.id === value);
    if (manageName) return manageName.nome
    else return 'teste';
  }
  const findUserName = (value: string) => {
    const customerName = userList?.find((el) => el.id === value);
    if (customerName) return customerName.nome
    else return 'teste';
  }

  const { mutate: updateManage } = useMutation({
    mutationKey: ['EDIT-_MANAGE'],
    mutationFn: async () => {
      toast.warn('Salvando alterações')
      const token = await AttToken();
      if (token) {
        const body = {
          ...token,
          nome: selectedManage.nome,
          clientes: selectedManage.clientes,
          formularios: selectedManage.formularios,
          gerentes: selectedManage.gerentes,
          equipes: selectedManage.equipes,
          dataDelecao: selectedManage.dataDelecao,
          users: selectedManage.users,
          id: selectedManage.id,
        }
        await managesService.updateManage(body)
      }
    },
    onSuccess: () => {
      toast.success(`${t('Managements.change')}`);
      queryClient.resetQueries(['GET_MANAGES']);
    }
  })

  return (
    <div className={`modal ${popUp ? "modal is-active" : "modal"}`}>
      <div className="modal-background" onKeyDown={() => escape()} onClick={() => openModal()} />
      <div className="modal-card" onSubmit={(e: React.FormEvent) => e.preventDefault()}>
        <>
          <header className="modal-card-head" style={{ display: "flex", flexDirection: "column" }}>
            <GenericHeader>
              {t('Managements.registered.edit')}
            </GenericHeader>
            <GenericText>
              {t('Managements.registered.beConf')}
            </GenericText>
          </header>
          <div className="modal-card-body">
            <div
              style={{
                display: "flex",
                flexDirection: "column"
              }}
              onSubmit={(e) => e.preventDefault()}
            >
              <BoldText>{t('name')}</BoldText>
              <Input
                onClick={() => trackEventMatomo('modal gerencia', 'click', 'input', 'edita valor')}
                className="input is-small"
                value={selectedManage && selectedManage.nome}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSelectedManage({ ...selectedManage, nome: e.target.value })}
              />
              <Label>{t('teamManage.modal.customer')}</Label>
              <TagContainer>
                {selectedManage && selectedManage.clientes?.map((e: string, i) => (
                  <TagSpan key={i} onClick={() => {
                    trackEventMatomo('modal gerencia', 'click', 'tag', 'deleta')
                    setSelectedManage({ ...selectedManage, clientes: selectedManage.clientes?.filter(el => el !== e) })
                  }}>
                    {findCustomerName(e)}
                    <AiOutlineClose />
                  </TagSpan>
                ))}
              </TagContainer>
              <select
                onClick={() => trackEventMatomo('modal gerencia', 'click', 'select', 'seleciona valor')}
                disabled={customers.length < 1}
                defaultValue=''
                className="select is-small"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  if (selectedManage.clientes) {
                    setSelectedManage({ ...selectedManage, clientes: [...selectedManage.clientes, e.target.value] })
                  } else setSelectedManage({ ...selectedManage, clientes: [e.target.value] })
                }}
              >
                <option value='' >{t('select')}</option>
                {
                  customers && customers
                    ?.sort((a: ClientType, b: ClientType) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                    ?.filter((e) => e.status === 'ativo')
                    ?.map((e: ClientType, i) => (
                      <option
                        key={i}
                        disabled={e.id ? selectedManage && selectedManage.clientes?.includes(e.id) : false}
                        value={e.id}>
                        {e.nome}
                      </option>
                    ))
                }
              </select>
              <Label>{t('teamManage.modal.form')}</Label>
              <TagContainer>
                {selectedManage && selectedManage?.formularios?.map((e: string, i) => (
                  <TagSpan key={i} onClick={() => {
                    trackEventMatomo('modal gerencia', 'click', 'tag', 'deleta')
                    setSelectedManage({ ...selectedManage, formularios: selectedManage?.formularios?.filter(el => el !== e) })
                  }}>
                    {findChecklistName(e)}
                    <AiOutlineClose />
                  </TagSpan>
                ))}
              </TagContainer>
              <select
                onClick={() => trackEventMatomo('modal gerencia', 'click', 'select', 'seleciona valor')}
                disabled={checklists && checklists.length < 1}
                defaultValue=''
                className="select is-small"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  if (selectedManage.formularios) {
                    setSelectedManage({ ...selectedManage, formularios: [...selectedManage.formularios, e.target.value] })
                  } else setSelectedManage({ ...selectedManage, formularios: [e.target.value] })
                }}
              >
                <option value='' >{t('select')}</option>
                {
                  checklists && checklists
                    ?.sort((a: ChecklistType, b: ChecklistType) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                    ?.map((e: ChecklistType, i) => (
                      <option
                        key={i}
                        disabled={selectedManage && selectedManage.formularios?.includes(e.id)}
                        value={e.id}>
                        {e.nome}
                      </option>
                    ))
                }
              </select>
              <Label>{t('teamManage.modal.teams')}</Label>
              <TagContainer>
                {selectedManage && selectedManage.equipes?.map((e: string, i) => (
                  <TagSpan key={i} onClick={() => {
                    trackEventMatomo('modal gerencia', 'click', 'tag', 'deleta')
                    setSelectedManage({ ...selectedManage, equipes: selectedManage.equipes?.filter(el => el !== e) })
                  }}>
                    {findTeamsName(e)}
                    <AiOutlineClose />
                  </TagSpan>
                ))}
              </TagContainer>
              <select
                disabled={teamsList && teamsList.length < 1}
                defaultValue=''
                className="select is-small"
                onClick={() => trackEventMatomo('modal gerencia', 'click', 'select', 'seleciona valor')}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  if (selectedManage.equipes) {
                    setSelectedManage({ ...selectedManage, equipes: [...selectedManage.equipes, e.target.value] })
                  } else setSelectedManage({ ...selectedManage, equipes: [e.target.value] })
                }}
              >
                <option value=''>{t('select')}</option>
                {
                  teamsList && teamsList
                    ?.sort((a: Team, b: Team) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                    ?.filter((e) => e.status === true)
                    ?.map((e: Team, i) => (
                      <option
                        key={i}
                        disabled={selectedManage && selectedManage.equipes?.includes(e.teamID)}
                        value={e.teamID}>
                        {e.nome}
                      </option>
                    ))
                }
              </select>
              <Label>{t('teamManage.modal.manages')}</Label>
              <TagContainer>
                {selectedManage && selectedManage.gerentes?.map((e: string, i) => (
                  <TagSpan key={i} onClick={() => {
                    trackEventMatomo('modal gerencia', 'click', 'tag', 'deleta')
                    setSelectedManage({ ...selectedManage, gerentes: selectedManage.gerentes?.filter(el => el !== e) })
                  }}>
                    {findManagesName(e)}
                    <AiOutlineClose />
                  </TagSpan>
                ))}
              </TagContainer>
              <select
                onClick={() => trackEventMatomo('modal gerencia', 'click', 'select', 'seleciona valor')}
                disabled={userList && userList.length < 1}
                defaultValue=''
                className="select is-small"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  if (selectedManage.gerentes) {
                    setSelectedManage({ ...selectedManage, gerentes: [...selectedManage.gerentes, e.target.value] })
                  } else setSelectedManage({ ...selectedManage, gerentes: [e.target.value] })
                }}
              >
                <option value=''>{t('select')}</option>
                {
                  userList && userList
                    ?.filter(el => el.perfil === 'Gerente')
                    ?.sort((a: userBackObj, b: userBackObj) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                    ?.filter((e) => e.status === 'ativo')
                    ?.map((e: userBackObj, i) => (
                      <option
                        key={i} disabled={selectedManage && selectedManage.gerentes?.includes(e.id)} value={e.id}>{e.nome}</option>
                    ))
                }
              </select>
              <Label>{t('teamManage.modal.users')}</Label>
              <TagContainer>
                {selectedManage && selectedManage.users?.map((e: string, i) => (
                  <TagSpan key={i} onClick={() => {
                    trackEventMatomo('modal gerencia', 'click', 'tag', 'deleta')
                    setSelectedManage({ ...selectedManage, users: selectedManage.users?.filter(el => el !== e) })
                  }}>
                    {findUserName(e)}
                    <AiOutlineClose />
                  </TagSpan>
                ))}
              </TagContainer>
              <select
                onClick={() => trackEventMatomo('modal gerencia', 'click', 'select', 'seleciona valor')}
                defaultValue=''
                className="select is-small"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  if (selectedManage.users) {
                    setSelectedManage({ ...selectedManage, users: [...selectedManage.users, e.target.value] })
                  } else setSelectedManage({ ...selectedManage, users: [e.target.value] })
                }}
              >
                <option value=''>{t('select')}</option>
                {
                  userList && userList
                    ?.sort((a: userBackObj, b: userBackObj) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                    ?.filter((e) => e.status === 'ativo')
                    ?.map((e: userBackObj, i) => (
                      <option
                        key={i}
                        disabled={selectedManage && selectedManage.users?.includes(e.id)}
                        value={e.id}>
                        {e.nome}
                      </option>
                    ))
                }
              </select>
              <Label>{t('status')}</Label>
              <select
                onClick={() => trackEventMatomo('modal gerencia', 'click', 'select', 'seleciona valor')}
                className='select is-small'
                onChange={(e) => setSelectedManage({ ...selectedManage, dataDelecao: Number(e.target.value) })}
                defaultValue={typeof selectedManage?.dataDelecao === 'number' ? Date.now() : 1}
              >
                <option value={1}>{t('active')}</option>
                <option value={Date.now()}>{t('inactive')}</option>
              </select>
            </div>
          </div>
          <div className='modal-card-foot' style={{ display: "flex", justifyContent: "space-between" }}>
            <button
              className="button is-success is-small"
              onClick={() => {
                trackEventMatomo('modal gerencia', 'click', 'button', 'salva')
                updateManage()
                openModal()
              }}
            >
              {t('save')}
            </button>
            <button
              type="submit"
              onClick={() => {
                trackEventMatomo('modal gerencia', 'click', 'button', 'fecha/cancela')
                openModal()
              }}
              className="button is-small is-warning"
            >
              {t('cancel')}
            </button>
          </div>
        </>
      </div>
    </div>
  )
}

export default memo(ChangeManageModal)