import { ColorResult, SketchPicker } from 'react-color'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { DropzoneContainer, PreviewColor } from '../../FormStyle';
import { useTranslation } from 'react-i18next';
import { modeloStyleType } from '../../../../service/checklistStyle';
import { trackEventMatomo } from '../../../../helpers/matomo';

type props = {
  objectToSend: modeloStyleType
  setObjectToSend: Function,
  fonts: string[],
}

export default function Header({
  objectToSend,
  setObjectToSend,
  fonts }: props) {

  const { t } = useTranslation('translation');

  function getBase64(file: File, cb: (s: string) => void) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      cb(reader.result as string)
    }
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  const renderImages = (File: FileList | null) => {
    if (File) {
      Array.from(File).map(e => getBase64(e, (i) => setObjectToSend({ ...objectToSend, logo: i })));
    }
  }

  return (
    <div className='columns' style={{ display: 'flex', marginTop: '.5em', width: 'fit-content', justifyContent: 'space-around' }}>
      <div className='column is-3' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 0, marginRight: '5px' }}>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', padding: 0 }}>
          <p style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'left' }}>Escolha a cor de fundo:</p>
          <PreviewColor
            style={{ backgroundColor: objectToSend.colorHeader!!! }}
          />
        </div>
        <SketchPicker
          width='150px'
          color={objectToSend.colorHeader!!!}
          onChangeComplete={
            (color: ColorResult) => {
              trackEventMatomo('Configurar impressões form deslogado', 'click', 'input', 'seleciona cor')
              setObjectToSend({ ...objectToSend, colorHeader: color.hex!!! })
            }
          }
          disableAlpha
          presetColors={['#fff', '#000', '#f00', '#0f0', '#00f']}
        />
      </div>
      <div className='column is-3' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 0 }}>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', padding: 0 }}>
          <p style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'left' }}>Escolha a cor da fonte:</p>
          <PreviewColor
            style={{ backgroundColor: objectToSend.fontHeader?.color!!! }}
          />
        </div>
        <SketchPicker
          width='150px'
          color={objectToSend.fontHeader?.color!!!}
          onChangeComplete={
            (color: ColorResult) => {
              trackEventMatomo('Configurar impressões form deslogado', 'click', 'input', 'seleciona cor')
              setObjectToSend({ ...objectToSend, fontHeader: { ...objectToSend?.fontHeader, color: color.hex!!! } })
            }
          }
          disableAlpha
          presetColors={['#fff', '#000', '#f00', '#0f0', '#00f']}
        />
      </div>
      <div className='column is-5' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <p style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'left' }}>Escolha fonte:</p>
        <div style={{ display: 'flex' }}>
          <div className="select is-small is-fullwidth">
            <select
              onClick={() => trackEventMatomo('Configurar impressões form deslogado', 'click', 'select', 'seleciona fonte')}
              onChange={(e) => setObjectToSend({ ...objectToSend, fontHeader: { ...objectToSend?.fontHeader, fontFamily: e.target.value } })}
              defaultValue={'Arial'}>
              {fonts.map((e: string, i: number) =>
                <option key={i} value={e}>{e}</option>
              )}
            </select>
          </div>
          <input
            onClick={() => trackEventMatomo('Configurar impressões form deslogado', 'click', 'input', 'tamanho da fonte')}
            value={objectToSend.fontHeader?.fontSize!!!}
            onChange={(e) => setObjectToSend({ ...objectToSend, fontHeader: { ...objectToSend?.fontHeader, fontSize: Number(e.target.value) } })}
            className='input is-small column is-2' />
          <input className='input is-small column is-2' disabled value={'px'} />
        </div>
        <DropzoneContainer>
          <span>{t('checklists.printConfig.headerPicture')}</span>
          {objectToSend.logo && <img alt="Logo da empresa" src={objectToSend.logo} style={{ objectFit: 'cover', width: '90%', height: '40%' }} />}
          <span className='button is-success'>
            <input
              accept="image/png, image/jpeg"
              value={undefined}
              id="img"
              name="img"
              multiple={true}
              className="file-input"
              type="file"
              onChange={(e) => {
                trackEventMatomo('Configurar impressões form deslogado', 'click', 'input', 'adiciona imagem')
                renderImages(e.target.files)
              }}
            />
            {t('users.userRegister.selectMessage')}
            <AddPhotoAlternateIcon />
          </span>
        </DropzoneContainer>
      </div>
    </div>
  )
}
