import { SubItemProps } from "./Avaliacao/Avaliacao";
import { MainContainerMulti } from "./Multiple/MultipleStyle";
import { Container } from "./Unique/UniqueStyle";
import SubItemPhoto from "./SubItemPhoto";
import { useEffect, useState } from "react";
import { trackEventMatomo } from "../../../../helpers/matomo";

const ShortText = ({ subItem, indexItem, onChange }: SubItemProps) => {
  const [ocorrencia, setOcorrencia] = useState(subItem.padrao ? subItem.padrao : '');

  useEffect(() => {
    if (subItem.padrao) {
      subItem.ocorrencia = subItem.padrao
    }
  }, [])

  useEffect(() => {
    onChange && onChange()
  }, [ocorrencia])

  const borderCheckerComent = () => {
    const comentario = subItem?.ocorrencia === '' || !subItem?.ocorrencia;
    if (subItem?.obrigatoriedade?.preenchimento && comentario) return '0.3em red solid'
    if (!subItem?.obrigatoriedade?.preenchimento && comentario) return '0.3em #2196f3 solid'
    else return '0.3em #4caf50 solid'
  }


  return (
    <MainContainerMulti>
      <Container>
        <input
          onClick={() => trackEventMatomo('texto curto', 'click', 'input', 'edita valor')}
          value={ocorrencia}
          onChange={async (e) => { setOcorrencia(e.target.value); subItem.ocorrencia = e.target.value }}
          style={{ borderBottom: `${borderCheckerComent()}`, marginTop: '0.5em' }}
          className="input"
        />
        {subItem.ocorrencia?.length > 0 &&
          <>
            {subItem.foto &&
              <SubItemPhoto subItem={subItem} indexItem={indexItem} onChange={onChange} />}
          </>
        }
      </Container>
    </MainContainerMulti>
  )
}

export default ShortText;
