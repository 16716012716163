import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../StyledComponents/Input/generic';
import { GenericHeader, ModalCardbody } from '../../StyledComponents/Modal/generic';
import { GenericText } from '../Teams/style/RegisteredTeamsStyle';
import { trackEventMatomo } from '../../helpers/matomo';

export default function ConfirmationModal({ yesFunction, closeFunction, isOpen, removing }
  : { yesFunction: Function, closeFunction: Function, isOpen: boolean, removing?: boolean }) {
  const { t } = useTranslation('translation');

  return (
    <div className={`modal ${isOpen ? "modal is-active" : "modal"}`}>
      <div className="modal-background" />
      <div className="modal-card" onSubmit={(e: React.FormEvent) => e.preventDefault()}>
        <header className="modal-card-head" style={{ display: "flex", flexDirection: "column" }}>
          <GenericHeader>{t('deleteConfirmation')}</GenericHeader>
          <GenericText style={{ color: "red" }}>{t('irreversibleWarning')}</GenericText>
        </header>
        <ModalCardbody className="modal-card-body">
          <div style={{ display: "flex", justifyContent: "space-around", marginTop: "15px" }}>
            <Button
              className="button is-danger"
              disabled={removing}
              onClick={() => {
                trackEventMatomo('modal de confirmaçõa', 'click', 'button', 'salvar')
                yesFunction()
              }}>
              {t('yes')}
            </Button>
            <Button
              className="button is-info"
              disabled={removing}
              onClick={() => {
                trackEventMatomo('modal de confirmação', 'click', 'button', 'cancela')
                closeFunction(false)
              }}>
              {t('no')}
            </Button>
          </div>
        </ModalCardbody>
      </div>
    </div>
  )
}
