import axios, { AxiosError, AxiosResponse } from "axios";
import { toast } from 'react-toastify';
import { AuthBodyType } from "./types";

export type NotificationType = {
  chamadoId?: string;
  conjuntoNome: string;
  empresa: string;
  grupos: string[];
  id: string;
  link: string;
  localNome: string;
  mensagem: string;
  nomeForm: string;
  nomeNotif: string;
  timestamp: number;
  timestampForm: string;
  tipo: string;
  unidadeNome: string;
  userOrigem: string;
  lido: boolean;
  lidoEM: number;
  nome: string;
};

export default class Notifications {
  private api = axios.create({
    baseURL:
    `${process.env.REACT_APP_API_HOST}/notificacoes`,
  });

  getByUser = async (
    body: AuthBodyType 
  ) => {
    try {
      const data: AxiosResponse = await this.api.post("/getByUser", {
        ...body,
      });
      return data.data.notificacoes;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error(error.response?.status)
        return error.response?.status;
      }
    }
  };

  updateRead = async (
    body: AuthBodyType & { notif: string; date: number; user: string }
  ) => {
    try {
      const data: AxiosResponse = await this.api.post("/marcaLida", {
        ...body,
      });
      return data
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error(error.response?.status)
        return error.response?.status;
      }
    }
  };

  getConfigsByEmpresa = async (
    body: AuthBodyType
  ) => {
    try {
      const data: AxiosResponse = await this.api.post("/getConfigsByEmpresa", {
        ...body,
      });
      return data.data
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error(error.response?.status)
        return error.response?.status;
      }
    }
  };

  deleteConfig = async (
    body: AuthBodyType,
    id: String
  ) => {
    try {
      const data: AxiosResponse = await this.api.post("/deleteConfig", {
        ...body,
        id
      });
      return data.data
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error(error.response?.status)
        return error.response?.status;
      }
    }
  };

  limpaRepetidas = async (
    body: AuthBodyType,
    identifier: String
  ) => {
    try {
      const data: AxiosResponse = await this.api.post("/limpaRepetidas", {
        ...body,
        identifier
      });
      return data.data
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error(error.response?.status)
        return error.response?.status;
      }
    }
  };
}
