import axios, { AxiosError } from "axios";
import { toast } from 'react-toastify';
import { AuthBodyType, UserRegisterType } from "./types";
import { editUserType } from "../components/Users/ChangeUserModal";


export type userType = {
  contato: string;
  dataAlteracao: Date;
  dataCadastro: Date;
  email: string;
  empresa: string;
  foto: string;
  gerente: string;
  id: string;
  matricula: string;
  nome: string;
  notificacao: string;
  passwdReset: boolean;
  perfil: string;
  status: string;
  aliasname: string;
  tags: string[] | undefined;
  listaEquipes: string[] | undefined;
}

export type UserResponse = {
  users: [
    {
      contato: string;
      dataAlteracao: Date;
      dataCadastro: Date;
      email: string;
      empresa: string;
      foto: string;
      gerente: string;
      id: string;
      matricula: string;
      nome: string;
      notificacao: string;
      passwdReset: boolean;
      perfil: string;
      status: string;
      aliasname: string;
      tags: string[] | undefined;
      listaEquipes: string[] | undefined;
    }
  ];
};

export type UserRegisterResponse = {
  userFirebase: {
    dataAlteracao: number;
    email: string;
    empresa: string;
    passwordHash: string;
    passwordSalt: string;
    uid: string;
  };
  usuario: {
    contato: string;
    email: string;
    empresa: string;
    id: string;
    gerente: string;
    matricula: string;
    nome: string;
    perfil: string;
    aprovador: boolean;
    notificacao: string;
    status: string;
    dataCadastro: number;
  };
};

export type EditUserBodyType = {
  userToken: string;
  username: string;
  userEmpresa: string;
  userAlias: string;
  user: {
    gestorPA?: boolean;
    inspetorPA?: boolean;
    contato: string;
    empresa: string;
    foto: string;
    listaEquipes: string[] | [];
    matricula: string;
    nome: string;
    passwdReset: boolean;
    perfil: string;
    aprovador: boolean;
    notificacao: string;
    status: string;
    email: string;
    tags?: string[];
    id: string;
    listaEquipesNomes?: string[];
  };
};

export type EditSelfUserBodyType = {
  userToken: string;
  username: string;
  userEmpresa: string;
  usuario: string;
  email: string;
  nome: string;
  contato: string;
};

export type userTagsType = {
  id: string;
  nome: string;
};

class Users {
  private api = axios.create({
    baseURL: `${process.env.REACT_APP_API_HOST}/usuarios`,
  });

  getAllUsers = async (body: AuthBodyType) => {
    try {
      const data = await this.api.post("/getAll", {
        ...body,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.log("erro --->", error.response);
        console.error(error.message);
      }
    }
  };

  registerUser = async (body: AuthBodyType & UserRegisterType): Promise<UserRegisterResponse | undefined | number> => {
    try {
      const data = await this.api.post("/newUser", {
        ...body,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        const dataError = error.response?.data as unknown as any
        const erro = dataError.message;
        toast.error(String(erro));
        // throw new Error(error.message)
      }
    }
  };

  changeUser = async (body: AuthBodyType, userAlias: string, user: editUserType) => {
    try {
      const data = await this.api.post("/editUser", {
        ...body,
        userAlias,
        user,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        const dataError = error.response?.data as unknown as any;
        const erro = dataError.message;
        toast.error(String(erro));
        // return error.response?.status;
      }
    }
  };

  changeSelfUser = async (body: EditSelfUserBodyType) => {
    try {
      const data = await this.api.post("/editSelfUser", {
        ...body,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error(error.response?.status)
        return error.response?.status;
      }
    }
  };
  
  changeNotificacao = async (body: AuthBodyType, notificacao: string) => {
    try {
      const data = await this.api.post("/changeNotificacao", {
        ...body,
        notificacao,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error(error.response?.status)
        return error.response?.status;
      }
    }
  };

  deleteUser = async (body: AuthBodyType, user: string) => {
    try {
      const data = await this.api.post("/deleteUser", {
        ...body,
        user,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error(error.response?.status)
        return error.response?.status;
      }
    }
  };

  resetUserPassword = async (body: AuthBodyType, id: string) => {
    try {
      const data = await this.api.post("/resetPassword", {
        ...body,
        id,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error(error.response?.status)
        return error.response?.status;
      }
    }
  };
}
export default Users;
