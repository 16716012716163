import styled from "styled-components";

export const Input = styled.input`
  :focus {
    border: 2px solid #47aa8d !important;
  }
  margin-bottom: 7px;
`
export const TabStyle = styled.a`
  border: 0;
  padding: 0;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 500;
  text-decoration: none;
`

export const Form = styled.form`
  padding: 20px;
  background-color: white;
`
export const Label = styled.label`
  color: gray;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  margin: 0;
  display: flex;
  //margin: 5px 0;
`
export const Button = styled.button`
  display: flex;
  margin-top: 10px;
  margin-right: 15px;
  margin-bottom: 10px;
`
export const RedButton = styled.button`
  display: flex;
  padding: 8px;
  background-color: darkred;
  font-weight: bold;
  color: white;
  border-radius: 4px;
  border: none;
  transition: 0.3s;
  :hover {
    background-color: red !important;
    color: white;
  }
`
export const GreenButton = styled.button`
  display: flex;
  padding: 8px;
  background-color: #49ab8d;
  font-weight: bold;
  color: white;
  border-radius: 4px;
  border: none;
`

export const RoundImage = styled.img`
  border-radius: 50%;
  //align-self: flex-start;
  max-width: 30%;
  margin: 8px 0;
`
