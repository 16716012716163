import { MUIDataTableOptions } from 'mui-datatables';

export const tableOptions: MUIDataTableOptions = {
  selectableRowsHeader: false,
  selectableRowsHideCheckboxes: true,
  viewColumns: false,
  print: false,
  download: false,
  filter: false,
  responsive: 'simple',
};