import { useContext, useEffect } from 'react';
import Header from '../components/Header/Header'
import Sidebar from '../components/Sidebar/Sidebar'
import { HeaderText } from '../StyledComponents/pages/pages';
import { useTranslation } from "react-i18next";
import { UserContext } from '../context/UserContext';
import ChangeAlert from '../components/ChangeAlert/ChangeAlert';
import AprovationTabs from '../components/Aprovations/AprovationsTabs';
import Footer from '../components/Footer/Footer';
import { Container, SubContainer } from './Styles';
import { trackEventMatomoVisit } from '../helpers/matomo';

export default function Aprovations() {
  const { t } = useTranslation('translation');
  const { changed } = useContext(UserContext);

  useEffect(() => {
    trackEventMatomoVisit('Aprovações')
  }, [])

  return (
    <Container>
      <Sidebar />
      <SubContainer>
        {changed && <ChangeAlert />}
        <Header />
        <HeaderText style={{ textAlign: 'center' }}>{t('Aprovações')}</HeaderText>
        <div style={{
          marginBottom: '50px',
          padding: `${window.innerWidth < 800 ? '0px 2% 0px 2%' : '0px 10% 0px 10%'}`,
        }}>
          <AprovationTabs />
        </div>
        <Footer />
      </SubContainer>
    </Container>
  );
}