import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import ptBR from 'date-fns/locale/pt-BR';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { createTheme, ThemeProvider } from '@mui/material';

export default function DatePickerFim({ fim, setFim, inicio }: { fim: number | Date, setFim: Function, inicio: number | Date }) {
  const getMuiTheme = () => createTheme({
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            textAlign: 'center',
            width: 110,
            maxWidth: 110,
            margin: 50
          }
        }
      },
    },
  })

  return (
    <ThemeProvider theme={getMuiTheme()}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
        <DesktopDatePicker
          sx={{ margin: '10px !important' }}
          format='dd/MM/yyyy'
          label="fim"
          value={new Date(fim ? (Number(fim)) : Date.now())}
          minDate={inicio as Date}
          onChange={(newValue) => setFim(newValue?.setHours(23, 59, 59, 59))}
          slotProps={{
            field: {
              readOnly: true
            }
          }}
        />
      </LocalizationProvider>
    </ThemeProvider>
  )
}
