import { useTranslation } from "react-i18next";
import PlanoDeAcao, { logPlanoType } from "../../../service/PlanoDeAcao";
import "../styles.css";
import AttToken from "../../../helpers/attToken";
import { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
import { FaFileDownload } from "react-icons/fa";
import ModalImagePreview from "../../Reports/ModalDetailPrint/ModalImagePreview";
import { trackEventMatomo } from "../../../helpers/matomo";

type props = {
  openLogs: boolean,
  setOpenLogs: Function,
  logs: logPlanoType[],
}

type linkType = {
  base64: string, nome: string
}

const planosService = new PlanoDeAcao()

export const ImageComponent = ({ linkFoto }: { linkFoto: string }) => {
  const [exists, setExists] = useState<boolean>(false);
  const [link, setLink] = useState<linkType>({} as linkType);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openImage, setOpenImage] = useState<boolean>(false);


  const getImages = async (value: string) => {
    setIsLoading(true)
    const token = await AttToken()
    if (token) {
      const response = await planosService.getFileLink(token, value)
      setIsLoading(false)
      return setLink(response)
    }
  }

  function isImgUrl(url: string) {
    const image = new Image();
    image.src = url;
    image.onload = () => {
      setExists(true);
    }
    image.onerror = () => {
      setExists(false);
    };
    return image.width > 0
  }

  useEffect(() => {
    getImages(linkFoto)
    isImgUrl(link.base64)
  }, [link.base64, linkFoto])

  return (
    <>
      {openImage &&
        <ModalImagePreview
          image={String(link.base64)}
          open={openImage}
          setOpen={setOpenImage}
        />}
      {exists ?
        <>
          {isLoading ? (
            <div style={{ display: 'flex' }}>
              <Skeleton width={100} height={20} />&nbsp;
              <Skeleton style={{ marginTop: '-2.5em', marginBottom: '-2.8em' }} height={200} width={100} />
            </div>
          ) :
            <>
              <div style={{ display: 'flex' }}>
                Evidencia:&nbsp;
                <img onClick={() => {
                  trackEventMatomo('modal logs PA', 'click', 'img', 'abre imagem')
                  setOpenImage(!openImage)
                }}
                  className="imgLink"
                  src={link.base64}
                  alt={link.nome} />
              </div>
            </>
          }
        </>
        : <>
          {isLoading ? (
            <div style={{ display: 'flex' }}>
              <Skeleton width={100} height={20} />&nbsp;
              <Skeleton style={{ marginTop: '-.5em' }} height={40} width={200} />
            </div>
          ) :
            <div>
              Evidencia: <a className="tag" href={link.base64} target="blank" download={link.nome}>
                <div>
                  {link.nome}&nbsp;<FaFileDownload />
                </div>
              </a>
            </div>
          }
        </>
      }
    </>
  )
}


export default function ModalLogs({
  openLogs,
  setOpenLogs,
  logs,
}: props) {
  const { t } = useTranslation('translation');


  return (
    <div className={`modal ${openLogs ? 'is-active' : ''}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Logs</p>
          <button
            onClick={() => {
              trackEventMatomo('modal logs PA', 'click', 'button', 'fecha modal')
              setOpenLogs(!openLogs)
            }}
            className="delete"
            aria-label={t('close')} />
        </header>
        <section className="modal-card-body">
          <ol>
            {logs?.map((log: logPlanoType, i: number) =>
              <li key={i} className="li">
                {log.acao}
                {log.foto && <ImageComponent linkFoto={log.foto} />}
                <hr />
              </li>
            )}
          </ol>
        </section>
      </div>
    </div>
  )
}
