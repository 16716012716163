import styled from "styled-components";
import HeaderBG from '../../assets/images/header-profile.png'

export const UserCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-image: url(${HeaderBG});
  background-size: cover;
  padding: 20px;
  text-align: left;
  //margin-bottom: 6px;
  //position: fixed;
  width: inherit;
`

export const UserImg = styled.img`
  border-radius: 50%;
  max-width: 50px;
  margin-bottom: 7px;
`
export const H1 = styled.h1`
  font-size: 15px;
  font-weight: bold;
  color: white;
`
export const H2 = styled.h2`
  font-size: 13px;
  color: #7894A8;
  padding-top:1px;
`
