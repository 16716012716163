import { TextField } from '@material-ui/core';
import { Autocomplete, AutocompleteRenderInputParams } from '@mui/material';

export default function AutocompleteWithApi({
  value,
  response,
  loading,
  setValue,
  textoVazio,
  textoLoading,
  callResponse,
  textLabel,
  setOpen,
  open,
}: {
  value: { label: string, value: string | number }[] | null,
  response: { id: string | number, nome: string }[] | null,
  loading: boolean,
  setValue: Function
  textoVazio: string,
  callResponse: Function,
  textoLoading: string,
  textLabel: string,
  open: boolean,
  setOpen: Function,
}) {

  return (
    <>
      {!response ?
        <Autocomplete
          id="asynchronous-demo"
          autoComplete
          defaultValue={undefined}
          fullWidth={true}
          noOptionsText={textoVazio}
          multiple
          open={open}
          onOpen={() => {
            setOpen(true);
            callResponse();
          }}
          onClose={() => setOpen(false)}
          loading={loading}
          loadingText={textoLoading}
          options={[]}
          renderInput={(params) => <TextField
            {...params}
            label={textLabel}
            InputLabelProps={{ shrink: true }}
          />}
        /> :
        <Autocomplete
          id="asynchronous-demo"
          autoComplete
          defaultValue={value as any || undefined}
          getOptionLabel={option => option.label as string}
          getOptionKey={option => option.value}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          fullWidth={true}
          noOptionsText={textoVazio}
          multiple
          filterSelectedOptions
          options={
            response
              ?.sort((a: { id: string | number, nome: string }, b: { id: string | number, nome: string }) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
              ?.map((option: { id: string | number, nome: string }) => ({
                label: option.nome,
                value: option.id,
              }))
          }
          onChange={(event: any, newValue: { label: string | number, value: string }[] | null) => {
            event.preventDefault()
            setValue(newValue)
          }}
          renderInput={(params) => <TextField
            {...params}
            label={textLabel}
            InputLabelProps={{ shrink: true }} />}
        />}
    </>
  )
}
