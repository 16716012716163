import { useState } from "react";
import { TabStyle } from "../TabsStyle/TabsStyle";
import ResearchesComp from "./ResearchesComp";
import ResearchesModels from "./ResearchesModels";
import { trackEventMatomo } from "../../helpers/matomo";

export default function ResearchesTabs() {

  const mapNames = {
    Respostas: 'Respostas',
    Modelos: 'Modelos',
  }

  const [tab, setTab] = useState({
    isActiveObject: 'Respostas',
    object: ['Respostas', 'Modelos']
  });

  const toggleClass = (index: number) => {
    setTab({ ...tab, isActiveObject: tab.object[index] })
    trackEventMatomo('Pesquisa legado', 'click', 'tab', 'muda tab')
  }

  const toggleActive = (index: number) => {
    if (tab.object[index] === tab.isActiveObject) {
      return "is-active"
    } else {
      return "inactve"
    }
  }
  return (
    <div style={{ width: '95%' }}>
      <div className="tabs is-boxed">
        <ul>
          {tab.object.map((el, index) => (
            <li key={index} className={toggleActive(index)} onClick={() => { toggleClass(index) }}>
              <TabStyle theme={toggleActive(index)}>{mapNames[el as keyof typeof mapNames]}</TabStyle>
            </li>
          ))}
        </ul>
      </div>
      {tab.isActiveObject === 'Respostas' && (
        <ResearchesComp />
      )}
      {tab.isActiveObject === 'Modelos' && (
        <ResearchesModels />
      )}
    </div>
  )
}