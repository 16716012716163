import { useTranslation } from 'react-i18next';
import { perguntasType, respostasType } from '../../service/Pesquisas';
import { useRef, useState } from 'react';
import { FaGear } from "react-icons/fa6";
import ReactToPrint from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';

export default function ModalRespostas({
  selected,
  openModal,
  setOpenModal,
}: {
  selected: respostasType,
  openModal: boolean,
  setOpenModal: Function
}) {
  const { t } = useTranslation('translation');

  const [showOptions, setShowOptions] = useState(false);
  const [showType, setShowType] = useState(true);
  const [ocultaErrado, setOcultaErrado] = useState(true);

  const componentRef = useRef(null);

  const tipoResposta = (value: string) => {
    switch (value) {
      case "alternativa":
        return 'Alternativa'
      case 'multiple':
        return 'Múltiplo'
      case 'textoCurto':
        return 'Texto curto'
      case 'textoLongo':
        return 'Texto longo'
      case 'horario':
        return 'Horário'
      case 'data':
        return 'Data'
      case 'numero':
        return 'Numero'
      case 'foto':
        return 'Foto'
      default: return ''
    }
  }

  const exibeResposta = (value: perguntasType) => {
    switch (value.tipo) {
      case "alternativa":
        return (
          <table>
            <tbody>
              {value.alternativas.map((ele, index) =>
                index === value.resposta
                  ?
                  <tr>
                    <th>{ele}</th>
                  </tr>
                  : (ocultaErrado &&
                    <tr>
                      <td>{ele}</td>
                    </tr>
                  ))}
            </tbody>
          </table>)
      case 'multiple':
        return (
          <table>
            <tbody>
              {value.alternativas.map((ele, index) =>
                (value?.resposta as unknown as number[])?.includes(index)
                  ? <tr>
                    <th>{ele}</th>
                  </tr>
                  : (ocultaErrado &&
                    <tr>
                      <td>{ele}</td>
                    </tr>
                  )
              )}
            </tbody>
          </table>
        )
      case 'textoCurto':
        return <th>{value?.resposta}</th>
      case 'textoLongo':
        return <th>{value?.resposta}</th>
      case 'horario':
        return <th>{value?.resposta}</th>
      case 'data':
        return <th>{value?.resposta}</th>
      case 'numero':
        return <th>{value?.resposta}</th>
      case 'foto':
        return <th>{value?.resposta}</th>
      default: return ''
    }
  }
  return (
    <div className={`modal ${openModal ? 'is-active ' : ''}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header style={{
          display: 'flex',
          flexDirection: 'column',
        }} className="modal-card-head">
          <p
            style={{
              textAlign: 'center',
              width: '100%',
              fontSize: '20px',
              color: 'grey',
              fontWeight: 500
            }}
          >
            Respostas feitas em {new Date(selected?.inicio).toLocaleString('pt-BR')} até {new Date(selected?.fim).toLocaleString('pt-BR')}
          </p>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', paddingTop: '10px' }}>
            <ReactToPrint
              pageStyle={'@page { size: auto; dpi: 1, display: block, height: window.innerHeight}'}
              trigger={() =>
                <button onClick={(e) => {
                  e.preventDefault()
                }}
                  className='button is-success is-small'>
                  <PrintIcon />
                  {t('checklists.printConfig.Imprimir')}
                </button>}
              content={() => componentRef.current}
            />
            <button onClick={() => setShowOptions(!showOptions)} className='button is-white'><FaGear /></button>
            <button onClick={() => setOpenModal(!openModal)} className="button is-warning">{t('close')}</button>
          </div>
          {showOptions &&
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              width: '100%',
              backgroundColor: '#EEEEEE',
              padding: '10px',
              borderRadius: '10px'
            }}>
              <span style={{ fontWeight: 600, color: 'gray', fontSize: '14px' }}>
                <input onChange={() => setOcultaErrado(!ocultaErrado)} type='checkbox' />&nbsp;Ocultar opções não selecionadas
              </span>
              <span style={{ fontWeight: 600, color: 'gray', fontSize: '14px' }}>
                <input onChange={() => setShowType(!showType)} type='checkbox' />&nbsp;Ocultar tipo das perguntas
              </span>
            </div>
          }
        </header>
        <section className="modal-card-body" ref={componentRef}>
          {selected.perguntas.map((pergunta, index) =>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ fontWeight: 600, color: 'gray' }}>({index + 1}) {pergunta.questao}</span>
              {showType && <span style={{ fontWeight: 600, color: 'gray', fontSize: '12px' }}>({tipoResposta(pergunta.tipo)})</span>}
              <span>{exibeResposta(pergunta)}</span>
            </div>
          )}
        </section>
      </div>
    </div>
  )
}
