import styled from "styled-components";

export const TitleText = styled.h1`
  color: gray;
  font-size: x-large;
  margin: 15px 0px;
  font-weight: 300;
  display: flex;
  justify-content: space-between;
`
export const BoldText = styled.p`
  color: gray;
  font-size: 12px;
  font-weight: 600;
`
export const GenericText = styled.p`
  color: gray;
  font-size: 12px;
`
export const Card = styled.div`
  display: flex;
  flex-direction: column;
  text-align: justify;
`