import html2canvas from 'html2canvas';
import { useEffect, useState, useRef, useContext } from 'react';
import { FaFilePdf } from 'react-icons/fa';
import pdfMake from "pdfmake/build/pdfmake";
import { downloadExcel } from 'react-export-table-to-excel';
import { useTranslation } from 'react-i18next';
import Reports, { ChecklistReportType } from '../../service/Reports';
import ModalDetailPrint from './ModalDetailPrint/ModalDetailPrint';
import { StyledDiv } from '../TableHelpers/TableStyle';
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { IoMdCheckboxOutline } from 'react-icons/io';
import { UserContext } from '../../context/UserContext';
import AttToken from '../../helpers/attToken';
import { toast } from 'react-toastify';
import { trackEventMatomo } from '../../helpers/matomo';

let rowsSelected: number[] | null = [];

const reportsService = new Reports();

export default function ReportsTableComponent({
  resetRows,
  currentItems,
  setPageNumber,
  setItensPerPage,
  pageNumber,
  itensPerPage,
  limit
}: {
  currentItems: ChecklistReportType[],
  resetRows: boolean,
  setPageNumber: Function,
  setItensPerPage: Function,
  pageNumber: number,
  itensPerPage: number,
  limit: number
}) {

  const { t, i18n } = useTranslation('translation');
  const { hierarchyLabel } = useContext(UserContext)

  const mapKeys = {
    inicio: t('Reports.columns.start'),
    fim: t('Reports.columns.end'),
    formulario: t('Reports.columns.form'),
    user: t('profiles.user'),
    cliente: hierarchyLabel.cliente,
    local: hierarchyLabel.local,
    conjunto: hierarchyLabel.conjunto,
    unidade: hierarchyLabel.unidade,
    subItemIdentificador: t('Reports.columns.subIdentifier'),
    identificador: t('Reports.columns.identifier'),
    status: t('Reports.columns.status'),
    duracao: t('Reports.columns.duration'),
    foiDelegado: t('Reports.columns.delegated'),
    idCliente: t('Reports.columns.customerId'),
    nomeEmpresa: t('Reports.columns.companyName'),
    gerencia: hierarchyLabel.gerencia,
    equipes: t('Reports.columns.teams'),
    schedual: t('Reports.columns.schedual'),
    aprovacaoStatus: t('Reports.columns.aprooval'),
    tempoPausa: t('Reports.columns.breakTime'),
    versaoForm: t('Reports.columns.version'),
    nomeAprovador: t('Reports.columns.aprooverName'),
    naoConforme: t('Reports.columns.notAccording'),
    conforme: t('Reports.columns.according'),
    latitude: t('Reports.columns.latitude'),
    longitude: t('Reports.columns.longitude'),
    distancia: t('Reports.columns.distace'),
    scorePorcentagem: 'Score',
    nomeTipoServico: t('Reports.columns.serviceTypeName')
  }

  const [columnsViews, setColmnsViews] = useState<{ [k: string]: boolean }>()
  const [openModal, setOpenModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<ChecklistReportType | ChecklistReportType[] | null>(null);
  const [loadingFormsById, setLoadingFormsById] = useState(false);
  const [multipleRowsSelected, setMultipleRowsSelected] = useState<{ user: string, timestamp: number }[]>();

  const printToPdf = () => {
    const table = document.getElementById("print_to_pdf");
    if (table) {
      html2canvas(table)?.then(canvas => {
        const data = canvas?.toDataURL();
        const pdfExportSetting = {
          PageOrientation: 'landscape',
          content: [
            {
              pageSize: 'a4',
              compress: true,
              image: data,
              width: 400,
            }
          ]
        };
        pdfMake?.createPdf(pdfExportSetting)?.download("Relatório Axyma Forms.pdf");
      });
    }
  };

  useEffect(() => {
    rowsSelected = null
    setSelectedItem(null)
  }, [resetRows])

  useEffect(() => {
    const localColumnsViews = localStorage.getItem('columnsNotif');
    const defaultColumns = {
      inicio: true,
      fim: true,
      formulario: true,
      user: true,
      cliente: true,
      local: true,
      conjunto: true,
      unidade: true,
      subItemIdentificador: true,
      identificador: true,
      status: true,
      duracao: false,
      foiDelegado: false,
      idCliente: false,
      nomeEmpresa: false,
      gerencia: false,
      equipes: false,
      schedual: false,
      aprovado: false,
      tempoPausa: false,
      versaoForm: false,
      nomeAprovador: false,
      naoConforme: false,
      conforme: false,
      latitude: false,
      longitude: false,
      distancia: false,
      scorePorcentagem: true,
      nomeTipoServico: false
    }
    if (localColumnsViews) {
      setColmnsViews(JSON.parse(localColumnsViews))
    } else {
      localStorage.setItem('columnsNotif', JSON.stringify(defaultColumns))
      setColmnsViews(defaultColumns)
    }
  }, []);

  const columns = [
    {
      name: "inicio",
      label: 'ID',
      options: {
        display: false,
        filter: true,
        sort: true,
        sortThirdClickReset: false,
      }
    },
    {
      name: "inicio",
      label: mapKeys.inicio,
      options: {
        display: columnsViews ? columnsViews.inicio : true,
        filter: false,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value: number) => {
          const data = new Date(value).toLocaleString(i18n.language === 'en' ? 'en-US' : 'pt-BR');
          return data;
        },
      }
    },
    {
      name: 'fim',
      label: mapKeys.fim,
      options: {
        display: columnsViews ? columnsViews.fim : true,
        filter: false,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value: number) => {
          const data = new Date(value).toLocaleString(i18n.language === 'en' ? 'en-US' : 'pt-BR');
          return data;
        },
      }
    },
    {
      name: 'formulario',
      label: mapKeys.formulario,
      options: {
        display: columnsViews ? columnsViews.formulario : true,
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value: string) => {
          return <p style={{ width: '200px', wordBreak: 'break-all' }} >{value}</p>

        },
      }
    },
    {
      name: 'scorePorcentagem',
      label: 'Score',
      options: {
        display: columnsViews ? columnsViews?.scorePorcentagem : true,
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value: string) => {
          return <p>{value}</p>

        },
      }
    },
    {
      name: 'user',
      label: mapKeys.user,
      options: {
        display: columnsViews ? columnsViews.user : true,
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: 'cliente',
      label: mapKeys.cliente,
      options: {
        display: columnsViews ? columnsViews.cliente : true,
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "local",
      label: mapKeys.local,
      options: {
        display: columnsViews ? columnsViews.local : true,
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "conjunto",
      label: mapKeys.conjunto,
      options: {
        display: columnsViews ? columnsViews.conjunto : true,
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "unidade",
      label: mapKeys.unidade,
      options: {
        display: columnsViews ? columnsViews.unidade : true,
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "subitemIdentificador",
      label: mapKeys.subItemIdentificador,
      options: {
        display: (columnsViews ? columnsViews.subItemIdentificador : true),
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "identificador",
      label: mapKeys.identificador,
      options: {
        display: (columnsViews ? columnsViews.identificador : true),
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "status",
      label: mapKeys.status,
      options: {
        display: (columnsViews ? columnsViews.status : true),
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value: boolean) => {
          return value ? <span className='tag is-primary'>{t('active')}</span> : <span className='tag is-danger'>Cancelado</span>
        },
      }
    },
    {
      name: "duracao",
      label: mapKeys.duracao,
      options: {
        display: (columnsViews ? columnsViews.duracao : false),
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "foiDelegado",
      label: mapKeys.foiDelegado,
      options: {
        display: (columnsViews ? columnsViews.foiDelegado : false),
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "idCliente",
      label: mapKeys.idCliente,
      options: {
        display: (columnsViews ? columnsViews.idCliente : false),
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "nomeEmpresa",
      label: mapKeys.nomeEmpresa,
      options: {
        display: (columnsViews ? columnsViews.nomeEmpresa : false),
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "gerencia",
      label: mapKeys.gerencia,
      options: {
        display: (columnsViews ? columnsViews.gerencia : false),
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    // {
    //   name: "equipes",
    //   label: mapKeys.equipes,
    //   options: {
    //     display: (columnsViews ? columnsViews.equipes : false),
    //     filter: true,
    //     sort: true,
    //     sortThirdClickReset: true,
    //     customBodyRender: (value: []) => {
    //       type team = {
    //         id: string,
    //         nome: string,
    //       }
    //       return (
    //         <ul>
    //           {value.map((ele: team) => {
    //             return <li style={{width: '150px', wordBreak:'keep-all',}} key={ele.id}>{ele.nome}</li>
    //           })}
    //         </ul>
    //       )

    //     },
    //   },
    // },
    {
      name: "schedual",
      label: mapKeys.schedual,
      options: {
        display: (columnsViews ? columnsViews.schedual : false),
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "aprovacaoStatus",
      label: mapKeys.aprovacaoStatus,
      options: {
        display: (columnsViews ? columnsViews.aprovacaoStatus : false),
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "tempoPausa",
      label: mapKeys.tempoPausa,
      options: {
        display: (columnsViews ? columnsViews.tempoPausa : false),
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "versaoForm",
      label: mapKeys.versaoForm,
      options: {
        display: (columnsViews ? columnsViews.versaoForm : false),
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "nomeAprovador",
      label: mapKeys.nomeAprovador,
      options: {
        display: (columnsViews ? columnsViews.nomeAprovador : false),
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "naoConforme",
      label: mapKeys.naoConforme,
      options: {
        display: (columnsViews ? columnsViews.naoConforme : false),
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "conforme",
      label: mapKeys.conforme,
      options: {
        display: (columnsViews ? columnsViews.conforme : false),
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "latitude",
      label: mapKeys.latitude,
      options: {
        display: (columnsViews ? columnsViews.latitude : false),
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "longitude",
      label: mapKeys.longitude,
      options: {
        display: (columnsViews ? columnsViews.longitude : false),
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "distancia",
      label: mapKeys.distancia,
      options: {
        display: (columnsViews ? columnsViews.distancia : false),
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "nomeTipoServico",
      label: mapKeys.nomeTipoServico,
      options: {
        display: (columnsViews ? columnsViews.nomeTipoServico : false),
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
  ];

  const translatedTextLabels: MUIDataTableOptions = {
    viewColumns: true,
    onViewColumnsChange: (changedColumn: string, action: string) => {
      if (action === 'remove') {
        setColmnsViews({
          ...columnsViews,
          [changedColumn]: false,
        })
        localStorage.setItem('columnsNotif', JSON.stringify({
          ...columnsViews,
          [changedColumn]: false
        }))
      } else {
        setColmnsViews({
          ...columnsViews,
          [changedColumn]: true
        })
        localStorage.setItem('columnsNotif', JSON.stringify({
          ...columnsViews,
          [changedColumn]: false
        }))
      }
    },
    textLabels: {
      selectedRows: {
        text: 'Formulários selecionados',
        delete: `Abrir`,
      },
      body: {
        noMatch: `${t('table.noMatch')}`,
        toolTip: `${t('table.toolTip')}`,
        columnHeaderTooltip: column =>
          `${t('table.columnHeaderTooltip')} ${column.label}`
      },
      pagination: {
        next: `${t('table.next')}`,
        previous: `${t('table.previous')}`,
        rowsPerPage: `${t('table.rowsPerPage')}`,
        displayRows: `${t('table.displayRows')}`
      },
      toolbar: {
        search: `${t('table.search')}`,
        filterTable: `${t('table.filterTable')}`,
        viewColumns: 'Mostrar/Ocultar colunas'
      },
      filter: {
        title: `${t('table.title')}`,
        reset: `${t('table.reset')}`,
      },
      viewColumns: {
        title: `${t('table.viewColumnsTitle')}`,
      }
    },
    onChangePage: (currentPage: number) => {
      setPageNumber(currentPage);
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      setPageNumber(0)
      setItensPerPage(numberOfRows);
    },
    page: pageNumber,
    rowsPerPage: itensPerPage,
    count: limit,
    search: false,
    sort: false
  }
  const getMuiTheme = () => createTheme({
    components: {
      MuiFormGroup: {
        styleOverrides: {
          root: {
            "&>:nth-child(1)": {
              display: 'none'
            }
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            width: 'fit-content',
            margin: 0,
            padding: 0
          }
        }
      },

      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            margin: 0,
            padding: '0 10px',
            button: {
              display: 'flex',
              justifySelf: 'flex-start',
              justifyContent: 'flex-start',
              width: '100%',
            },
            '&:nth-child(1)': { width: 30, backgroundColor: "#FF0000" },
            span: {
              width: '100%',
              margin: 0,
              padding: 0
            }
          },
        }
      },
      MuiList: {
        styleOverrides: {
          root: {
            display: 'flex',
            flexDirection: 'column',
          }
        }
      },

      MUIDataTableFilter: {
        styleOverrides: {
          root: {
            width: '35dvw',
          }
        }
      },
      MuiTableBody: {
        styleOverrides: {
          root: {
            td: {
              height: 'fit-content',
              margin: 0,
              padding: '3px 10px',
              width: 'fit-content'
            },
          }
        }
      },
      MuiGrid: {
        styleOverrides: {
          item: {
            margin: 0,
            padding: 0
          },
          root: {
            margin: 0,
            padding: 0
          }
        }
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            padding: '10px'
          }
        }
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            fontSize: '12px'
          },
        }
      },
      MUIDataTableSelectCell: {
        styleOverrides: {
          root: {
            backgroundColor: "#FFF",
          },
        }
      },
    },
  })

  const tableRef = useRef(null);

  const getReports = async (ids: { user: string, timestamp: number }[]): Promise<ChecklistReportType[] | void> => {
    try {
      const response = await toast.promise(async () => {
        const token = await AttToken();
        if (token) {
          const response = await reportsService.getDetailsById(token, ids);
          if (response) {
            const execucoes = response as ChecklistReportType[];
            return execucoes;
          }
        } else {
          return;
        }
      }, {
        pending: 'Buscando Informações, aguarde...',
        success: 'Informações encontradas!',
        error: 'Falhou!'
      })
      return response;
    } catch (err) {
      return;
    }
  }

  const tableOptions: MUIDataTableOptions = {
    rowsSelected: rowsSelected as [],
    selectableRowsHeader: false,
    onRowSelectionChange(_currentRowsSelected, _allRowsSelected, r) {
      trackEventMatomo('Relatórios', 'click', 'row', 'seleciona form')
      rowsSelected = (r || []) as number[];
      if (rowsSelected && rowsSelected.length) {
        const idsList: { user: string, timestamp: number }[] = [];
        rowsSelected.forEach(index => {
          const form = currentItems[index];
          if (form) {
            idsList.push({
              user: form.username,
              timestamp: Number(form.inicio)
            })
          }
        })
        if (idsList.length) {
          setMultipleRowsSelected(idsList);
        }
      }
    },
    customToolbarSelect: () => {
      return <button
        style={{ marginRight: '20px' }}
        className='button is-success'
        disabled={loadingFormsById}
        onClick={async () => {
          trackEventMatomo('Relatórios', 'click', 'button', 'abre form')
          setLoadingFormsById(true);
          if (multipleRowsSelected && multipleRowsSelected.length) {
            const forms = await getReports(multipleRowsSelected);
            if (forms && forms.length) {
              setSelectedItem(forms)
              setOpenModal(true);
            }
          }
          setLoadingFormsById(false);
        }}>
        Abrir formulários&nbsp;
        <IoMdCheckboxOutline />
      </button>
    },

    onRowClick: async (rowData) => {
      trackEventMatomo('Relatórios', 'click', 'button', 'abre form')
      setLoadingFormsById(true);
      const selected = currentItems?.find(e => e.inicio === Number(rowData[0]));
      if (selected) {
        const idsList: { user: string, timestamp: number }[] = [{
          user: selected.username,
          timestamp: Number(selected.inicio)
        }]
        const forms = await getReports(idsList);
        if (forms && forms.length) {
          setSelectedItem(forms[0])
          setOpenModal(true);
        }
      }
      setLoadingFormsById(false);
    },
    customToolbar: () => {
      return (
        <>
          <button
            style={{ marginTop: '5px', color: 'gray', borderRadius: '40px 40px 40px 40px' }}
            className="button is-white"
            onClick={() => {
              trackEventMatomo('Relatórios', 'click', 'button', 'gera PDF')
              printToPdf()
            }}>
            <FaFilePdf />
          </button>
          <button
            style={{ marginTop: '5px', color: 'gray', borderRadius: '40px 40px 40px 40px' }}
            className="button is-white"
            onClick={() => {
              trackEventMatomo('Relatórios', 'click', 'button', 'gera csv')
              const current = currentItems as ChecklistReportType[];
              const bodyArray = [] as ChecklistReportType[];
              const headerArray: string[] = [];
              Object.keys(mapKeys)?.forEach(key => {
                if (columnsViews && columnsViews[key]) {
                  headerArray.push(mapKeys[key as keyof typeof mapKeys])
                }
              })
              current?.forEach((elem: any) => {
                const objToInstert: any = {}
                Object.keys(mapKeys)?.forEach((key: any) => {
                  if (columnsViews && columnsViews[key]) {
                    if (key === 'inicio' || key === 'fim') {
                      objToInstert[key] = new Date(Number(elem[key])).toLocaleString('pt-br').replace(',', ' ')
                    } else if (key === 'status') {
                      objToInstert[key] = elem[key] ? 'Ativo' : 'Inativo'
                    } else if (key === 'equipes' && elem[key]) {
                      let teamNamesString = '';
                      Object.keys(elem[key])?.forEach(teamKey => {
                        const team = elem[key][teamKey];
                        if (team) {
                          teamNamesString += team.nome + ' | '
                        }
                      })
                      objToInstert[key] = teamNamesString.slice(0, teamNamesString.length - 3);
                    } else {
                      objToInstert[key] = elem[key]
                    }
                  }
                })
                bodyArray.push(objToInstert)
              })
              downloadExcel({
                fileName: "Relatório XLSX Axyma Forms",
                sheet: "react-export-table-to-excel",
                tablePayload: {
                  header: headerArray || [''],
                  body: bodyArray as unknown as any
                },
              });
            }}>
            <RiFileExcel2Fill />
          </button>
        </>
      )
    },
    downloadOptions: {
      filename: 'Relatório CSV Axyma Forms',
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      const newData = data.map((ele: any) => {
        ele.data[0] = new Date(ele.data[0]).toLocaleString('pt-br').replace(',', '-')
        ele.data[1] = new Date(ele.data[1]).toLocaleString('pt-br').replace(',', '-')
        return ele
      })
      return "\uFEFF" + buildHead(columns) + buildBody(newData);
    },
    viewColumns: false,
    print: true,
    download: true,
    filter: false,
    filterType: 'textField',
    responsive: `vertical`,
    rowsPerPageOptions: [10, 15, 50, 100, 500, 1000],
  };

  return (
    <>
      {openModal &&
        <ModalDetailPrint
          openModal={openModal}
          setOpenModal={setOpenModal}
          selected={selectedItem as ChecklistReportType}
          selectedObj={selectedItem as ChecklistReportType[]}
        />}
      <div style={{
        justifyContent: 'center',
        maxWidth: '100%',
      }}>
        <StyledDiv
          theme={JSON.stringify(window.screen.width)}
          id="print_to_pdf" style={{ paddingBottom: '50px' }}>
          {currentItems &&
            <ThemeProvider theme={getMuiTheme()}>
              <div ref={tableRef} id={'myTable'}>
                <MUIDataTable
                  title={'Relatórios'}
                  data={currentItems}
                  columns={columns}
                  options={{ ...tableOptions, ...translatedTextLabels }}
                />
              </div>
            </ThemeProvider>}
        </StyledDiv>
      </div >
    </>
  )
}