import Auth from "../service/Auth";
import { AuthBodyType } from "../service/types";

const AttToken = async (): Promise<AuthBodyType | void> => {
  const localLoginData = localStorage.getItem('loginData');
  if (localLoginData) {
    const parsedLoginData: AuthBodyType = JSON.parse(localLoginData);
    const authService = new Auth(parsedLoginData.userToken, parsedLoginData.username, parsedLoginData.userEmpresa);
    const newToken = await authService.getToken();
    const data = { userToken: newToken, username: parsedLoginData.username, userEmpresa: parsedLoginData.userEmpresa };
    return data;
  }
}

export default AttToken;
