import styled from "styled-components";

export const TextArea = styled.textarea`
  background-color: white;
  border: none;
  display: flex;
  width: 100%;
  height: 4em;
  border-radius: 0.3em;
  padding-left: 1em;
  outline: 0;
  font-size: small;
`;
