import { useTranslation } from 'react-i18next';
import './styles.css';
import PlanoDeAcao from '../../../../service/PlanoDeAcao';
import { useMutation, useQuery } from '@tanstack/react-query';
import AttToken from '../../../../helpers/attToken';
import { Label } from '../../../Notifications/style/SingleNotificationStyle';
import { Autocomplete, Skeleton } from '@mui/material';
import { useState } from 'react';
import Checklists, { simpleFormType } from '../../../../service/Checklists';
import { AxiosError } from 'axios';
import { ConjuntoType } from '../../../../service/Places';
import { toast } from 'react-toastify';
import { Accordion, AccordionSummary, TextField } from '@material-ui/core';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { trackEventMatomo } from '../../../../helpers/matomo';

type props = {
  openModalPA: boolean,
  setOpenModalPA: Function,
  conjunto: ConjuntoType,
  getPlaces: Function
}

const PAService = new PlanoDeAcao();
const formService = new Checklists();

export default function ModalPA({
  openModalPA,
  setOpenModalPA,
  conjunto,
  getPlaces,
}: props) {
  const { t } = useTranslation('translation');
  const [selectedGestores, setSelectedGestores] = useState<{ [name: string]: string[] }>(conjunto.listaGestoresPA || {})
  const [selectedInspetores, setSelectedInspetores] = useState<{ [name: string]: string[] }>(conjunto.listaInspetoresPA || {})
  const [expanded, setExpanded] = useState<string | boolean>(false);

  const handleChange = (isExpanded: boolean, panel: string) => {
    setExpanded(isExpanded ? panel : false);
  };


  const { data: gestores, isLoading: loadingGestores } = useQuery({
    queryKey: ['GET_GESTORES'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const response = await PAService.getListaGestoresPA(token);
        return response
      }
    },
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  })

  const { data: inspetores, isLoading: loadingInspetores } = useQuery({
    queryKey: ['GET_INSPETORES'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const response = await PAService.getListaInspetoresPA(token)
        return response
      }
    },
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  })

  const {
    data: forms,
    isLoading: loadingForms,
  } = useQuery({
    queryKey: ['GET_FORMS'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const body = { ...token }
        const result = await formService.getSimpleForm(body, true);
        return result
      }
    },
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    onError: () => {
      console.log(AxiosError)
    }
  });

  const { mutate, isLoading } = useMutation({
    mutationKey: ['SAVE_GESTORES'],
    mutationFn: async () => {
      const token = await AttToken();
      if (token) {
        const response = await PAService.salvarGestoresPA(token, conjunto?.id, selectedGestores, selectedInspetores)
        return response
      }
    },
    onSuccess: () => {
      getPlaces(conjunto.cliente)
      setOpenModalPA(!openModalPA)
      toast.success('Alterações salvas!')
    }
  })

  return (
    <div className={`modal ${openModalPA ? 'is-active' : ''}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Associações de Plano de Ação</p>
        </header>
        <section className="modal-card-body">
          <Accordion style={{ padding: '0px 15px 10px 15px' }} expanded={expanded === 'panel1'}>
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon
                onClick={(isExpanded) => {
                  trackEventMatomo('Modal associação de PA', 'click', 'acordeon', 'abre/fecha')
                  if (expanded === 'panel1') {
                    handleChange(!isExpanded, '')
                  } else handleChange(true, 'panel1')
                }}
              />}>
              <span style={{ fontWeight: 600, color: 'gray' }}>Associações de gestores</span>
            </AccordionSummary>
            {loadingGestores ? <Skeleton variant='rounded' height={30} /> :
              <Autocomplete
                onClick={() => trackEventMatomo('Modal associação de PA', 'click', 'select', 'seleciona valor')}
                multiple
                value={Object.keys(selectedGestores)?.map((ele) => (
                  {
                    value: ele || 'Não encontrado',
                    label: gestores?.find((elem: { id: string, nome: string }) => elem?.id === ele)?.nome || 'Não encontrado'
                  })) || null}
                id="combo-box-demo"
                sx={{ marginTop: '5px' }}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                filterSelectedOptions
                disableClearable
                options={
                  gestores
                    ?.sort((a: any, b: any) => { return a?.nome.toLowerCase() > b?.nome.toLowerCase() ? 1 : - 1 })
                    ?.map((ele: any) => ({
                      label: ele?.nome,
                      value: ele?.id,
                    }))}
                onChange={(event: any, newValue: { label: string, value: string }[] | null, reason: any, details: any) => {
                  const value = details?.option.value;
                  if (Object.keys(selectedGestores)?.includes(value)) {
                    delete selectedGestores[value]
                    setSelectedGestores({ ...selectedGestores })
                  } else {
                    setSelectedGestores({ ...selectedGestores, [value]: [] })
                  }
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.value}>
                    <span>{option.label}</span>
                  </li>)}
                renderInput={(params) => <TextField {...params} label={'Adicionar gestores'} />}
              />
            }
            {loadingForms ? <Skeleton variant='rounded' height={30} /> :
              <>
                {Object.keys(selectedGestores).length > 0 &&
                  <>
                    <Label>
                      Formulários associados
                    </Label>
                    {selectedGestores && Object.keys(selectedGestores).map((user: string) => (
                      <Autocomplete
                        onClick={() => trackEventMatomo('Modal associação de PA', 'click', 'select', 'seleciona valor')}
                        value={selectedGestores[user]?.map((ele) => (
                          {
                            label: forms.find((elem: simpleFormType) => elem?.id === ele)?.nome || 'Não encontrado',
                            value: ele || 'Não encontrado'
                          })) || null}
                        multiple
                        id="combo-box-demo"
                        className='column'
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        filterSelectedOptions
                        disableClearable
                        options={
                          forms
                            ?.sort((a: simpleFormType, b: simpleFormType) => { return a?.nome.toLowerCase() > b?.nome.toLowerCase() ? 1 : - 1 })
                            ?.map((ele: any) => ({
                              label: ele?.nome,
                              value: ele?.id,
                            }))}
                        onChange={(event: any, newValue: { label: string, value: string }[] | null, reason: any, details: any) => {
                          const value = details?.option.value;
                          if (selectedGestores[user].includes(value)) {
                            setSelectedGestores((prev) => ({ ...prev, [user]: prev[user as keyof typeof prev].filter(ele => ele !== value) }))
                          } else {
                            setSelectedGestores((prev) => ({ ...prev, [user]: [...prev[user as keyof typeof prev], value] }))
                          }
                        }}
                        renderOption={(props, option) => (
                          <li {...props} key={option.value}>
                            <span>{option.label}</span>
                          </li>)}
                        renderInput={(params) => <TextField {...params} label={gestores?.find((elem: { id: string, nome: string }) => elem?.id === user)?.nome} />}
                      />
                    ))}
                  </>}
              </>}
          </Accordion>
          <Accordion style={{ padding: '0px 15px 10px 15px' }} expanded={expanded === 'panel2'}>
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon
                onClick={(isExpanded) => {
                  trackEventMatomo('Modal associação de PA', 'click', 'acordeon', 'abre/fecha')
                  if (expanded === 'panel2') {
                    handleChange(!isExpanded, '')
                  } else handleChange(true, 'panel2')
                }}
              />}>
              <span style={{ fontWeight: 600, color: 'gray' }}>Associações de inspetores</span>
            </AccordionSummary>
            {loadingInspetores ? <Skeleton variant='rounded' height={30} /> :
              <Autocomplete
                onClick={() => trackEventMatomo('Modal associação de PA', 'click', 'select', 'seleciona valor')}
                multiple
                value={Object.keys(selectedInspetores)?.map((ele) => (
                  {
                    value: ele || 'Não encontrado',
                    label: inspetores?.find((elem: { id: string, nome: string }) => elem?.id === ele)?.nome || 'Não encontrado'
                  })) || null}
                id="combo-box-demo"
                sx={{ marginTop: '5px' }}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                filterSelectedOptions
                disableClearable
                options={
                  inspetores
                    ?.sort((a: any, b: any) => { return a?.nome.toLowerCase() > b?.nome.toLowerCase() ? 1 : - 1 })
                    ?.map((ele: any) => ({
                      label: ele?.nome,
                      value: ele?.id,
                    }))}
                onChange={(event: any, newValue: { label: string, value: string }[] | null, reason: any, details: any) => {
                  const value = details?.option.value;
                  if (Object.keys(selectedInspetores)?.includes(value)) {
                    delete selectedInspetores[value]
                    setSelectedInspetores({ ...selectedInspetores })
                  } else {
                    setSelectedInspetores({ ...selectedInspetores, [value]: [] })
                  }
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.value}>
                    <span>{option.label}</span>
                  </li>)}
                renderInput={(params) => <TextField {...params} label={'Adicionar gestores'} />}
              />
            }
            {loadingForms ? <Skeleton height={30} variant='rounded' /> :
              <>
                {Object.keys(selectedInspetores).length > 0 &&
                  <>
                    <Label>
                      Formulários associados
                    </Label>
                    {selectedInspetores && Object.keys(selectedInspetores).map((user: string) => (
                      <>
                        <Autocomplete
                          onClick={() => trackEventMatomo('Modal associação de PA', 'click', 'select', 'seleciona valor')}
                          value={selectedInspetores[user]?.map((ele) => (
                            {
                              label: forms.find((elem: simpleFormType) => elem?.id === ele)?.nome || 'Não encontrado',
                              value: ele || 'Não encontrado'
                            })) || null}
                          multiple
                          id="combo-box-demo"
                          className='column'
                          isOptionEqualToValue={(option, value) => option.value === value.value}
                          filterSelectedOptions
                          disableClearable
                          options={
                            forms
                              ?.sort((a: simpleFormType, b: simpleFormType) => { return a?.nome.toLowerCase() > b?.nome.toLowerCase() ? 1 : - 1 })
                              ?.map((ele: any) => ({
                                label: ele?.nome,
                                value: ele?.id,
                              }))}
                          onChange={(event: any, newValue: { label: string, value: string }[] | null, reason: any, details: any) => {
                            const value = details?.option.value;
                            if (selectedInspetores[user].includes(value)) {
                              setSelectedInspetores((prev) => ({ ...prev, [user]: prev[user as keyof typeof prev].filter(ele => ele !== value) }))
                            } else {
                              setSelectedInspetores((prev) => ({ ...prev, [user]: [...prev[user as keyof typeof prev], value] }))
                            }
                          }}
                          renderOption={(props, option) => (
                            <li {...props} key={option.value}>
                              <span>{option.label}</span>
                            </li>)}
                          renderInput={(params) => <TextField {...params} label={inspetores?.find((elem: { id: string, nome: string }) => elem?.id === user)?.nome} />}
                        />
                      </>
                    ))}
                  </>}
              </>}
          </Accordion>

        </section>
        <footer className="modal-card-foot">
          <button
            disabled={isLoading}
            onClick={() => {
              trackEventMatomo('Modal associação de PA', 'click', 'button', 'salva')
              mutate()
            }}
            className="button is-success is-small">
            {t('save')}
          </button>
          <button
            className='button is-small is-warning'
            onClick={() => {
              trackEventMatomo('Modal associação de PA', 'click', 'button', 'fecha modal')
              setOpenModalPA(!openModalPA)
            }}>
            {t('cancel')}
          </button>
        </footer>
      </div>
    </div>
  )
}
