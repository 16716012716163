import { SubItemFormFilling } from "./Types";

export enum SubStatus {
  ok = "OK",
  naoOk = "NAOOK",
  default = "DEFAULT",
}

const regexEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const verificaConformidade = (subItem: SubItemFormFilling) => {
  let valido;
  if (subItem.nota && subItem.threshold) {
    if (subItem.nota >= subItem.threshold + 1) valido = true;
    else valido = false;
  } else valido = false;
  return valido;
};

export function verifySubitem(subItem: SubItemFormFilling): SubStatus {
  const ok = SubStatus.ok;
  const naoOk = SubStatus.naoOk;
  const def = SubStatus.default;
  switch (subItem.tipo) {
    case "assinatura":
      if (subItem.obrigatoriedade?.preenchimento) {
        if (!subItem.assinatura) {
          return naoOk;
        }
      }
      if (subItem.assinatura) {
        if (subItem.comentario && subItem.obrigatoriedade?.ocorrencia)
          if (!subItem.obs || !subItem.obs?.length) {
            return naoOk;
          }
        if (subItem.foto && subItem.obrigatoriedade?.img) {
          if (!subItem.img || !subItem.img?.length) {
            return naoOk;
          }
        }
        return ok;
      }
      break;
    case "cep":
      if (subItem.obrigatoriedade?.preenchimento) {
        if (subItem.ocorrencia?.length !== 9) {
          return naoOk;
        }
      }
      if (subItem.ocorrencia) {
        if (subItem.obrigatoriedade?.img && (!subItem.img || !subItem.img?.length)) {
          return naoOk;
        }
        return ok;
      }
      break;
    case "avaliacao":
      if (subItem.obrigatoriedade?.preenchimento) {
        if (subItem.nota === undefined) {
          return naoOk;
        }
      }
      if (subItem.nota !== undefined && (subItem.threshold || subItem.threshold === 0)) {
        if (subItem?.nota > subItem?.threshold) {
          if (!subItem.hideImg && subItem.obrigatoriedade?.conforme?.img && (!subItem.img || !subItem.img?.length)) {
            return naoOk;
          }
          if (!subItem.hideComent && subItem.obrigatoriedade?.conforme.ocorrencia && (!subItem.ocorrencia || !subItem.ocorrencia?.length)) {
            return naoOk;
          }
        }
        if (subItem?.nota <= subItem?.threshold) {
          if (!subItem.hideImg && subItem.obrigatoriedade?.naoConforme.img && (!subItem.img || !subItem.img?.length)) {
            return naoOk;
          }
          if (!subItem.hideComent && subItem.obrigatoriedade?.naoConforme.ocorrencia && (!subItem.ocorrencia || !subItem.ocorrencia?.length)) {
            return naoOk;
          }
        }
        if (!subItem.hideImg && subItem.obrigatoriedade?.NA?.img && subItem.nota === -1) {
          if (!subItem?.img || !subItem.img?.length) {
            return naoOk;
          }
          if (!subItem.hideComent && subItem.obrigatoriedade?.NA?.ocorrencia && subItem.nota === -1) {
            if (!subItem?.ocorrencia || !subItem.ocorrencia?.length) {
              return naoOk;
            }
          }
        }
        return ok;
      }
      if (subItem.nota !== undefined) {
        return ok;
      }
      break;
    case "cpfcnpj":
      if (subItem.obrigatoriedade?.preenchimento) {
        if (!subItem.ocorrencia?.length || (subItem.ocorrencia?.length !== 14 && subItem.ocorrencia?.length !== 18)) {
          return naoOk;
        }
      }
      if (subItem.ocorrencia?.length) {
        if (subItem.ocorrencia?.length !== 14 && subItem.ocorrencia?.length !== 18) {
          return naoOk;
        }
      }
      if (subItem.ocorrencia) {
        if (subItem.obrigatoriedade?.img) {
          if (!subItem.img || !subItem.img?.length) {
            return naoOk;
          }
        }
        return ok;
      }
      break;
    case "data":
      if (subItem.obrigatoriedade?.preenchimento) {
        if (!subItem.ocorrencia) {
          return naoOk;
        }
      }
      if (subItem.ocorrencia) {
        if (subItem.comentario && subItem.obrigatoriedade?.ocorrencia) {
          if (!subItem.obs || !subItem.obs?.length) {
            return naoOk;
          }
        }
        return ok;
      }
      break;
    case "email":
      if (subItem.obrigatoriedade?.preenchimento) {
        if (!subItem.ocorrencia || !regexEmail.test(subItem.ocorrencia)) {
          return naoOk;
        }
      }
      if (subItem.ocorrencia) {
        if (subItem.foto && subItem.obrigatoriedade?.img) {
          if (!subItem.img || !subItem.img?.length) {
            return naoOk;
          }
        }
        return ok;
      }
      break;
    case "hora":
      if (subItem.obrigatoriedade?.preenchimento) {
        if (!subItem.ocorrencia) {
          return naoOk;
        }
      }
      if (subItem.ocorrencia) {
        if (subItem.comentario && subItem.obrigatoriedade?.ocorrencia) {
          if (!subItem.obs || !subItem.obs?.length) {
            return naoOk;
          }
        }
        return ok;
      }
      break;
    case "list":
      if (subItem.obrigatoriedade?.preenchimento) {
        if ((subItem?.selecionados as { texto: string }[])?.some((ele) => !ele.texto?.length)) {
          return naoOk;
        }
      }
      if ((subItem?.selecionados as { texto: string }[])?.some((ele) => ele.texto?.length)) {
        if (subItem.comentario && subItem.obrigatoriedade?.ocorrencia) {
          if (!subItem.obs || !subItem.obs?.length) {
            return naoOk;
          }
        }

        if (subItem.foto && subItem.obrigatoriedade?.img) {
          if (!subItem.img || !subItem.img?.length) {
            return naoOk;
          }
        }
        return ok;
      }
      break;
    case "multiple":
      if (subItem.obrigatoriedade?.preenchimento) {
        if (!(subItem?.selecionados as boolean[])?.some((ele) => ele === true)) {
          return naoOk;
        }
      }
      if ((subItem.selecionados as boolean[])?.some((ele) => ele === true)) {
        if (subItem.comentario && subItem.obrigatoriedade?.ocorrencia) {
          if (!subItem.ocorrencia || !subItem.ocorrencia?.length) {
            return naoOk;
          }
        }
        if (subItem.foto && subItem.obrigatoriedade?.img) {
          if (!subItem.img || !subItem.img?.length) {
            return naoOk;
          }
        }
        return ok;
      }
      break;
    case "unique":
      if (subItem.obrigatoriedade?.preenchimento) {
        if (!(subItem?.selecionados as boolean[])?.some((ele) => ele === true)) {
          return naoOk;
        }
      }
      if ((subItem.selecionados as boolean[])?.some((ele) => ele === true)) {
        if (subItem.comentario && subItem.obrigatoriedade?.ocorrencia) {
          if (!subItem.obs || !subItem.obs?.length) {
            return naoOk;
          }
        }
        if (subItem.foto && subItem.obrigatoriedade?.img) {
          if (!subItem.img || !subItem.img?.length) {
            return naoOk;
          }
        }
        return ok;
      }
      break;
    case "numero":
      if (subItem.obrigatoriedade?.preenchimento) {
        if (!subItem.ocorrencia) {
          return naoOk;
        }
      }
      if (subItem.ocorrencia) {
        if (subItem.maxNumberVal && Number(subItem.ocorrencia) > subItem.maxNumberVal) {
          return naoOk;
        }
        if (subItem.minNumberVal && Number(subItem.ocorrencia) < subItem.minNumberVal) {
          return naoOk;
        }
        if (subItem.comentario && subItem.obrigatoriedade?.ocorrencia && (!subItem.obs || !subItem.obs?.length)) {
          return naoOk;
        }
        if (subItem.foto && subItem.obrigatoriedade?.img && (!subItem.img || !subItem.img?.length)) {
          return naoOk;
        }
        return ok;
      }
      break;
    case "placa":
      if (subItem.obrigatoriedade?.preenchimento) {
        if (subItem.ocorrencia?.length !== 8) {
          return naoOk;
        }
      }
      if (subItem.ocorrencia?.length === 8) {
        if (subItem.foto && subItem.obrigatoriedade?.img) {
          if (!subItem.img || !subItem.img?.length) {
            return naoOk;
          }
        }
        return ok;
      }
      break;
    case "rg":
      if (subItem.obrigatoriedade?.preenchimento) {
        if (subItem.ocorrencia?.length !== 13) {
          return naoOk;
        }
      }
      if (subItem.ocorrencia?.length === 13) {
        if (subItem.foto && subItem.obrigatoriedade?.img) {
          if (!subItem.img || !subItem.img?.length) {
            return naoOk;
          }
        }
        return ok;
      }
      break;
    case "telefone":
      if (subItem.obrigatoriedade?.preenchimento) {
        if (!subItem.ocorrencia || subItem.ocorrencia?.length < 12) {
          return naoOk;
        }
      }
      if (subItem.ocorrencia?.length  >= 12) {
        if (subItem.foto && subItem.obrigatoriedade?.img) {
          if (!subItem.img || !subItem.img?.length) {
            return naoOk;
          }
        }
        return ok;
      }
      break;
    case "textoCurto":
    case "textoLongo":
      if (subItem.obrigatoriedade?.preenchimento) {
        if (!subItem.ocorrencia) {
          return naoOk;
        }
      }
      if (subItem.ocorrencia) {
        if (subItem.foto && subItem.obrigatoriedade?.img) {
          if (!subItem.img || !subItem.img?.length) {
            return naoOk;
          }
        }
        return ok;
      }
      break;
    default:
      return def;
  }
  return def;
}
