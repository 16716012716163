import { GoGear } from "react-icons/go"
import * as Style from "./style/AllNotificationsStyle"
import { useContext, useEffect, useState } from "react"
import { Label, Button } from "../../StyledComponents/Input/generic";
import { GenericText } from "../../StyledComponents/Modal/generic";
import { useTranslation } from "react-i18next";
import { StyledDiv } from "../TableHelpers/TableStyle";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import { tableOptions, getMuiTheme } from "../TableHelpers/options";
import AttToken from "../../helpers/attToken";
import Notifications, { NotificationType } from "../../service/Notifications";
import { MdOutlineLocalPostOffice } from "react-icons/md";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@tanstack/react-query";
import { ThemeProvider } from '@mui/material/styles';
import { AxiosError } from "axios";
import Users from "../../service/Users";
import { UserContext } from "../../context/UserContext";
import queryClient from "../../service/query";
import { Skeleton } from "@mui/material";
import Table from "../Skeletons/Table";
import { IoMdCheckboxOutline } from "react-icons/io";
import Reports from "../../service/Reports";
import { useNavigate } from "react-router-dom";
import { trackEventMatomo } from "../../helpers/matomo";

const notificationsService = new Notifications();
const reportsService = new Reports();
const usersService = new Users();

export default function AllNotifications() {
  const { userData } = useContext(UserContext);
  const { t } = useTranslation('translation');
  const [isOpenBtn, setIsOpenBtn] = useState(false);
  const [columnsViews, setColmnsViews] = useState<{ [k: string]: boolean }>()
  const [selected, setSelected] = useState(false);
  const [notifications, setNotifications] = useState<NotificationType[]>([]);
  const [hour, setHour] = useState<number>(1);
  const [when, setWhen] = useState<string>(userData.notificacao);
  const [canSaveLocal, setCanSaveLocal] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);

  const navigate = useNavigate()

  const radioBtnEvent = () => {
    setIsOpenBtn(!isOpenBtn);
  }

  const {
    data,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ['GET_NOTIFICATIONS'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const response = await notificationsService.getByUser(token)
        return response
      }
    },
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    onError: () => {
      console.log(AxiosError)
    }
  });

  const {
    mutate: updateNotification,
    isLoading: isLoadingNotif
  } = useMutation({
    mutationKey: ['UPDATE_NOTIFICATIONS'],
    mutationFn: async (notif: string) => {
      const token = await AttToken();
      if (token) {
        const response = await usersService.changeNotificacao(token, notif)
        return response
      }
    }
  });

  useEffect(() => {
    if (canSaveLocal) {
      const localUser = localStorage.getItem('userDataLocal');
      if (localUser) {
        const userParsed: { notificacao?: string } = JSON.parse(localUser);
        if (userParsed.notificacao && firstLoad) {
          if (userParsed.notificacao.length === 1 || userParsed.notificacao.length === 2) {
            setWhen('diario');
            setHour(Number(userParsed.notificacao));
          } else setWhen(userParsed.notificacao);
        }
        if (!firstLoad) userParsed.notificacao = when !== 'diario' ? when : `${hour}`;
        !firstLoad && localStorage.setItem('userDataLocal', JSON.stringify(userParsed));
        setCanSaveLocal(false);
        setFirstLoad(false);
      }
    }
  }, [canSaveLocal, hour, when, firstLoad]);

  useEffect(() => {
    if (data) setNotifications(!!data && data?.filter((el: NotificationType) => el.lido === selected));
  }, [data, selected])

  useEffect(() => {
    const localColumnsViews = localStorage.getItem('columnsNotif');
    if (localColumnsViews) {
      setColmnsViews(JSON.parse(localColumnsViews))
    }
  }, []);

  const { mutate } = useMutation({
    mutationKey: ['READ_NOTIFICATION '],
    mutationFn: async (val: string) => {
      const token = await AttToken()
      if (token) {
        const body = { ...token, notif: val, date: Date.now(), user: token.username }
        await notificationsService.updateRead(body)
        setNotifications(notifications.map((e) => e))
      }
    },
    onSuccess: () => {
      queryClient.resetQueries(['GET_NOTIFICATIONS'])
    }
  });

  const openLink = async (value: string) => {
    toast.warn('Aguarde, abrindo execução!')
    const token = await AttToken()
    if (token) {
      const form = notifications.find((item: NotificationType) => item.id === value)
      if (form) {
        if (form.chamadoId) {
          //localStorage.setItem('chamado', form.chamadoId)
          navigate(`/detalheChamado/${form.chamadoId}`)
        } else {
          const response = await reportsService.getLink(token, form.userOrigem, null, Number(form.timestampForm), String(process.env.REACT_APP_PROJECT_NAME))
          if (!response.message) {
            toast.warning('Erro ao buscar execução')
          }
          else if (response.message) {
            window.open(response.message, '_blank', 'noopener,noreferrer');
          }
        }
      }
    }
  }

  const columns = [
    {
      name: "timestamp",
      label: t('notifications.unread.hour'),
      options: {
        display: columnsViews ? columnsViews.timestamp : true,
        filter: false,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value: number) => {
          const data = new Date(value).toLocaleString("pt-br");
          return data;
        },
      }
    },
    {
      name: 'nomeNotif',
      label: t('notifications.unread.notificationName'),
      options: {
        display: columnsViews ? columnsViews.nomeNotif : true,
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: 'mensagem',
      label: t('message'),
      options: {
        display: columnsViews ? columnsViews.mensagem : true,
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: 'localNome',
      label: t('settingsPage.hierarchy.place'),
      options: {
        display: columnsViews ? columnsViews.localNome : true,
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: 'conjutoNome',
      label: t('settingsPage.hierarchy.set'),
      options: {
        display: columnsViews ? columnsViews.conjuntoNome : true,
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "unidadeNome",
      label: t('settingsPage.hierarchy.unity'),
      options: {
        display: columnsViews ? columnsViews.unidadeNome : true,
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "nomeForm",
      label: t('notifications.unread.form'),
      options: {
        display: columnsViews ? columnsViews.nomeForm : false,
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "lidoEm",
      label: t('notifications.all.readAt'),
      options: {
        display: columnsViews ? columnsViews.lidoEm : false,
        filter: false,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value: number) => {
          const data = value ? new Date(value).toLocaleString("pt-br") : "";
          return data;
        },
      }
    },
    {
      name: "id",
      label: t(`notifications.all.markAs`),
      options: {
        display: true,
        filter: false,
        sort: false,
        sortThirdClickReset: false,

        customBodyRender: (value: string) => (
          <button
            style={{ display: selected ? 'none' : '' }}
            className="button is-small is-light"
            onClick={(e) => {
              trackEventMatomo('notificações', 'click', 'button', 'abre notificação')
              e.preventDefault();
              mutate(value);
              setNotifications(notifications.filter(el => el.id !== value))
              toast.success("Marcado como lido!");
            }}
          >
            <MdOutlineLocalPostOffice />&nbsp;
            {t(`notifications.all.markAs`)}
          </button>
        ),
      }
    },
    {
      name: "id",
      label: 'Abrir formulário',
      options: {
        display: true,
        filter: false,
        sort: false,
        sortThirdClickReset: false,
        customBodyRender: (value: string) => (
          <button
            className="button is-small is-warning"
            onClick={(e) => {
              e.preventDefault();
              trackEventMatomo('notificações', 'click', 'button', 'abre link')
              openLink(value)
            }}
          >
            <IoMdCheckboxOutline />&nbsp;
            <span>Abrir formulário</span>
          </button>
        ),
      }
    },
  ];

  const translatedTextLabels: MUIDataTableOptions = {
    viewColumns: true,
    onViewColumnsChange: (changedColumn: string, action: string) => {
      if (action === 'remove') {
        setColmnsViews({
          ...columnsViews,
          [changedColumn]: false
        })
        localStorage.setItem('columnsNotif', JSON.stringify({
          ...columnsViews,
          [changedColumn]: false
        }))
      } else {
        setColmnsViews({
          ...columnsViews,
          [changedColumn]: true
        })
        localStorage.setItem('columnsNotif', JSON.stringify({
          ...columnsViews,
          [changedColumn]: false
        }))
      }
    },
    textLabels: {
      body: {
        noMatch: `${t('table.noMatch')}`,
        toolTip: `${t('table.toolTip')}`,
        columnHeaderTooltip: column =>
          `${t('table.columnHeaderTooltip')} ${column.label}`
      },
      pagination: {
        next: `${t('table.next')}`,
        previous: `${t('table.previous')}`,
        rowsPerPage: `${t('table.rowsPerPage')}`,
        displayRows: `${t('table.displayRows')}`
      },
      toolbar: {
        search: `${t('table.search')}`,
        filterTable: `${t('table.filterTable')}`,
        viewColumns: 'Mostrar/Ocultar colunas'
      },
      filter: {
        title: `${t('table.title')}`,
        reset: `${t('table.reset')}`,
      },
      viewColumns: {
        title: `${t('table.viewColumnsTitle')}`,
      }
    }
  }

  let timeToSet = [];

  for (let i = 1; i <= 24; i++) {
    timeToSet.push(i)
  }

  return (
    <div>
      {isLoadingNotif ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Skeleton height={45} width={180} />&nbsp;&nbsp;
          <Skeleton height={20} width={200} />&nbsp;
          <Skeleton height={45} width={80} />
        </div>
      ) :
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className={isOpenBtn ? "dropdown is-right is-active" : ""}>
            <div className="dropdown-trigger">
              <Button
                style={{ marginLeft: "10px", fontWeight: 700 }}
                className="button is-primary is-small"
                aria-haspopup="true"
                aria-controls="dropdown-menu"
                onClick={radioBtnEvent}
                type="button"
              >
                <Style.TextOption>{t('settings')}</Style.TextOption>
                <GoGear style={{ fontSize: "20px" }} />
                <span className="icon is-small">
                  <i className="fas fa-angle-down" aria-hidden="true"></i>
                </span>
              </Button>
            </div>
            <Style.DropDown className="dropdown-menu">
              <div className="dropdown-content">
                <Style.TextRadio>{t('notifications.all.recieveContent')}</Style.TextRadio>
                <Style.DivRadio className="control">
                  <Label htmlFor="answer">
                    <input
                      value={when}
                      type="radio"
                      name="answer"
                      checked={when === 'sempre'}
                      onClick={() => trackEventMatomo('notificações', 'click', 'radio', 'seleciona valor')}
                      onChange={() => {
                        setWhen('sempre')
                        updateNotification('sempre')
                        setCanSaveLocal(true);
                      }} />
                    <Style.TextOption>{t('notifications.all.always')}</Style.TextOption>
                  </Label>
                  <Label htmlFor="answer">
                    <input
                      value={when}
                      type="radio"
                      name="answer"
                      checked={when === 'nunca'}
                      onClick={() => trackEventMatomo('notificações', 'click', 'radio', 'seleciona valor')}
                      onChange={() => {
                        setWhen('nunca')
                        updateNotification('nunca')
                        setCanSaveLocal(true);
                      }} />
                    <Style.TextOption>{t('notifications.all.never')}</Style.TextOption>
                  </Label>
                  <Label htmlFor="answer">
                    <input
                      value={when}
                      type="radio"
                      name="answer"
                      checked={when === 'diario'}
                      onClick={() => trackEventMatomo('notificações', 'click', 'radio', 'seleciona valor')}
                      onChange={() => setWhen('diario')} />
                    <Style.TextOption>{t('notifications.all.daily')}</Style.TextOption>
                  </Label>
                </Style.DivRadio>
                <label style={{ display: 'flex', padding: '2px', marginLeft: '5px' }}>
                  {t('notifications.selectWhen')}
                  <select
                    value={hour}
                    style={{ marginLeft: '5px' }}
                    disabled={when !== 'diario'}
                    onClick={() => trackEventMatomo('notificações', 'click', 'select', 'seleciona valor')}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      setHour(Number(e.target.value));
                      updateNotification(e.target.value);
                      setCanSaveLocal(true);
                    }}>
                    {timeToSet.map((e: number) => <option key={Math.random()} value={`${e}`}>{`${e} : 00`}</option>
                    )}
                  </select>
                </label>
              </div>
            </Style.DropDown>
          </div>
          <label>
            {t(`notifications.all.selectStatus`)}:&nbsp;
            <select
              onClick={() => trackEventMatomo('notificações', 'click', 'select', 'seleciona valor')}
              onChange={() => {
                setSelected(!selected)
                queryClient.resetQueries(['notifications'])
              }}
              className="select"
              defaultValue={`${false}`}>
              <option value={`${true}`}>{t(`notifications.all.read`)}</option>
              <option value={`${false}`}>{t(`notifications.all.notRead`)}</option>
            </select>
          </label>
        </div>}
      <>
        {(isLoading || isFetching) ? (<Table />) : (
          notifications.length > 0 ?
            <StyledDiv>
              <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                  title={t('notifications.all.tabtitle')}
                  data={notifications}
                  columns={columns}
                  options={{ ...tableOptions, ...translatedTextLabels }}
                />
              </ThemeProvider>
            </StyledDiv> :
            <div>
              <span>Você não tem notificações não lidas no momento</span>
            </div>
        )
        }
      </>
      <GenericText>
        {t('notifications.all.dataWarning')}
      </GenericText>
    </div>
  )
}

