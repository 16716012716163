import axios, { AxiosError, AxiosResponse } from "axios";
import { toast } from 'react-toastify';
import { AuthBodyType } from "./types";
import { PrintTemplateType } from "../components/Checklists/Types";

export type printTemplate = {
  id?: string,
  name: string | null;
  dafaultTemplate?: boolean;
  cliente?: string | null;
  headerText?: {
    bold?: boolean;
    italic?: boolean;
    size?: number | null;
    text?: string | null;
  };
  footerText?: {
    bold?: boolean | null;
    italic?: boolean;
    size?: number | null;
    text?: string | null;
  };
  color?: string | null;
  logo: File | string | null;
  status: boolean;
};

class PrintService {
  private api = axios.create({
    baseURL:
    `${process.env.REACT_APP_API_HOST}/printTemplate`,
  });

  getAllTemplates = async (body: AuthBodyType) => {
    try {
      const data: AxiosResponse = await this.api.post(
        "/getAll",
        {
          ...body,
        }
      );
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error(error.response?.status)
        return error.response?.status;
      }
    }
  };

  createPrintTemplate = async (
    body: AuthBodyType,
    printTemplate: printTemplate
  ) => {
    try {
      const data: AxiosResponse = await this.api.post(
        "/create",
        {
          ...body,
          printTemplate,
        }
      );
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error(error.response?.status)
        return error.response?.status;
      }
    }
  };

  editPrintTemplate = async (
    body: AuthBodyType,
    printTemplate: PrintTemplateType,
    id: string,
  ) => {
    try {
      const data: AxiosResponse = await this.api.post(
        "/edit",
        {
          ...body,
          printTemplate,
          id,
        }
      );
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error(error.response?.status)
        return error.response?.status;
      }
    }
  };

  getByClient = async (
    body: AuthBodyType,
    clientId: string
  ) => {
    try {
      const data: AxiosResponse = await this.api.post(
        "/getByClient",
        {
          ...body,
          clientId
        }
      );
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error(error.response?.status)
        return error.response?.status;
      }
    }
  };
}

export default PrintService;
