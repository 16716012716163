import React, { createContext, useState } from 'react';
import { FormToFilling, ItemFormFilling, SubItemFormFilling } from '../components/Filling/Types';

const initialSubItem: SubItemFormFilling = {
  id: 0,
  new_id: '', // eslint-disable-line
  itemid: '',
  nome: '',
  tipo: 'avaliacao',

  // visuais
  changes: null || false,

  // configuração
  ocorrenciaPreenchido: null || false,
  comentarioPreenchido: null || false,
  fotoPreenchido: null || false,
  comentario: null || false,
  foto: null || false,
  galeriaFoto: null || false,
  padrao: null,
  validacao: null,
  obrigatoriedade: {
    conforme: {
      img: false,
      ocorrencia: false,
    },
    naoConforme: {
      img: false,
      ocorrencia: false,
    },
    NA: {
      img: false,
      ocorrencia: false,
    }, //eslint-disable-line
    preenchimento: false,
    ocorrencia: false,
    img: false,
  },
  help: {
    foto: '',
    video: '',
    choose: '',
    comentario: '',
  },
  hasHelp: null || false,
  status: 'vazio',

  // lista
  padraoLista: null || [],

  // numero
  maxNumberVal: 0,
  minNumberVal: 0,
  noDecimal: false,

  // controle
  // checked: null,

  // avaliação
  // avaliacao: null || [{ valor: 0, nome: '', NA: null }], // eslint-disable-line
  // nota: null,
  // threshold: null,
  // peso: null,

  // seleção múltipla
  selecao: null || [],
  selecionados: null || [false] || [{
    texto: '',
    cssClass: {
      preenchido: null || false,
      erro: null || false,
    }
  }],
  temSelecionado: null || false,
  padraoMultiple: null,

  // assinatura
  assinatura: null || '',

  // textos
  ocorrencia: null,
  obs: null || '',

  // fotos
  img: null || [],

  // chamados
  limiteChamado: null || 0,
  tipoChamado: null || [{
    id: null || 0,
    mensagem: null || '',
    nome: null || '',
    tipo: null || '',
    emails: null || [],
    selecionado: null,
    remetente: null || '',
    prazo: null || 0,
    prazoHoras: null || 0,
  }],
  tempChamado: null || 0,
  chamados: null || [
    {
      selecionado: null || {
        id: null || 0,
        mensagem: null || '',
        nome: null || '',
        tipo: null || '',
        emails: null || [],
        selecionado: null,
        remetente: null || '',
        prazo: null || 0,
        prazoHoras: null || 0,
      },
      dateChamado: new Date(),
      textChamado: null || '',
      selKey: 0 || '' || null,
      shown: null || false,
      dateTime: null || '',
      statusChamado: null || '',
      statusPrazo: null || '',
    }
  ],
  chamadoObrigatorio: null || '',
  chamadoRed: null || false,

  // notificações
  tipoNotificacao: {
    id: 0,
    notificar: ''
  },

  // corAvl?: string,
  statusOcorrencia: null || '',
  statusObservacao: null || '',
  statusFoto: null || '',
  statusChamado: null || '',
  countItem: null || 0,
  icon: null || '',
  inputType: null || '',
  noAnim: null || false,
  shown: null || false,
  shownHelp: null || false,
  tempISODate: null || '',
  corSubHeader: null || '',

  // scoring
  valorScoring: null || 0,
  valorMaxScoring: null || 0,

  // hides
  hideImg: null || false,
  hideComent: null || false,

  // templates
  opcoes: null || [],
  opcoesEscolhidas: null || [],
  conformidade: null || '',
}

const initialItem: ItemFormFilling = {
  id: "",
  nome: "",
  peso: 0,
  subitens: [initialSubItem],
}

const initialState: FormToFilling = {
  usuario: '',
  aprovacao: {
    aprovadores: [''],
    status: "",
  },
  chamadoObrigatorio: false,
  cliente: "",
  conjunto: "",
  editavel: {
    cancela: false,
    clone: false
  },
  empresa: "",
  fim: 0,
  gerencia: "",
  id: "",
  identificadorLista: "",
  inicio: 0,
  itens: [initialItem],
}

export const WebFormContext = createContext<{ webForm: FormToFilling, setWebForm: Function }>({ webForm: initialState, setWebForm: () => { } })
export default function WebFormProvider({ children }: { children: React.ReactNode }) {
  const [webForm, setWebForm] = useState<FormToFilling>(initialState)

  const store = {
    webForm,
    setWebForm,
  }
  return (
    <WebFormContext.Provider value={store}>
      {children}
    </WebFormContext.Provider>
  )
}