import { memo } from "react"
import { trackEventMatomo } from "../../../helpers/matomo"

function ModalImagePreview({
  image,
  open,
  setOpen,
}: {
  image: string,
  open: Boolean,
  setOpen: Function,
}) {

  return (
    <div className={`modal ${open ? "is-active" : ""}`} style={{ zIndex: 999 }}>
      <div className="modal-background" onClick={() => {
        trackEventMatomo('Modal de imagem', 'click', 'modal', 'fecha')
        setOpen()
      }} />
      <div className="modal-content" style={{ display: 'flex' }}>
        <img style={{ maxHeight: '75svh', backgroundColor: 'white', position: 'relative' }} src={image} alt={image} />
        <button onClick={() => {
          setOpen(!open)
          trackEventMatomo('Modal de imagem', 'click', 'modal', 'fecha')
        }}
          className="delete"
          aria-label={'Fechar'}
          style={{ position: 'absolute', backgroundColor: 'red' }} />
      </div>
    </div>
  )
}

export default memo(ModalImagePreview)