import { useEffect, useMemo, useState } from 'react';
import ServiceTypes, { types } from '../../service/ServiceTypes';
import AttToken from '../../helpers/attToken';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { useTranslation } from 'react-i18next';
import { EditButton, StyledDiv } from '../TableHelpers/TableStyle';
import { tableOptions } from '../TableHelpers/options';
import { RiDeleteBin5Line, RiPencilFill } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { RedButton } from '../../StyledComponents/Input/generic';
import ModalServiceType from './Modals/ModalServiceType';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ThemeProvider } from '@mui/material/styles';
import Table from '../Skeletons/Table';
import { Skeleton } from '@mui/material';
import { getMuiTheme } from "../TableHelpers/options";
import { IoInformationCircleOutline } from 'react-icons/io5';
import ServiceInfoModal from './Modals/ServiceInfoModal';
import { trackEventMatomo } from '../../helpers/matomo';

export default function ServicesManagement() {
  const serviceType = useMemo(() => new ServiceTypes(), []);
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation('translation');
  const [typeData, setTypeData] = useState<types>({
    nome: '',
    tipoId: '',
    tipoDescicao: '',
    dataCriacao: 0,
  });
  const [services, setServices] = useState<types[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [tipo, setTipo] = useState<types | null>(null);

  const { data, isLoading, isError } = useQuery({
    queryKey: ['getServices'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const body = { ...token, empresa: token.userEmpresa };
        const result = await serviceType.getAllServices(body);
        return result
      }
    },
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (data) {
      setServices(data)
    }
  }, [data]);

  const { mutate: removeType } = useMutation({
    mutationKey: ['removeServiceType'],
    mutationFn: async (value: string) => {
      toast.warn('Removendo tipo de serviço')
      const token = await AttToken();
      if (token) {
        const body = { ...token, empresa: token.userEmpresa, tipoId: value };
        await serviceType.deleteServiceType(body);
      }
    },
    onSuccess: () => {
      toast.success('Serviço removido');
    },
    onError: () => {
      toast.error('Erro ao remover serviço!');
      return console.log(AxiosError);
    }
  })

  const columns = [
    {
      name: "nome",
      label: `${t('Services.servicesManagement.name')}`,
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "descricao",
      label: `${t('Services.servicesManagement.description')}`,
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "tipoId",
      label: t('edit'),
      options: {
        customBodyRender: (value: string) => {
          return (
            <EditButton
              className="button is-small is-light"
              onClick={() => {
                trackEventMatomo('tela serviços', 'click', 'button', 'abre modal')
                const selected = services.filter((e) => e.tipoId === value)[0];
                setTypeData(selected);
                setIsEditing(true);
                setOpenModal(true);
              }}>
              <RiPencilFill />
              <span>{t('edit')}</span>
            </EditButton>
          )
        },
        filter: true,
        sort: false,
      }
    },
    {
      name: "tipoId",
      label: t('delete'),
      options: {
        customBodyRender: (value: string, _tableMeta: { rowIndex: number }) => (
          <RedButton
            type='button'
            className="button is-small is-danger"
            onClick={() => {
              trackEventMatomo('tela serviços', 'click', 'button', 'deleta serviço')
              removeType(value);
              setServices(services.filter(e => e.tipoId !== value))
            }}
          >
            <RiDeleteBin5Line />
            <span>{t('delete')}</span>
          </RedButton>
        ),
        sort: false,
        filter: false
      }
    },
    {
      name: "tipoId",
      label: t('info'),
      options: {
        customBodyRender: (value: string, _tableMeta: { rowIndex: number }) => (
          <button
            type='button'
            className="button is-small is-info"
            onClick={() => {
              trackEventMatomo('tela serviços', 'click', 'button', 'abre modal')
              const selected = services.find((e) => e.tipoId === value);
              setTipo(selected || null);
            }}
          >
            <IoInformationCircleOutline size={30} />
          </button>
        ),
        sort: false,
        filter: false
      }
    }
  ];

  const translatedTextLabels: MUIDataTableOptions = {
    textLabels: {
      body: {
        noMatch: `${t('table.noMatch')}`,
        toolTip: `${t('table.toolTip')}`,
        columnHeaderTooltip: column =>
          `${t('table.columnHeaderTooltip')} ${column.label}`
      },
      pagination: {
        next: `${t('table.next')}`,
        previous: `${t('table.previous')}`,
        rowsPerPage: `${t('table.rowsPerPage')}`,
        displayRows: `${t('table.displayRows')}`
      },
      toolbar: {
        search: `${t('table.search')}`,
        filterTable: `${t('table.filterTable')}`
      },
      filter: {
        title: `${t('table.title')}`,
        reset: `${t('table.reset')}`
      },
      viewColumns: {
        title: `${t('table.viewColumnsTitle')}`
      },
    },
  };

  if (isLoading) {
    return (
      <>
        <Skeleton width={300} height={80} />
        <Table />
      </>
    )
  }

  if (isError) {
    return <span>Erro ao buscar dados</span>
  }

  return (
    <>
      <>
        <ModalServiceType
          open={openModal}
          setOpen={setOpenModal}
          typeData={typeData}
          setTypeData={setTypeData}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
        />
        {tipo && (
          <ServiceInfoModal
            tipo={tipo}
            setTipo={setTipo}
          />
        )}
        <div style={{ display: 'flex', marginBottom: '.5em' }}>
          <button
            onClick={() => {
              trackEventMatomo('tela serviços', 'click', 'button', 'abre modal')
              setOpenModal(true)
            }}
            className='button is-medium is-success'>
            {`${t('notifications.ticket.criarTipoServico')} +`}
          </button>
        </div>

        {
          <StyledDiv>
            <ThemeProvider theme={getMuiTheme()}>
              <MUIDataTable
                title={`${t('Services.servicesManagement.kind')}`}
                data={services}
                columns={columns}
                options={{ ...tableOptions, ...translatedTextLabels }}
              />
            </ThemeProvider>
          </StyledDiv>
        }
      </>
    </>

  )
}