import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import React, { useEffect, useState } from 'react'
import { RedButton } from '../../StyledComponents/Input/generic'
import { tableOptions, getMuiTheme } from "../TableHelpers/options";
import { StyledDiv } from '../TableHelpers/TableStyle';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { RiDeleteBin5Line } from "react-icons/ri";
import { useTranslation } from 'react-i18next';
import Clients from "../../service/Clients";
import Loading from '../Loading/Loading';
import AttToken from '../../helpers/attToken';
import { useMutation, useQuery } from '@tanstack/react-query';
import queryClient from '../../service/query';
import { AxiosError } from 'axios';
import { TagType } from './ChangeCustomerModal';
import { ThemeProvider } from '@mui/material/styles';
import Table from '../Skeletons/Table';
import { Skeleton } from '@mui/material';
import { trackEventMatomo } from '../../helpers/matomo';

const customerService = new Clients();

export default function AddCustomerTag() {

  const [tagName, setTagName] = useState<string>('');
  const [isTagSaved] = useState(false);
  const [customerTags, setCustomerTags] = useState<TagType[]>([]);
  const [disabled, setDisable] = useState(false)

  const { t } = useTranslation('translation');


  const { data, isLoading, isError } = useQuery({
    queryKey: ['getTags'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const response = await customerService.getClientTag(token);
        if (response) {
          return response.tags
        }
      }
    },
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
  })

  const { mutate: addNewTag } = useMutation({
    mutationKey: ['newTag'],
    mutationFn: async () => {
      setDisable(true)
      toast.warn('Salvando')
      const token = await AttToken();
      if (token) {
        const body = { ...token, tag: tagName }
        await customerService.createClientTag(body);
        queryClient.resetQueries(['getTags'])
      }
    },
    onSuccess: () => {
      toast.success(t<string>('customerPage.tagCreated'));
      setTagName('');
    },
    onError: () => toast.warn('Erro ao criar nova tag!'),
  });

  const { mutate: removeTag } = useMutation({
    mutationKey: ['deleteTag'],
    mutationFn: async (value: string) => {
      const token = await AttToken();
      if (token) {
        const body = { ...token, tagId: value }
        await customerService.deleteClientTag(body);
      }
    },
    onError: () => console.log(AxiosError)
  });

  useEffect(() => {
    if (data) {
      setCustomerTags(data)
    }
  }, [data]);

  const columns = [
    {
      name: 'name',
      label: t('tag'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: 'id',
      label: t('delete'),
      options: {
        customBodyRender: (value: string) => (
          <RedButton
            type='button'
            className="button is-danger is-small"
            onClick={() => {
              trackEventMatomo('Adicionar tag cliente', 'click', 'button', 'deletar tag')
              removeTag(value);
              setCustomerTags(customerTags.filter(e => e.id !== value))
              toast.error(t<string>('customerPage.tagExcluded'));
            }}
          >
            <RiDeleteBin5Line />
            <span>{t('delete')}</span>
          </RedButton>
        ),
        sort: false,
        filter: false
      }
    },
  ]

  const translatedTextLabels: MUIDataTableOptions = {
    textLabels: {
      body: {
        noMatch: `${t('table.noMatch')}`,
        toolTip: `${t('table.toolTip')}`,
        columnHeaderTooltip: column =>
          `${t('table.columnHeaderTooltip')} ${column.label}`
      },
      pagination: {
        next: `${t('table.next')}`,
        previous: `${t('table.previous')}`,
        rowsPerPage: `${t('table.rowsPerPage')}`,
        displayRows: `${t('table.displayRows')}`
      },
      toolbar: {
        search: `${t('table.search')}`,
        filterTable: `${t('table.filterTable')}`
      },
      filter: {
        title: `${t('table.title')}`,
        reset: `${t('table.reset')}`
      },
      viewColumns: {
        title: `${t('table.viewColumnsTitle')}`
      },
    },
  };

  if (isLoading) {
    return (
      <>
        <div style={{ display: 'flex' }}>
          <Skeleton width={450} height={50} />&nbsp;<Skeleton width={80} height={50} />
        </div>
        <Table />
      </>
    )
  }

  if (isError) {
    return <span>Erro ao carregar</span>
  }

  return (
    <form onSubmit={(e) => {
      e.preventDefault();
      addNewTag()
      trackEventMatomo('Adicionar tag cliente', 'click', 'button', 'criar nova tag')
    }}>
      {isTagSaved && (
        <ToastContainer />
      )}
      <StyledDiv>
        <div style={{ display: 'flex', marginBottom: '1em' }}>
          <input
            style={{ width: '30vw' }}
            value={tagName}
            onClick={() => trackEventMatomo('Adicionar tag cliente', 'click', 'input', 'editar valor tag')}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setTagName(e.target.value);
              setDisable(false)
            }}
            placeholder={t('users.tagControll.inputPlacholder')}
            className="input is-small is-fullwidth"
          />
          <button
            style={{ marginLeft: '.5em' }}
            type="submit"
            className='button is-small is-success'
            disabled={tagName === '' || disabled}
          >
            {`+ ${t('register')}`}
          </button>
        </div>
        {isLoading ? <Loading /> :
          <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              title={t('users.tagControll.tagList')}
              columns={columns}
              data={customerTags}
              options={{ ...tableOptions, ...translatedTextLabels }}
            />
          </ThemeProvider>
        }
      </StyledDiv>
    </form>
  )
}
