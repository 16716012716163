import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { TabStyle } from "../TabsStyle/TabsStyle";
import { useTranslation } from "react-i18next";
import PlacesComponent from "./PlacesComponent";
import ServicesManagement from "./ServicesManagement";
import { UserContext } from "../../context/UserContext";
import { useQuery } from "@tanstack/react-query";
import AttToken from "../../helpers/attToken";
import ServiceTypes from "../../service/ServiceTypes";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { trackEventMatomo } from "../../helpers/matomo";

export default function ServicesTabs() {
  const { hierarchyLabel, setServiceTypesList } = useContext(UserContext);
  const { t, i18n } = useTranslation('translation');

  const { trackEvent } = useMatomo()

  const serviceType = useMemo(() => new ServiceTypes(), []);

  const getServiceTypes = useCallback(async () => {
    try {
      const token = await AttToken();
      if (token) {
        const body = { ...token, empresa: token.userEmpresa };
        const result = await serviceType.getAllServices(body);
        if (result) {
          return result
        }
      }
    } catch (err) {
      return console.log(err);
    }
  }, [serviceType]);

  const { data: serviceNames } = useQuery({
    queryKey: ['serviceTypes'],
    queryFn: () => getServiceTypes(),
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (serviceNames) setServiceTypesList(serviceNames)
  }, [serviceNames, setServiceTypesList])

  const mapNames = {
    places: i18n.language !== 'en' ? `${t('Services.places')} ${hierarchyLabel.local}` : `${hierarchyLabel.local} ${t('Services.places')} `,
    services: t('Services.services'),
  }

  const [isTab, setIsTab] = useState({
    isActiveObject: 'places',
    object: ['places', 'services']
  });

  const toggleClass = (index: number) => {
    setIsTab({ ...isTab, isActiveObject: isTab.object[index] })
    trackEventMatomo('tela gestão de serviços', 'click', 'tab', 'troca tab')
  }

  const toggleActive = (index: number) => {
    if (isTab.object[index] === isTab.isActiveObject) {
      return "is-active"
    } else {
      return "inactve"
    }
  }
  return (
    <div style={{ width: '95%' }}>
      <div className="tabs is-boxed" >
        <ul>
          {isTab.object.map((el, index) => (
            <li key={index} className={toggleActive(index)} onClick={() => { toggleClass(index) }}>
              <TabStyle theme={toggleActive(index)}>{mapNames[el as keyof typeof mapNames]}</TabStyle>
            </li>
          ))}
        </ul>
      </div>
      {isTab.isActiveObject === 'places' && (
        <PlacesComponent />
      )}
      {isTab.isActiveObject === 'services' && (
        <ServicesManagement />
      )}
    </div>
  )
}
