import React, { useCallback, useEffect, useState } from 'react';
import { UnidadeType } from '../../../service/Places';
import { Card, Tooltip } from '@material-ui/core';
import { GenericHeader } from '../../../StyledComponents/Modal/generic';
import { IoQrCodeOutline, IoQrCodeSharp } from 'react-icons/io5';
import QRCode from "react-qr-code";
import { useTranslation } from 'react-i18next';
import Unity from '../../../service/Unity';
import { toast } from 'react-toastify';
import AttToken from '../../../helpers/attToken';
import { useToPng } from '@hugocxl/react-to-image';
import { trackEventMatomo } from '../../../helpers/matomo';


type props = {
  unity: UnidadeType;
}

const unityController = new Unity();

export default function QrCodeModal({ unity }: props) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(unity.qrcode || '');
  const [loading, setLoading] = useState(false);

  const [state, convertToPng, ref] = useToPng<HTMLDivElement>({
    onSuccess: data => {
      console.log(data)
      let a = document.createElement("a");
      a.href = data
      a.download = "QrCodeComTitulo.png";
      a.click();
    }
  })

  const [stateWithout, convertWithout, refWithout] = useToPng<HTMLDivElement>({
    onSuccess: data => {
      console.log(data)
      let a = document.createElement("a");
      a.href = data
      a.download = "QrCode.png";
      a.click();
    }
  })


  const { t } = useTranslation('translation');

  const escape = useCallback((e: any): void => {
    if (e.key === 'Escape' && setOpen) {
      setOpen(false);
    }
  }, [setOpen])

  useEffect(() => {
    document.addEventListener('keydown', escape, true)
  }, [escape])

  const addQrCode = async () => {
    try {
      setLoading(true);
      toast.warn('Criando QR Code...')
      const token = await AttToken();
      if (token) {
        await unityController.addQRCode(token, unity.id)
        toast.success('QR Code Criado com sucesso!')
        setLoading(false)
      } else {
        setLoading(false);
        throw new Error('Falha ao resgatar o token')
      }
    } catch (err: any) {
      toast.error(err.message)
      setLoading(false);
    }
  }

  return (
    <>
      <div>
        {unity.qrcode ?
          <Tooltip title={'Ver QR Code'}>
            <span>
              <button
                onClick={() => {
                  trackEventMatomo('Modal QR code', 'click', 'button', 'salva')
                  setOpen(true);
                }}
              >
                <IoQrCodeSharp size={25} />
              </button>
            </span>
          </Tooltip>
          :
          <Tooltip title={'Adicionar QR Code'}>
            <span>
              <button
                onClick={async () => {
                  trackEventMatomo('Modal QR code', 'click', 'button', 'salva')
                  await addQrCode();
                  setValue(unity.id)
                  unity.qrcode = unity.id;
                  setOpen(true)
                }}
                disabled={loading}
              >
                <IoQrCodeOutline size={25} />
              </button>
            </span>
          </Tooltip>
        }
      </div>
      <Card>
        <div className={`modal ${open ? "modal is-active" : "modal"}`}>
          <div
            className="modal-background"
            onKeyDown={escape}
            onClick={() => {
              trackEventMatomo('Modal QR code', 'click', 'div', 'fecha')
              setOpen(false)
            }} />
          <div className="modal-card" onSubmit={(e: React.FormEvent) => e.preventDefault()}>
            <header className="modal-card-head" style={{ display: "flex", flexDirection: "column" }}>
              <GenericHeader>
                QR CODE
              </GenericHeader>
            </header>
            <div className="modal-card-body">
              <div style={{ padding: '10px', backgroundColor: 'white' }} ref={ref}>
                <h1 style={{ margin: '10px', fontSize: '1.7em' }}>{unity.nome}</h1>
                <div style={{ padding: '10px', backgroundColor: 'white' }} ref={refWithout}>
                  <QRCode
                    size={256}
                    style={{ height: "auto", maxWidth: "50%", width: "50%" }}
                    value={value}
                    viewBox={`0 0 256 256`}
                  />
                </div>
              </div>
              <div style={{ display: 'flex', width: '100%', justifyContent: 'space-around' }}>
                <button className="button" onClick={convertToPng} style={{ width: '40%' }}>
                  Baixar QR Code Com Título
                </button>
                <button className="button" onClick={convertWithout} style={{ width: '40%' }}>
                  Baixar QR Code Sem Título
                </button>
              </div>
              <div style={{ margin: '10px' }}>
                <h1>Código da unidade:</h1>
                <h1 style={{ fontWeight: 'bold' }}>{value}</h1>
              </div>
            </div>
            <div className='modal-card-foot' style={{ display: "flex", justifyContent: "center" }}>
              <button
                className='button is-white'
                onClick={() => {
                  trackEventMatomo('Modal QR code', 'click', 'button', 'fecha')
                  setOpen(false)
                }}
                style={{ width: '100px' }}
              >{t('close')}</button>
            </div>
          </div>
        </div>
      </Card>
    </>
  )
}
