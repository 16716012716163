import { useRef, useEffect } from "react";
import { selectedPlaceType } from "./Modals/NewPlaceModal";
import { trackEventMatomo } from "../../helpers/matomo";

const PlacesAutocomplete = ({
  setSelected,
  selected
}: {
  setSelected: Function,
  selected: selectedPlaceType
}) => {
  const autoCompleteRef = useRef<google.maps.places.Autocomplete>();
  const inputRef = useRef<any>();

  useEffect(() => {
    const options = {
      componentRestrictions: { country: "br" },
      fields: ["address_components", "geometry", "icon", "name", "formatted_address"]
    };
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );
    autoCompleteRef.current.addListener("place_changed", async function () {
      const current = autoCompleteRef.current;
      if (current) {
        const place = current.getPlace();
        setSelected({
          lat: place.geometry?.location?.lat(),
          lng: place.geometry?.location?.lng(),
          address: place.formatted_address
        });
      }
    });
  }, [setSelected]);

  return (
    <input
      value={selected.address}
      ref={inputRef}
      onChange={(eve) => {
        trackEventMatomo('modal de autocomplete local', 'click', 'input', 'edita valor')
        setSelected({ lat: 0, lng: 0, address: eve.target.value })
      }}
      className="input is-fullwidth" />
  )
};

export default PlacesAutocomplete;