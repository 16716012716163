import { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { StyledLinkDiv } from './SideBarStyle';
import { AiOutlineBarChart, AiOutlineForm } from 'react-icons/ai'
import { IoHome, IoPersonSharp, IoMailSharp } from 'react-icons/io5';
import { IoIosPeople, IoMdCheckboxOutline } from 'react-icons/io';
import { BsFillGearFill } from 'react-icons/bs';
import { GiGreekTemple, GiTeamIdea } from 'react-icons/gi';
import { FaUserTie, FaChartPie, FaNetworkWired } from 'react-icons/fa';
import { UserContext } from '../../context/UserContext';
import { useTranslation } from 'react-i18next';
import { MdSpeakerNotes } from "react-icons/md";
import { FaListAlt } from "react-icons/fa";
import { useQuery } from '@tanstack/react-query';
import AttToken from '../../helpers/attToken';
import PlanoDeAcao from '../../service/PlanoDeAcao';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';

const planoService = new PlanoDeAcao();

export default function Links({ option }: { option: string }) {
  const { isHidden, hierarchyLabel, setAnalisesPendentes, setLoadingAnalises, userData, acessoPlanoDeAcao } = useContext(UserContext);
  const [totalAnalises, setTotalAnalises] = useState<number>(0)
  const location = useLocation();
  const { t, i18n } = useTranslation('translation');

  const { data } = useQuery({
    queryKey: ['GET_ANALISES_PENDING'],
    queryFn: async () => {
      setLoadingAnalises(true);
      const token = await AttToken()
      if (token) {
        const response = await planoService.getPendingList(token)
        setLoadingAnalises(false);
        return response
      }
    },
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  })

  useEffect(() => {
    if (data) {
      setTotalAnalises(data?.total)
      setAnalisesPendentes(data.planos)
    }
  }, [data, setAnalisesPendentes])

  switch (option) {
    case 'pesquisas':
      return (
        <Link to={`/${option}`}>
          <StyledLinkDiv theme={location.pathname === '/pesquisas' ? 'selected' : ''}>
            <MdSpeakerNotes className={isHidden ? 'hidden-icon' : 'icon'} />
            {!isHidden && <h1>Pesquisa (Legado)</h1>}
          </StyledLinkDiv>
        </Link>
      )
    case 'empresas':
      return (
        <Link to={`/${option}`}>
          <StyledLinkDiv theme={location.pathname === '/empresas' ? 'selected' : ''}>
            <GiGreekTemple className={isHidden ? 'hidden-icon' : 'icon'} />
            {!isHidden && <h1>{t('sidebar.company')}</h1>}
          </StyledLinkDiv>
        </Link>
      )
    case 'plano-de-acao':
      return (
        <Link to={`/${option}`}>
          <StyledLinkDiv theme={location.pathname === '/plano-de-acao' ? 'selected' : ''}>
            <FaListAlt className={isHidden ? 'hidden-icon' : 'icon'} />
            {!isHidden && <>
              <h1>Plano de Ação</h1>&nbsp;&nbsp;
              {acessoPlanoDeAcao && userData.gestorPA && totalAnalises > 0 ? <span
                className='tag is-warning'
                style={{ borderRadius: '50%', fontWeight: 700 }}>
                {totalAnalises}
              </span> : <></>}
            </>
            }
          </StyledLinkDiv>
        </Link>
      )
    case 'estatisticas':
      return (
        <Link to={`/${option}`}>
          <StyledLinkDiv theme={location.pathname === '/estatisticas' ? 'selected' : ''}>
            <StackedLineChartIcon className={isHidden ? 'hidden-icon' : 'icon'} />
            {!isHidden && <h1>Estatisticas</h1>}
          </StyledLinkDiv>
        </Link>
      )
    case 'configuracoes':
      return (
        <Link to={`/${option}`}>
          <StyledLinkDiv theme={location.pathname === '/configuracoes' ? 'selected' : ''}>
            <BsFillGearFill className={isHidden ? 'hidden-icon' : 'icon'} />
            {!isHidden && <h1>{t('settings')}</h1>}
          </StyledLinkDiv>
        </Link>
      )
    case 'notificacaoWeb':
      return (
        <Link to={`/${option}`}>
          <StyledLinkDiv theme={location.pathname === '/notificacaoWeb' ? 'selected' : ''}>
            <IoHome className={isHidden ? 'hidden-icon' : 'icon'} />
            {!isHidden && <h1>{t('sidebar.notifications')}</h1>}
          </StyledLinkDiv>

        </Link>
      )
    case 'usuarios':
      return (
        <Link to={`/${option}`}>
          <StyledLinkDiv theme={location.pathname === '/usuarios' ? 'selected' : ''}>
            <IoPersonSharp className={isHidden ? 'hidden-icon' : 'icon'} />
            {!isHidden && <h1>{t('sidebar.users')}</h1>}
          </StyledLinkDiv>

        </Link>
      )
    case 'equipes':
      return (
        <Link to={`/${option}`}>
          <StyledLinkDiv theme={location.pathname === '/equipes' ? 'selected' : ''}>
            <GiTeamIdea className={isHidden ? 'hidden-icon' : 'icon'} />
            {!isHidden && <h1>{t('sidebar.team')}</h1>}
          </StyledLinkDiv>

        </Link>
      )
    case 'clientes':
      return (
        <Link to={`/${option}`}>
          <StyledLinkDiv theme={location.pathname === '/clientes' ? 'selected' : ''}>
            <IoIosPeople className={isHidden ? 'hidden-icon' : 'icon'} />
            {!isHidden &&
              <h1>
                {
                  i18n.resolvedLanguage !== 'en' ?
                    `${t('sidebar.management')} ${hierarchyLabel.cliente}` :
                    `${hierarchyLabel.cliente} ${t('sidebar.management')}`
                }
              </h1>
            }
          </StyledLinkDiv>

        </Link>
      )
    case 'notificacoes':
      return (
        <Link to={`/${option}`}>
          <StyledLinkDiv theme={location.pathname === '/notificacoes' ? 'selected' : ''}>
            <IoMailSharp className={isHidden ? 'hidden-icon' : 'icon'} />
            {!isHidden && <h1>{t('sidebar.callsAndNotifications')}</h1>}
          </StyledLinkDiv>
        </Link>
      )
    case 'formularios':
      return (
        <Link to={`/${option}`}>
          <StyledLinkDiv theme={location.pathname === '/formularios' ? 'selected' : ''}>
            <IoMdCheckboxOutline className={isHidden ? 'hidden-icon' : 'icon'} />
            {!isHidden && <h1>{t('sidebar.forms')}</h1>}
          </StyledLinkDiv>
        </Link>
      )
    case 'gerencias':
      return (
        <Link to={`/${option}`}>
          <StyledLinkDiv theme={location.pathname === '/gerencias' ? 'selected' : ''}>
            <FaUserTie className={isHidden ? 'hidden-icon' : 'icon'} />
            {!isHidden &&
              <h1>
                {
                  i18n.resolvedLanguage !== 'en' ?
                    `${t('sidebar.management')} ${hierarchyLabel.gerencia ? hierarchyLabel.gerencia : 'Gerência'}` :
                    `${hierarchyLabel.gerencia ? hierarchyLabel.gerencia : 'Gerência'} ${t('sidebar.management')}`
                }
              </h1>
            }
          </StyledLinkDiv>

        </Link>
      )
    case 'locais':
      return (
        <Link to={`/${option}`}>
          <StyledLinkDiv theme={location.pathname === '/locais' ? 'selected' : ''}>
            <FaNetworkWired className={isHidden ? 'hidden-icon' : 'icon'} />
            {!isHidden &&
              <h1>
                {
                  t('sidebar.services')}
              </h1>
            }
          </StyledLinkDiv>

        </Link>
      )
    case 'relatorios':
      return (
        <Link to={`/${option}`}>
          <StyledLinkDiv theme={location.pathname === '/relatorios' ? 'selected' : ''}>
            <AiOutlineBarChart className={isHidden ? 'hidden-icon' : 'icon'} />
            {!isHidden && <h1>{t('sidebar.report')}</h1>}
          </StyledLinkDiv>

        </Link>
      )
    case 'powerbi':
      return (
        <Link to={`/powerbi`}>
          <StyledLinkDiv theme={location.pathname === '/powerbi' ? 'selected' : ''}>
            <FaChartPie className={isHidden ? 'hidden-icon' : 'icon'} />
            {!isHidden && <h1>Dashboards</h1>}
          </StyledLinkDiv>
        </Link>
      )
    case 'preenchimento':
      return (
        <Link to={`/preenchimento`}>
          <StyledLinkDiv theme={location.pathname === '/preenchimento' ? 'selected' : ''}>
            <AiOutlineForm className={isHidden ? 'hidden-icon' : 'icon'} />
            {!isHidden && <h1>Preenchimento</h1>}
          </StyledLinkDiv>
        </Link>
      )
    default:
      return (
        <></>
      )
  }
}