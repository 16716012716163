// import cfac22 from "../library/utils/cfac22/cfac22";

export function startMatomo() {
  var _paq = (window._paq = window._paq || []);
  let infoData = {};
  const userInfoAuxiliar = localStorage.getItem("userInfoAuxiliar");
  if (userInfoAuxiliar) {
    infoData = JSON.parse(userInfoAuxiliar);
  }
  _paq.push(["enableLinkTracking"]);
  (function () {
    var u = "https://matomo.triibo.com.br/";
    _paq.push(["setTrackerUrl", u + "matomo.php"]);
    // _paq.push(["setSiteId", cfac22("idMatomo")]);
    if (infoData !== null && infoData !== undefined) _paq.push(["setUserId", infoData.username]);

    var d = document,
      g = d.createElement("script"),
      s = d.getElementsByTagName("script")[0];
    g.type = "text/javascript";
    g.async = true;
    g.src = u + "matomo.js";
    s.parentNode.insertBefore(g, s);
  })();
}

export function trackEventMatomo(location, event, element, elementName) {
  const elementType = `element:${element}; elementName:${elementName}`;

  var _paq = (window._paq = window._paq || []);
  _paq.push(["trackEvent", location, event, elementType]);
}

export function trackEventMatomoElementId(location, event, element, elementId) {
  const elementType = `element:${element}; elementId:${elementId}`;

  var _paq = (window._paq = window._paq || []);
  _paq.push(["trackEvent", location, event, elementType]);
}

export function trackEventMatomoVisit(locationName) {
  var _paq = (window._paq = window._paq || []);
  _paq.push(["trackEvent", locationName, "visualizar", window.location.href, 0]);
}
