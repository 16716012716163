import styled from "styled-components";

export const TranslationContainer = styled.div`
  display: flex;
  align-self: center;
  justify-content: space-around;
  h1 {
    color: white;
    width: 5em;
  }
  max-width: 50px;
  .selected {
    min-width: 35px;
    /* opacity: 100% !important; */
    margin: 2px;
    cursor: not-allowed !important;
    border: 1px solid black;
  }
  .flag {
    margin: 2px;
    min-width: 20px;
    max-width: 20px;
    cursor: pointer;
    /* opacity: 0.5; */
  }
`;

export const FlagButton = styled.button`
  background-color: inherit;
  padding: 0px;
  margin: 0 3px;
  border: none;
  max-width: 1px;
  :hover {
    outline:none;
    border-color: #44c1cd;
    box-shadow:0 0 10px #44c1cd;
    border-radius: 10px;
    cursor: pointer;
  }
`
