import { MainContainerMulti } from './Multiple/MultipleStyle'
import { Container } from './Unique/UniqueStyle'
import { SubItemProps } from './Avaliacao/Avaliacao';
import SubItemPhoto from './SubItemPhoto';
import { useEffect, useState } from 'react';
import { trackEventMatomo } from '../../../../helpers/matomo';

export default function Email({ subItem, indexItem, onChange }: SubItemProps) {
  const [ocorrencia, setOcorrencia] = useState<string>('');

  const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  useEffect(() => {
    if (subItem.padrao) {
      setOcorrencia(subItem.padrao)
      subItem.ocorrencia = subItem.padrao
    }
  }, [])

  useEffect(() => {
    onChange && onChange()
  }, [ocorrencia])

  const borderCheckerComent = () => {
    if (subItem.ocorrencia || subItem.ocorrencia?.length) {
      if (!regexEmail.test(ocorrencia)) {
        return '0.3em #FB8E27 solid'
      }
    }
    if (!subItem.ocorrencia || !subItem.ocorrencia?.length) {
      if (subItem?.obrigatoriedade?.preenchimento) {
        return '0.3em red solid'
      }
      if (!subItem?.obrigatoriedade?.preenchimento) {
        return '0.3em #2196f3 solid'
      }
    } else return '0.3em #4caf50 solid'
  }

  return (
    <MainContainerMulti>
      <Container>
        <input
          onClick={() => trackEventMatomo('email', 'click', 'input', 'edita valor')}
          type='email'
          style={{ borderBottom: `${borderCheckerComent()}`, marginTop: '0.5em' }}
          value={ocorrencia?.toString()}
          onChange={async (e) => {
            setOcorrencia(e.target.value);
            subItem.ocorrencia = e.target.value;
          }}
          className="input"
          placeholder="Toque para digitar" />
        {subItem.ocorrencia && subItem.foto &&
          <SubItemPhoto subItem={subItem} indexItem={indexItem} onChange={onChange} />}
      </Container>
    </MainContainerMulti>
  )
}