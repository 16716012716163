import { ColorResult, SketchPicker } from 'react-color'
import { PreviewColor } from '../../FormStyle';
import { modeloStyleType } from '../../../../service/checklistStyle';
import { trackEventMatomo } from '../../../../helpers/matomo';

type props = {
  objectToSend: modeloStyleType
  setObjectToSend: Function,
  fonts: string[],
}

export default function Body({
  objectToSend,
  setObjectToSend,
  fonts }: props) {

  return (
    <div className='columns' style={{ display: 'flex', marginTop: '.5em', width: 'fit-content', justifyContent: 'space-around' }}>
      <div className='column is-3' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 0, marginRight: '5px' }}>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', padding: 0 }}>
          <p style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'left' }}>Escolha a cor de fundo:</p>
          <PreviewColor
            style={{ backgroundColor: objectToSend.background!!! }}
          />
        </div>
        <SketchPicker
          width='150px'
          color={objectToSend.background!!!}
          onChangeComplete={
            (color: ColorResult) => {
              trackEventMatomo('Configurar impressões', 'click', 'input', 'seleciona cor')
              setObjectToSend({ ...objectToSend, background: color.hex!!! })
            }
          }
          disableAlpha
          presetColors={['#fff', '#000', '#f00', '#0f0', '#00f']}
        />
      </div>
      <div className='column is-3' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 0 }}>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', padding: 0 }}>
          <p style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'left' }}>Escolha a cor da fonte:</p>
          <PreviewColor
            style={{ backgroundColor: objectToSend.fontBackground?.color!!! }}
          />
        </div>
        <SketchPicker
          width='150px'
          color={objectToSend.fontBackground?.color!!!}
          onChangeComplete={
            (color: ColorResult) => {
              trackEventMatomo('Configurar impresões', 'click', 'input', 'seleciona cor')
              setObjectToSend({ ...objectToSend, fontBackground: { ...objectToSend.fontBackground, color: color.hex!!! } })
            }
          }
          disableAlpha
          presetColors={['#fff', '#000', '#f00', '#0f0', '#00f']}
        />
      </div>
      <div className='column is-5' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <p style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'left' }}>Escolha fonte:</p>
        <div style={{ display: 'flex' }}>
          <div className="select is-small is-fullwidth">
            <select
              onClick={() => trackEventMatomo('Configurar impresões', 'click', 'select', 'seleciona fonte')}
              onChange={(e) => setObjectToSend({ ...objectToSend, fontBackground: { ...objectToSend.fontBackground, fontFamily: e.target.value } })}
              defaultValue={'Arial'}>
              {fonts.map((e: string, i: number) =>
                <option key={i} value={e}>{e}</option>
              )}
            </select>
          </div>
          <input
            onClick={() => trackEventMatomo('Configurar impresões', 'click', 'input', 'tamanho da fonte')}
            value={objectToSend.fontBackground?.fontSize!!!}
            onChange={(e) => setObjectToSend({ ...objectToSend, fontBackground: { ...objectToSend.fontBackground, fontSize: Number(e.target.value) } })}
            className='input is-small column is-2' />
          <input className='input is-small column is-2' disabled value={'px'} />
        </div>
      </div>
    </div>
  )
}
