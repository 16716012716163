import FormLogin from "../components/FormLogin/FormLogin"
import "../css/Login.css";
import { useEffect } from "react";
import { trackEventMatomoVisit } from "../helpers/matomo";

export default function Login() {
  useEffect(() => {
    trackEventMatomoVisit('Login')
  }, [])

  return (
    <FormLogin />    
  )
}
