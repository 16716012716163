import { MdCancel } from "react-icons/md";

export default function TagComp({ values, setValues, valuesArray }: { values: string[] | null, setValues: Function, valuesArray: string[] }) {
  const findValue = (value: string) => {
    const valor = valuesArray.find((el) => Object.keys(el)[0] === value);
    if (valor) return Object.values(valor)[0]
    else return 'Status não encontrado';
  }

  return (
    <>
   { values?.map((value: string) =>
      <span
        onClick={() => {
          setValues(values?.filter((elem) => elem !== value))
        }}
        style={{ margin: '2px' }}
        className='tag'>
        {findValue(value)}&nbsp;<MdCancel />
      </span>)}
    </>
  )
}
