import { Accordion, AccordionSummary, TextField } from '@material-ui/core'
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import { ThemeProvider } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getMuiTheme } from "../TableHelpers/options";
import { tableOptions } from '../TableHelpers/TableOptions';
import { StyledDiv } from '../TableHelpers/TableStyle';
import { useMutation, useQuery } from '@tanstack/react-query';
import AttToken from '../../helpers/attToken';
import Pesquisa, { perguntasType, pesquisaType, respostasType } from '../../service/Pesquisas';
import ModalRespostas from './ModalRespostas';
import { Pagination, Skeleton } from '@mui/material';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { trackEventMatomo } from '../../helpers/matomo';


const pesquisaService = new Pesquisa();

export default function ResearchesComp() {
  const [expanded, setExpanded] = useState<string>('');
  const [selected, setSelected] = useState<respostasType>({} as respostasType);
  const [openModal, setOpenModal] = useState(false);
  const [search, setSearch] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [paginated, setPaginated] = useState<pesquisaType[]>([]);
  const { t } = useTranslation('translation');

  const { data, isLoading } = useQuery({
    queryKey: ['GET_RESEARCHES'],
    queryFn: async () => {
      const token = await AttToken()
      if (token) {
        const response = await pesquisaService.getAll(token)
        return response
      }
    },
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  })

  const lastPageIndex = currentPage * itemsPerPage;
  const firstPageIndex = lastPageIndex - itemsPerPage;

  useEffect(() => {
    if (data) {
      const currentPageData = data?.sort((a: pesquisaType, b: pesquisaType) => { return a.dataCadastro < b.dataCadastro ? 1 : -1 })
        ?.filter((e: pesquisaType) => e.nome.toLowerCase().includes(search.toLowerCase()))
      search ? setPaginated(currentPageData?.slice(0, 5)) : setPaginated(currentPageData?.slice(firstPageIndex, lastPageIndex))
    }
  }, [firstPageIndex, lastPageIndex, data, search])

  let pages = [];

  for (let i = 1; i <= Math.ceil(data?.length / itemsPerPage); i++) {
    pages.push(i)
  }

  const { data: respostas, isLoading: loadingRespostas, mutate } = useMutation({
    mutationKey: ['GET_RESPOSTAS'],
    mutationFn: async (value: string) => {
      const token = await AttToken()
      if (token) {
        const response = await pesquisaService.getExecutions(token, value)
        return response
      }
    }
  })

  const columns = [
    {
      name: "inicio",
      options: {
        display: false,
        filter: false,
        sort: true,
        sortThirdClickReset: true,
      }
    },
    {
      name: "inicio",
      label: 'Iniciado em',
      options: {
        filter: false,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value: number) => {
          return <span>{new Date(value).toLocaleString('pt-BR')}</span>
        }
      }
    },
    {
      name: "fim",
      label: 'Finalizado em',
      options: {
        filter: false,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value: number) => {
          return <span>{new Date(value).toLocaleString('pt-BR')}</span>
        }
      }
    },
    {
      name: 'id',
      label: 'Duração',
      options: {
        filter: false,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value: string) => {
          const duracao = respostas.find((elem: respostasType) => elem.id === value)
          const inicio = new Date(duracao?.inicio).getTime();
          const fim = new Date(duracao?.fim).getTime();
          const hour = new Date(inicio).getHours() - new Date(fim).getHours()
          const diff = Number(fim) - Number(inicio)
          const hora = new Date(diff).toLocaleTimeString()
          const sub = hora.substring(2)
          return <span>{`${hour}` + sub}</span>
        }
      }
    },
    {
      name: "perguntas",
      label: 'Preenchimento',
      options: {
        filter: false,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (pergunta: perguntasType[]) => {
          const respondida = pergunta.filter((elem) => elem.resposta || elem.resposta === 0).length;
          return (<span>{respondida} de {pergunta?.length} questões</span>)
        }
      }
    },
    {
      name: "acerto",
      label: 'Taxa de acertos',
      options: {
        filter: false,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (acerto: { acertos: number, total: number }) => {
          return (acerto ? <span>{acerto?.acertos} acertos de {acerto.total} ({(acerto.acertos * 100) / acerto.total}%)</span> : <>Não consta</>)
        }
      }
    },
  ];

  const translatedTextLabels: MUIDataTableOptions = {
    onRowClick: (rowData) => {
      const value = respostas.find((e: respostasType) => e.inicio === Number(rowData[0]))
      if (value)
        setSelected(value)
      setOpenModal(!openModal)
    },
    textLabels: {
      body: {
        noMatch: `${t('table.noMatch')}`,
        toolTip: `${t('table.toolTip')}`,
        columnHeaderTooltip: column =>
          `${t('table.columnHeaderTooltip')} ${column.label}`
      },
      pagination: {
        next: `${t('table.next')}`,
        previous: `${t('table.previous')}`,
        rowsPerPage: `${t('table.rowsPerPage')}`,
        displayRows: `${t('table.displayRows')}`
      },
      toolbar: {
        search: `${t('table.search')}`,
        filterTable: `${t('table.filterTable')}`
      },
      filter: {
        title: `${t('table.title')}`,
        reset: `${t('table.reset')}`
      },
      viewColumns: {
        title: `${t('table.viewColumnsTitle')}`
      },
    },
  };

  return (
    <>
      {isLoading ?
        <>
          <Skeleton height={60} width={300} style={{ marginTop: '-.5em' }} />
          <Skeleton height={15} width={60} style={{ marginTop: '-.5em' }} />
          <Skeleton height={60} style={{ marginTop: '-.5em' }} />
          <Skeleton height={140} style={{ marginTop: '-1.8em' }} />
        </>
        : <>
          <div style={{ marginBottom: '5px' }}>
            {data?.length > 5 &&
              <>
                <Pagination
                  style={{ display: `${!pages?.length ? 'none' : ''}` }}
                  showFirstButton
                  showLastButton
                  shape="rounded"
                  variant="outlined"
                  count={pages?.length}
                  defaultPage={currentPage}
                  onChange={(eve, pageNumber) => {
                    trackEventMatomo('Pesquisa legado', 'click', 'button', 'muda página')
                    setCurrentPage(pageNumber);

                  }}
                />
                <TextField
                  fullWidth
                  label='Pesquisar'
                  value={search}
                  onClick={() => trackEventMatomo('Pesquisa legado', 'click', 'input', 'edita valor')}
                  onChange={(event) => setSearch(event.target.value.toLowerCase())}
                  style={{ marginBottom: '10px' }}
                />
              </>
            }
          </div>
          {paginated?.map((modelo: pesquisaType) => (
            <Accordion expanded={expanded === modelo.id} style={{ width: '100%', margin: '0px 0px 10px 0px' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                IconButtonProps={{
                  onClick: () => {
                    trackEventMatomo('Pesquisa legado', 'click', 'acordeon', 'abre/fecha')
                    if (expanded === modelo.id) {
                      setExpanded('')
                    } else {
                      setExpanded(modelo.id);
                      mutate(modelo.id)
                    }
                  }
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span style={{
                    color: '#18A689',
                    fontWeight: 600,
                    fontSize: '1.5em'
                  }}>
                    {modelo.nome}
                  </span>
                  <span
                    style={{
                      fontWeight: 300,
                      fontSize: '.8em'
                    }}>
                    criado por: <span style={{ fontWeight: 500 }}>{modelo.userNome}&nbsp;</span>
                    em {new Date(modelo.dataCadastro).toLocaleString()}
                    &nbsp;versão: {modelo.versao}</span>
                </div>

              </AccordionSummary>
              {loadingRespostas ?
                <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                  <Skeleton height={320} width={'96%'} style={{ marginBottom: '-1em', marginTop: '-3.8em' }} />
                </div>
                : <div style={{ display: 'flex', width: '100%', padding: '0 20px 0 20px', marginBottom: '1em' }}>
                  {openModal && <ModalRespostas selected={selected} openModal={openModal} setOpenModal={setOpenModal} />}
                  <StyledDiv style={{ width: '100%' }}>
                    <ThemeProvider theme={getMuiTheme()}>
                      <MUIDataTable
                        title={<p style={{ wordBreak: 'break-word' }}>
                          Respostas pesquisa: <b>{modelo.nome}</b>
                        </p>
                        }
                        data={respostas}
                        columns={columns}
                        options={{ ...tableOptions, ...translatedTextLabels }}
                      />
                    </ThemeProvider>
                  </StyledDiv>
                </div>
              }
            </Accordion>
          ))}
        </>
      }
    </>
  )
}
