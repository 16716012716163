// import { useTranslation } from "react-i18next";
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { useCallback, useEffect, useMemo, useState } from "react";
import { Skeleton } from "@mui/material";
import AttToken from "../../helpers/attToken";
import PowerBi from "../../service/PowerBi";
import { StatisticsContainer } from './StatisticsStyle';

export default function StatisticsComponent() {
  // const { t } = useTranslation('translation');
  const [loading, setLoading] = useState(true);
  const [embedUrl, setEmbedUrl] = useState();
  const [accessToken, setAccessToken] = useState();
  const powerBiService = useMemo(() => new PowerBi(), []);

  const getInfo = useCallback(async () => {
    const token = await AttToken();
    if (token) {
      const info = await powerBiService.getDefaultDashboard(token);
      setEmbedUrl(info.embedUrl[0].embedUrl);
      setAccessToken(info.accessToken);
    }
    setLoading(false);
  }, [powerBiService])

  useEffect(() => {
    if (loading) {
      getInfo()
    }
  }, [loading, getInfo])

  return (
    <div style={{ width: '95%' }}>
      {loading && <Skeleton width={'100%'} height={'80vh'}/>}
      {!loading && embedUrl && accessToken && (
        <StatisticsContainer>
          <PowerBIEmbed
              cssClassName="powerBiStyle"
              key={'0d47a889-7d45-4f97-bda7-1996436ecaff'}
              embedConfig={{
                type: "report", // Supported types: report, dashboard, tile, visual and qna
                id: '0d47a889-7d45-4f97-bda7-1996436ecaff',
                embedUrl,
                accessToken,
                tokenType: models.TokenType.Embed, // PowerBiClient.Models.TokenType.Embed
                settings: {
                  panes: {
                    filters: {
                      expanded: false,
                      visible: true
                    }
                  },
                }
              }}
            />
        </StatisticsContainer>
      )}
    </div>
  )
}

