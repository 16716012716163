import axios, { AxiosError, AxiosResponse } from "axios";
import { toast } from 'react-toastify';
import { AuthBodyType } from "./types";

export type UnidadeToSend = {
  cliente: string,
  conjunto: string,
  dataAlteracao: number,
  local: string,
  nome: string
}

class Unity {
  private api = axios.create({
    baseURL: `${process.env.REACT_APP_API_HOST}/unidade`
  });

  newUnity = async (body: AuthBodyType, unidade: UnidadeToSend) => {
    try {
      const data: AxiosResponse = await this.api.post('/newUnidade', {
        ...body,
        unidade
      })
      return data.data
    } catch (err) {
      const error = err as Error | AxiosError;
      if(axios.isAxiosError(error)){
        console.error(error.message);
        console.log('erro --->', error)
        return error.response?.status;
      }
    }
  }

  editUnity = async (body: AuthBodyType, unidade: string, nome: string) => {
    try {
      const data: AxiosResponse = await this.api.post('/changeUnidade', {
        ...body,
        unidade,
        nome
      })
      return data.data
    } catch (err) {
      const error = err as Error | AxiosError;
      if(axios.isAxiosError(error)){
        console.error(error.message);
        console.log('erro --->', error)
        return error.response?.status;
      }
    }
  }

  deleteUnity = async (body: AuthBodyType, unidade: string, conjunto: string) => {
    try {
      const data: AxiosResponse = await this.api.post('/deleteUnidade', {
        ...body,
        unidade,
        conjunto
      })
      return data.data
    } catch (err) {
      const error = err as Error | AxiosError;
      if(axios.isAxiosError(error)){
        console.error(error.message);
        console.log('erro --->', error)
        return error.response?.status;
      }
    }
  }

  duplicateUnity = async (body: AuthBodyType, unidade: string) => {
    try {
      const data: AxiosResponse = await this.api.post('/duplicateUnidade', {
        ...body,
        unidade,
      })
      return data.data
    } catch (err) {
      const error = err as Error | AxiosError;
      if(axios.isAxiosError(error)){
        console.error(error.message);
        console.log('erro --->', error)
        return error.response?.status;
      }
    }
  }

  addQRCode = async (body: AuthBodyType, unity: string) => {
    try {
      const data: AxiosResponse = await this.api.post('/addQRCode', {
        ...body,
        unity,
      })
      return data.data
    } catch (err) {
      const error = err as Error | AxiosError;
      if(axios.isAxiosError(error)){
        console.error(error.message);
        console.log('erro --->', error)
        return error.response?.status;
      }
    }
  }
}

export default Unity;
