import { useEffect, useState, useContext } from 'react'
import { Team, TeamServiceTypes } from '../../service/Teams'
import { useQuery } from '@tanstack/react-query';
import AttToken from '../../helpers/attToken';
import Clients, { ClientsWithChildren } from '../../service/Clients';
import Loading from '../Loading/Loading';
import { Accordion, AccordionSummary, TextField } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Label } from '../Notifications/style/SingleNotificationStyle';
import { Pagination, Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { UserContext } from '../../context/UserContext';
import { trackEventMatomo } from '../../helpers/matomo';

type props = {
  tipoServico: string;
  tipoLocalAssocia: TeamServiceTypes[] | null;
  setShowingAssociation: Function;
  nomeServico: string;
  equipe: Team
}

const CustomerService = new Clients();

export default function TypePlacesTeamModal({ tipoServico, tipoLocalAssocia, setShowingAssociation, nomeServico, equipe }: props) {
  const thisTipoLocal = tipoLocalAssocia ? tipoLocalAssocia.find(e => e.tipo === tipoServico) || null : null;
  const [expanded, setExpanded] = useState<string | boolean>(false);
  const [searchCliente, setSearchCliente] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [paginated, setPaginated] = useState<ClientsWithChildren[]>([]);

  const handleChange = (isExpanded: boolean, panel: string) => {
    setExpanded(isExpanded ? panel : false);
  };

  const { t } = useTranslation('translation');


  const { data: ClientesChildren, isLoading: isLoadingClientesChildren, isFetching } = useQuery({
    queryKey: ['ClientesChildren'],
    queryFn: async () => {
      const data = await AttToken();
      if (data) {
        const teams = await CustomerService.getAllWithSets(data, tipoServico);
        return teams
      } else throw new Error('aaaa')
    },
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  });

  const lastPageIndex = currentPage * itemsPerPage;
  const firstPageIndex = lastPageIndex - itemsPerPage;

  let pages: any[] = [];

  useEffect(() => {
    if (ClientesChildren) {
      const currentPageData = ClientesChildren
        ?.sort((a: any, b: any) => { return a.nome > b.nome ? 1 : -1 })
        ?.filter((e: any) => e.nome
          ?.normalize("NFD")
          ?.replace(/[\u0300-\u036f]/g, "")
          ?.toLowerCase()
          ?.includes(searchCliente
            ?.normalize("NFD")
            ?.replace(/[\u0300-\u036f]/g, "")
            ?.toLowerCase()))
      searchCliente ? setPaginated(currentPageData?.slice(0, 5)) : setPaginated(currentPageData?.slice(firstPageIndex, lastPageIndex))
    }
  }, [firstPageIndex, lastPageIndex, ClientesChildren, searchCliente])

  for (let i = 1; i <= Math.ceil(ClientesChildren?.filter((elem: any) => elem.nome?.includes(searchCliente))?.length / itemsPerPage); i++) {
    pages.push(i)
  }

  if (isFetching || isLoadingClientesChildren) return (
    <>
      <Skeleton variant='rounded' sx={{ width: '75%', marginBottom: '5px' }} height={30} />
      <Skeleton variant='rounded' sx={{ width: '30%', marginBottom: '5px' }} height={30} />
      <Skeleton variant='rounded' sx={{ width: '60%', marginBottom: '5px' }} height={30} />
      <Skeleton variant='rounded' sx={{ width: '25%', marginBottom: '5px' }} height={30} />
      <Skeleton variant='rounded' sx={{ width: '100%', marginBottom: '5px' }} height={30} />
      <Skeleton variant='rounded' sx={{ width: '50%', marginBottom: '5px' }} height={30} />
      <Skeleton variant='rounded' sx={{ width: '100%', marginBottom: '5px' }} height={50} />
    </>
  )

  return (
    <div>
      <h1>{`${t('teamManage.registered.edit')} - ${nomeServico}`}</h1>
      <button
        className="button is-success is-small"
        onClick={() => {
          trackEventMatomo('modal associação de equipes', 'click', 'button', 'fecha modal')
          setShowingAssociation(false);
        }}
      >
        <ArrowBackIcon />&nbsp;{t('teamManage.registered.voltar')}
      </button>
      <h1>{t('teamManage.registered.AssociacaoConjuntos')}</h1>
      <h2>{t('teamManage.registered.Clientes')}</h2>
      <>
        <TextField
          onClick={() => trackEventMatomo('modal associação de equipes', 'click', 'input', 'edita valor')}
          style={{ marginBottom: '10px' }}
          fullWidth
          value={searchCliente}
          onChange={(eve) => setSearchCliente
            (eve.target.value
              ?.normalize("NFD")
              ?.replace(/[\u0300-\u036f]/g, "")
              ?.toLowerCase()
            )
          }
          label='Pesquisar cliente'
        />
        <Pagination
          sx={{ marginBottom: '10px', display: `${pages?.length > 1 ? '' : 'none'}` }}
          key={Math.random()}
          showFirstButton
          showLastButton
          shape="rounded"
          variant="outlined"
          count={pages?.length}
          defaultPage={currentPage}
          onChange={(eve, pageNumber) => {
            trackEventMatomo('modal associação de equipes', 'click', 'button', 'muda página')
            setCurrentPage(pageNumber);

          }}
        />
      </>
      {isLoadingClientesChildren || isFetching || !ClientesChildren ? <Loading /> : (
        paginated
          ?.map((e: ClientsWithChildren, i: number) => (
            e &&
            <AccordeonPlaces
              expanded={expanded}
              handleChange={handleChange}
              client={e}
              key={e.id}
              thisTipoLocal={thisTipoLocal}
              equipe={equipe}
              tipoServico={tipoServico}
            />
          ))
      )}
      {(!isLoadingClientesChildren && !isFetching) && ClientesChildren.length === 0 &&
        <div style={{ backgroundColor: 'orange', padding: '0px 5px 0px 5px', borderRadius: '5px', height: 'fit-content' }}>
          <span style={{ fontSize: '13px' }}>
            * Você não possui nenhuma estrutura geográfica associada a
            esse tipo, por favor associe em "Gestão de Serviços" na aba de "Gestão de Local"
            ou em "Gestão de Cliente" na aba "Meus Clientes".
          </span>
        </div>
      }
    </div>
  )
}

type AccordeonPlacesParams = {
  client: ClientsWithChildren;
  thisTipoLocal: TeamServiceTypes | null;
  equipe: Team,
  tipoServico: string
  expanded: string | boolean,
  handleChange: Function
}

const AccordeonPlaces = ({ client, thisTipoLocal, equipe, tipoServico, expanded, handleChange }: AccordeonPlacesParams) => {
  const [subExpanded, setSubExpanded] = useState(-1);
  const [searchLocais, setSearchLocais] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [paginated, setPaginated] = useState<any[]>([]);
  const [searchConjuntos, setSearchConjuntos] = useState<string>('');

  const [cnjsArr, setCnjsArr] = useState(thisTipoLocal ? thisTipoLocal.cnjs || [] : []);

  const { userData } = useContext(UserContext);

  const lastPageIndex = currentPage * itemsPerPage;
  const firstPageIndex = lastPageIndex - itemsPerPage;

  useEffect(() => {
    if (client.locaisList) {
      const currentPageData = client.locaisList
        ?.sort((a: any, b: any) => { return a.nome > b.nome ? 1 : -1 })
        ?.filter((e: any) => e.nome
          ?.normalize("NFD")
          ?.replace(/[\u0300-\u036f]/g, "")
          ?.toLowerCase()
          ?.includes(searchLocais
            ?.normalize("NFD")
            ?.replace(/[\u0300-\u036f]/g, "")
            ?.toLowerCase()))
      searchLocais ? setPaginated(currentPageData?.slice(0, 5)) : setPaginated(currentPageData?.slice(firstPageIndex, lastPageIndex))
    }
  }, [firstPageIndex, lastPageIndex, client, searchLocais])

  let pages = [];

  for (let i = 1; i <= Math.ceil(client?.locaisList?.filter((elem: any) => elem.nome.includes(searchLocais))?.length / itemsPerPage); i++) {
    pages.push(i)
  }


  const allSetsFromClient: string[] = [];
  client.locaisList.forEach((local: any) => {
    local.conjuntosList.forEach((cnj: any) => allSetsFromClient.push(cnj.id))
  })

  return (
    <div key={client.id}>
      <Accordion style={{ marginBottom: '10px' }} expanded={expanded === client.id}  >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon
            onClick={(isExpanded) => {
              trackEventMatomo('modal associação de equipes', 'click', 'acordeon', 'abre/fecha')
              if (expanded === client.id) {
                handleChange(!isExpanded, '')
              } else
                handleChange(isExpanded, client.id)
            }}
          />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Label className='checkbox'>
            <input
              onClick={() => trackEventMatomo('modal associação de equipes', 'click', 'input', 'edita valor')}
              type='checkbox'
              style={{ marginLeft: '10px', marginRight: '10px' }}
              checked={allSetsFromClient.every(set => cnjsArr.includes(set))}
              disabled={!userData.formMigrado && allSetsFromClient.every(set => cnjsArr.includes(set))}
              onChange={(event) => {
                const cnjs: string[] = [];
                if (event.target.checked) {
                  client.locaisList.forEach((local) => {
                    local.conjuntosList.forEach((cnj) => {
                      setCnjsArr((prev) => prev ? [...prev, cnj.id] : [cnj.id])
                      cnjs.push(cnj.id)
                    })
                  })
                  if (equipe.tiposLocaisAssocia) {
                    equipe.tiposLocaisAssocia.forEach(el => {
                      if (el.tipo === tipoServico) {
                        if (el.cnjs) el.cnjs = [...el.cnjs, ...cnjs];
                        else el.cnjs = cnjs;
                      }
                    })
                  }
                }
                else {
                  client.locaisList.forEach((local) => {
                    local.conjuntosList.forEach((cnj) => {
                      setCnjsArr((prev) => prev.filter(el => el !== cnj.id))
                      cnjs.push(cnj.id)
                    })
                  })
                  if (equipe.tiposLocaisAssocia) {
                    equipe.tiposLocaisAssocia.forEach(el => {
                      if (el.tipo === tipoServico) {
                        if (el.cnjs) el.cnjs = el.cnjs.filter(set => !cnjs.includes(set));
                        else el.cnjs = [];
                      }
                    })
                  }
                }
              }}
            />
            <span style={{ fontWeight: 'bold', color: 'black' }}>{client.nome}</span>
          </Label>
        </AccordionSummary>
        <h2 style={{ marginLeft: '10px', fontSize: '0.8xem' }}>Locais:</h2>
        {client.locaisList.length > 5 &&
          <>
            <TextField
              onClick={() => trackEventMatomo('modal associação de equipes', 'click', 'input', 'edita valor')}
              fullWidth
              value={searchLocais}
              onChange={(eve) => setSearchLocais(eve.target.value
                ?.normalize("NFD")
                ?.replace(/[\u0300-\u036f]/g, "")
                ?.toLowerCase())}
              label='Pesquisar locais' />
            <Pagination
              key={Math.random()}
              style={{ display: `${!pages?.length ? 'none' : ''}` }}
              showFirstButton
              showLastButton
              shape="rounded"
              variant="outlined"
              count={pages?.length}
              defaultPage={currentPage}
              onChange={(eve, pageNumber) => {
                trackEventMatomo('modal associação de equipes', 'click', 'button', 'muda página')
                setCurrentPage(pageNumber);
              }}
            />
          </>
        }
        {paginated.filter((ele) => ele.nome?.toLowerCase()?.includes(searchLocais?.toLowerCase()))?.map((e, i) => {
          const allSetsFromPlace: string[] = e.conjuntosList.map((cnj: any) => cnj.id)
          return (
            <div key={i}>
              <Accordion style={{ backgroundColor: '#e5e5e5', borderRadius: '0', margin: '10px' }} expanded={subExpanded === i} >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  key={i}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  IconButtonProps={{
                    onClick: () => subExpanded === i ? setSubExpanded(-1) : setSubExpanded(i)
                  }}
                >
                  <Label className='checkbox'>
                    <input
                      onClick={() => trackEventMatomo('modal associação de equipes', 'click', 'input', 'edita valor')}
                      type='checkbox'
                      style={{ marginLeft: '10px', marginRight: '10px' }}
                      checked={allSetsFromPlace.every(set => cnjsArr?.includes(set))}
                      disabled={!userData.formMigrado && allSetsFromPlace.every(set => cnjsArr?.includes(set))}
                      onChange={(event) => {
                        const cnjs: string[] = [];
                        if (event.target.checked) {
                          e.conjuntosList.forEach((cnj: any) => {
                            setCnjsArr((prev) => prev ? [...prev, cnj.id] : [cnj.id])
                            cnjs.push(cnj.id)
                          })
                          if (equipe.tiposLocaisAssocia) {
                            equipe.tiposLocaisAssocia.forEach(el => {
                              if (el.tipo === tipoServico) {
                                if (el.cnjs) el.cnjs = [...el.cnjs, ...cnjs];
                                else el.cnjs = cnjs;
                              }
                            })
                          }
                        } else {
                          e.conjuntosList.forEach((cnj: any) => {
                            setCnjsArr((prev) => prev.filter(el => el !== cnj.id))
                            cnjs.push(cnj.id)
                          })
                          if (equipe.tiposLocaisAssocia) {
                            equipe.tiposLocaisAssocia.forEach(el => {
                              if (el.tipo === tipoServico) {
                                if (el.cnjs) el.cnjs = el.cnjs.filter(set => !cnjs?.includes(set));
                                else el.cnjs = [];
                              }
                            })
                          }
                        }
                      }}
                    />
                    <span style={{ fontWeight: 'bold', color: 'black' }}>{e.nome}</span>
                  </Label>
                </AccordionSummary>
                <h2 style={{ marginLeft: '10px', fontSize: '0.6em' }}>Conjuntos:</h2>
                {e.conjuntosList.length > 5 && <div style={{ padding: '0px 5px 0px 5px' }}>
                  <input
                    onClick={() => trackEventMatomo('modal associação de equipes', 'click', 'input', 'edita valor')}
                    className='input is-fullwidth'
                    value={searchConjuntos.toLowerCase()}
                    onChange={(eve) => setSearchConjuntos(eve.target.value.toLowerCase())}
                    placeholder='Pesquisar conjuntos' />
                </div>}
                {e.conjuntosList && e.conjuntosList?.filter((ele: any) => ele.nome?.toLowerCase()?.includes(searchConjuntos?.toLowerCase()))?.map((el: any, ind: number) => {
                  return (
                    <Label className='checkbox' key={ind}>
                      <input
                        onClick={() => trackEventMatomo('modal associação de equipes', 'click', 'input', 'edita valor')}
                        type='checkbox'
                        style={{ marginLeft: '25px', marginRight: '10px' }}
                        checked={cnjsArr.includes(el.id)}
                        disabled={!userData.formMigrado && cnjsArr.includes(el.id)}
                        onChange={(event) => {
                          const cnjs: string[] = [];
                          if (event.target.checked) {
                            setCnjsArr((prev) => prev ? [...prev, el.id] : [el.id])
                            cnjs.push(el.id)
                            if (equipe.tiposLocaisAssocia) {
                              equipe.tiposLocaisAssocia.forEach(el => {
                                if (el.tipo === tipoServico) {
                                  if (el.cnjs) el.cnjs = [...el.cnjs, ...cnjs];
                                  else el.cnjs = cnjs;
                                }
                              })
                            }
                          } else {
                            setCnjsArr((prev) => prev.filter(ele => ele !== el.id))
                            cnjs.push(el.id)
                            if (equipe.tiposLocaisAssocia) {
                              equipe.tiposLocaisAssocia.forEach(el => {
                                if (el.tipo === tipoServico) {
                                  if (el.cnjs) el.cnjs = el.cnjs.filter(set => !cnjs.includes(set));
                                  else el.cnjs = [];
                                }
                              })
                            }
                          }
                        }}
                      />
                      {el.nome}
                    </Label>
                  )
                })}
              </Accordion>
            </div>
          )
        })}
      </Accordion>
    </div>
  )
}