import { useContext, useEffect, useMemo, useState } from 'react'
import { BsArrowClockwise } from 'react-icons/bs'
import { FilterForm } from './Style';
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pt from "date-fns/locale/pt";
import ReportsTableComponent from './ReportsTableComponent';
import { useTranslation } from 'react-i18next';
import Reports, { filterDataType, filtersV2 } from '../../service/Reports';
import { Accordion, AccordionSummary, ThemeProvider } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useMutation } from '@tanstack/react-query';
import AttToken from '../../helpers/attToken';
import { toast } from 'react-toastify';
import { Skeleton } from '@mui/material';
import './styles.css';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { UserContext } from '../../context/UserContext';
import { createTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { ptBR } from 'date-fns/locale';
import { trackEventMatomo } from '../../helpers/matomo';

registerLocale('pt', pt);

const currentDate = new Date();
const oneWeekAgo = new Date(currentDate);

export type selectedFilter = {
  dataInicio: Date | number,
  dataFim: Date | number,
  texto?: string | null,
  limite: number,
}

export default function ReportsTable({ data }: { data: filterDataType }) {
  const { hierarchyLabel, userData } = useContext(UserContext)
  const { t } = useTranslation('translation');
  const reportsService = useMemo(() => new Reports(), []);
  const [canShow, setCanShow] = useState(false);
  const [resetRows, setResetRows] = useState(false)

  const [gerenciasNome, setGerenciasNome] = useState<{ label: string, value: string }[] | null>([]);
  const [usuarioNome, setUsuarioNome] = useState<{ label: string, value: string }[] | null>([]);
  const [clienteNome, setClienteNome] = useState<{ label: string, value: string }[] | null>([]);
  const [tagNome, setTagNome] = useState<{ label: string, value: string }[] | null>([]);
  const [formNome, setFormNome] = useState<{ label: string, value: string }[] | null>([]);
  const [localNome, setLocalNome] = useState<{ label: string, value: string }[] | null>([]);
  const [equipeNome, setEquipeNome] = useState<{ label: string, value: string }[] | null>([]);
  const [conjuntoNome, setConjuntoNome] = useState<{ label: string, value: string }[] | null>([]);
  const [unidadeNome, setUnidadeNome] = useState<{ label: string, value: string }[] | null>([]);
  const [servicoNome, setServicoNome] = useState<{ label: string, value: string }[] | null>([]);
  const [identificadorNome, setIdentificadorNome] = useState<{ label: string, value: string }[] | null>([]);
  const [pausa, setPausa] = useState<boolean | null>(null);
  const [delegado, setDelegado] = useState<boolean | null>(null);
  const [filteringByScore, setFilteringByScore] = useState(false);
  const [scoreDe, setScoreDe] = useState(0);
  const [scoreAte, setScoreAte] = useState(100);
  const [status, setStatus] = useState<boolean | null>(null);
  const [lastIndexesByPage, setLastIndexesBypage] = useState<{ [page: number]: number } | undefined>();
  const [identificadorOpcoes, setIdentificadorOpcoes] = useState<string[]>([]);

  const [filters, setFilters] = useState<selectedFilter>({
    dataInicio: oneWeekAgo.setDate(currentDate.getDate() - 7),
    dataFim: new Date().setHours(23, 59, 59, 59),
    texto: null,
    limite: 0,
  });
  const [pageNumber, setPageNumber] = useState(0);
  const [itensPerPage, setItensPerPage] = useState(10);
  const [totalItens, setTotalItens] = useState(10);
  const [firstRender, setFirstRender] = useState(true);

  const sendDataForSupervisor = () => {
    return conjuntoNome && conjuntoNome.length ? conjuntoNome?.map((elem) => elem.value) : data?.conjuntos?.map(el => el.id)
  }

  const sendDataForManager = () => {
    return gerenciasNome && gerenciasNome.length ? gerenciasNome?.map((elem) => elem.value) : data?.gerencias?.map(el => el.id)
  }

  const { mutate, data: filteredData, isLoading } = useMutation({
    mutationKey: ['reportsData'],
    mutationFn: async () => {
      const token = await AttToken();
      if (firstRender) {
        setFirstRender(false);
      }
      if (token) {
        const newFilters: filtersV2 = {
          inicio: {
            de: Number(filters.dataInicio),
            ate: Number(filters.dataFim)
          },
          score: filteringByScore ? {
            de: scoreDe,
            ate: scoreAte
          } : undefined,
          cliente: clienteNome?.map((elem) => elem.value),
          local: localNome?.map((elem) => elem.value),
          conjunto: userData.role === 'Supervisor' ? sendDataForSupervisor() : conjuntoNome?.map((elem) => elem.value),
          unidade: unidadeNome?.map((elem) => elem.value),
          usuario: usuarioNome?.map((elem) => elem.value),
          tipoServico: servicoNome?.map((elem) => elem.value),
          gerencia: userData.role === 'Gerente' ? sendDataForManager() : gerenciasNome?.map((elem) => elem.value),
          pausa: pausa === null ? undefined : pausa,
          delegado: delegado === null ? undefined : delegado,
          status: status === null ? undefined : status,
          formulario: formNome?.map((elem) => elem.value),
          identificador: identificadorNome?.map((elem) => elem.label)
        }
        setResetRows(true)
        const response = await reportsService.getExecutionsV2(token, newFilters, lastIndexesByPage && lastIndexesByPage[pageNumber] ? lastIndexesByPage[pageNumber] : null, itensPerPage)
        if (response.execucoes.length === 0) {
          toast.warn('Não encontramos relatórios para o período!')
        }
        if (response.identificadorOpcoes && response.identificadorOpcoes.length) {
          setIdentificadorOpcoes(response.identificadorOpcoes);
        } 
        if (response.lastIndex && response.total && response.total > (itensPerPage * (pageNumber || 1))) {
          setLastIndexesBypage(prev => {
            if (prev) {
              console.log({
                ...prev,
                [pageNumber + 1]: response.lastIndex
              })
              return {
                ...prev,
                [pageNumber + 1]: response.lastIndex
              }
            } else {
              console.log({ [pageNumber + 1]: response.lastIndex });
              return { [pageNumber + 1]: response.lastIndex }
            }
          })
        }
        const mocked = [];
        if (pageNumber) {
          for (let i = 0; i < pageNumber * itensPerPage; i += 1) {
            const mockedObj = {
              scorePorcentagem: 'Mocked',
              score: 0,
              range: 'Mocked',
              user: 'Mocked',
              username: 'Mocked',
              inicio: 1704078000000,
              fim: 1716301116388,
              formulario: 'Mocked',
              cliente: 'Mocked',
              local: 'Mocked',
              conjunto: 'Mocked',
              unidade: 'Mocked',
              identificador: 'Mocked',
              subItemIdentificador: 'Mocked',
              status: 'Mocked',
              foiDelegado: 'Mocked',
              nomeAprovador: 'Mocked',
              naoConforme: 'Mocked',
              conforme: 'Mocked',
              distancia: 'Mocked',
              latitude: 'Mocked',
              longitude: 'Mocked',
              duracao: 'Mocked',
              gerencia: 'Mocked',
              nomeEmpresa: 'Mocked',
              idCliente: 'Mocked',
              equipes: 'Mocked',
              agendamento: 'Mocked',
              aprovacao: 'Mocked',
              tempoPausa: 'Mocked',
              versaoForm: 'Mocked',
              idForm: 'Mocked',
              itens: 'Mocked'
            }
            mocked.push(mockedObj)
          }
        }
        if (mocked.length) {
          const newArray = [...mocked, ...response.execucoes]
          setTotalItens(response.total)
          setResetRows(false);
          return newArray;
        }
        setTotalItens(response.total)
        setResetRows(false)
        return response.execucoes
      }
    },
    onError: (erro) => {
      console.log(erro)
    },
  })

  useEffect(() => {
    if (!firstRender) {
      mutate()
    }
  }, [pageNumber, itensPerPage, mutate, firstRender])

  const getMuiTheme = () => createTheme({
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            textAlign: 'center',
            width: 110,
            maxWidth: 110,
            margin: 50
          }
        }
      },
    },
  })

  if (isLoading) return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', width: '100%', alignItems: 'center' }}>
        <Skeleton width={'100%'} height={'400px'} style={{ marginTop: '-5.5em' }} />
        <Skeleton width={'100%'} height={'700px'} style={{ marginTop: '-12em' }} />
      </div>
    </>
  )

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%', maxWidth: '100%' }}>
        <FilterForm className='box' onSubmit={(e) => e.preventDefault()}
          style={{
            height: 'fit-content',
            padding: '10px',
            display: '-webkit-flex',
            flexWrap: 'wrap',
            marginBottom: '50px',
            width: '100%'
          }}>
          <span>{t('Reports.searchFilterSettings')}</span>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }} className='columns'>
            <div className='column' style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', flexDirection: 'column' }}>
                <div style={{ display: 'flex', marginTop: '10px' }}>
                  <ThemeProvider theme={getMuiTheme()}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                      <DesktopDatePicker
                        maxDate={new Date(new Date().setHours(23, 59, 59))}
                        format='dd/MM/yyyy'
                        label={t('Reports.from')}
                        slotProps={{
                          textField: { size: 'small' },
                          field: {
                            readOnly: true,
                          },
                        }}
                        value={new Date(filters.dataInicio)}
                        onChange={(newValue) => {
                          trackEventMatomo('Relatórios', 'click', 'datepicker', 'seleciona data')
                          setFilters({ ...filters, dataInicio: new Date(newValue?.setHours(0, 0, 0, 0) as number) })
                        }}
                      />
                    </LocalizationProvider>&nbsp;
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                      <DesktopDatePicker
                        format='dd/MM/yyyy'
                        label={t('Reports.until')}
                        value={new Date(filters.dataFim)}
                        slotProps={{
                          textField: { size: 'small' },
                          field: {
                            readOnly: true,
                          },
                        }}
                        maxDate={new Date(new Date().setHours(23, 59, 59))}
                        onChange={(newValue) => {
                          trackEventMatomo('Relatórios', 'click', 'datepicker', 'seleciona data')
                          setFilters({ ...filters, dataFim: new Date(newValue?.setHours(23, 59, 59, 59) as number) })
                        }}
                      />
                    </LocalizationProvider>
                  </ThemeProvider>
                </div>
              </div>

            </div>
            <div className='column' style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <button
                className='button is-info'
                onClick={() => {
                  trackEventMatomo('Relatórios', 'click', 'button', 'pesquisar')
                  if (firstRender || (pageNumber === 0 && itensPerPage === 10)) {
                    setLastIndexesBypage(undefined);
                    mutate()
                    setCanShow(true);
                  } else {
                    setLastIndexesBypage(undefined);
                    setPageNumber(0);
                    setItensPerPage(10);
                    setCanShow(true);
                  }
                }}
                disabled={
                  (
                    userData.role === "Gerente" && gerenciasNome?.length && data?.gerencias && data?.gerencias.length > 30
                  ) || (
                    userData.role === "Supervisor" && !conjuntoNome?.length && data?.conjuntos && data?.conjuntos.length > 30
                  )
                }
              >
                <BsArrowClockwise />
                &nbsp;&nbsp;&nbsp;&nbsp;{t('filter')}
              </button>
            </div>
          </div>
          {userData.role === "Gerente" && gerenciasNome?.length && data?.gerencias && data?.gerencias.length > 30 && (
            <span
              className='tag is-warning'
            >
              {`Você está e mais de 30 ${hierarchyLabel.gerencia}(s) por favor selecione até 30`}
            </span>
          )}
          {userData.role === "Supervisor" && !conjuntoNome?.length && data?.conjuntos && data?.conjuntos.length > 30 && (
            <span
              className='tag is-warning'
            >
              {`Você supervisiona mais de 30 ${hierarchyLabel.conjunto} por favor selecione até 30`}
            </span>
          )}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <span style={{ width: '100%', textAlign: 'center', fontSize: '20px', fontWeight: '600', color: 'gray' }}>
                Filtros
              </span>
            </ AccordionSummary>
            <Autocomplete
              onClick={() => trackEventMatomo('Relatórios', 'click', 'select', 'seleciona valor')}
              className='column'
              noOptionsText={`Sem ${hierarchyLabel.gerencia}  correspondentes`}
              size="small"
              multiple
              style={{ fontSize: '10px !important' }}
              value={gerenciasNome as { value: string, label: string }[]}
              id="disable-close-on-select"
              filterSelectedOptions
              options={data?.gerencias
                ?.filter((elem) => !gerenciasNome?.some(ger => ger.value === elem.id))
                ?.sort((a, b) => { return a.nome?.toLowerCase() > b.nome?.toLowerCase() ? 1 : - 1 })
                ?.map((option) => ({
                  label: option.nome,
                  value: option.id || option.key,
                }))}
              onChange={(event: any, newValue: { label: string, value: string }[] | null) => {
                setGerenciasNome(newValue)
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.value}>
                  <span>{option.label}</span>
                </li>)}
              renderInput={(params) => <TextField {...params} label={`${t('Reports.managementSelect')} - (${hierarchyLabel.gerencia || 'Gerencia'})`} />}
            />
            <div className='columns' style={{ padding: '0px 10px 0px 10px' }}>
              <Autocomplete
                onClick={() => trackEventMatomo('Relatórios', 'click', 'select', 'seleciona valor')}
                className='column'
                noOptionsText={'Sem usuários correspondentes'}
                size="small"
                multiple
                style={{ fontSize: '10px !important' }}
                value={usuarioNome as { value: string, label: string }[]}
                id="disable-close-on-select"
                filterSelectedOptions
                options={data?.users
                  ?.filter((el) => {
                    if (!gerenciasNome?.length) return el
                    if (el.gerencias && gerenciasNome?.length) return el.gerencias.some(gerId => gerenciasNome.some(ger => ger.value === gerId));
                    else return false;
                  })
                  ?.filter((elem) => !usuarioNome?.some(user => user.value === elem.id))
                  ?.sort((a, b) => { return a.nome?.toLowerCase() > b.nome?.toLowerCase() ? 1 : - 1 })
                  ?.map((option) => ({
                    label: option.nome,
                    value: option.id,
                  }))}
                onChange={(event: any, newValue: { label: string, value: string }[] | null) => {
                  setUsuarioNome(newValue)
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.value}>
                    <span>{option.label}</span>
                  </li>)}
                renderInput={(params) => <TextField {...params} label={t('Reports.multipleUserSelect')} />}
              />
              <Autocomplete
                onClick={() => trackEventMatomo('Relatórios', 'click', 'select', 'seleciona valor')}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.value}>
                    <span>{option.label}</span>
                  </li>)}
                className='column'
                noOptionsText={'Sem clientes correspondentes'}
                size="small"
                multiple
                value={clienteNome as { value: string, label: string }[]}
                id="disable-close-on-select"
                filterSelectedOptions
                options={data?.clientes
                  ?.filter((el) => {
                    if (!gerenciasNome?.length) return el
                    if (el.gerencias && gerenciasNome?.length) return el.gerencias.some(gerId => gerenciasNome.some(ger => ger.value === gerId));
                    else return false;
                  })
                  ?.filter((elem) => !clienteNome?.some(cliente => cliente.value === elem.id))
                  ?.sort((a, b) => { return a.nome?.toLowerCase() > b.nome?.toLowerCase() ? 1 : - 1 })
                  ?.map((option) => ({
                    label: option.nome,
                    value: option.id,
                  }))}
                onChange={(event: any, newValue: { label: string, value: string }[] | null) => {
                  setClienteNome(newValue)
                }}
                renderInput={(params) => <TextField {...params} label={`${t('Reports.multipleCustomerSelect')} - (${hierarchyLabel.cliente || 'Cliente'})`} />}
              />
            </div>
            <div className='columns' style={{ padding: '0px 10px 0px 10px' }}>
              <Autocomplete
                onClick={() => trackEventMatomo('Relatórios', 'click', 'select', 'seleciona valor')}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.value}>
                    <span>{option.label}</span>
                  </li>)}
                className='column'
                noOptionsText={'Sem tags correspondentes'}
                size="small"
                multiple
                value={tagNome as { value: string, label: string }[]}
                id="disable-close-on-select"
                filterSelectedOptions
                options={data?.tags
                  ?.filter((el) => {
                    if (!gerenciasNome?.length) return el
                    if (el.gerencias && gerenciasNome?.length) return el.gerencias.some(gerId => gerenciasNome.some(ger => ger.value === gerId));
                    else return false;
                  })
                  ?.filter((elem) => !tagNome?.some(tag => tag.value === elem.id))
                  ?.sort((a, b) => { return a.nome?.toLowerCase() > b.nome?.toLowerCase() ? 1 : - 1 })
                  ?.map((option) => ({
                    label: option.nome,
                    value: option.id,
                  }))}
                onChange={(event: any, newValue: { label: string, value: string }[] | null) => {
                  setTagNome(newValue)
                }}
                renderInput={(params) => <TextField {...params} label={t('Reports.multipleTagSelect')} />}
              />
              <Autocomplete
                onClick={() => trackEventMatomo('Relatórios', 'click', 'select', 'seleciona valor')}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.value}>
                    <span>{option.label}</span>
                  </li>)}
                className='column'
                noOptionsText={'Sem formulários correspondentes'}
                size="small"
                multiple
                value={formNome as { value: string, label: string }[]}
                id="disable-close-on-select"
                filterSelectedOptions
                options={data?.forms
                  ?.filter((el) => {
                    if (!gerenciasNome?.length) return el
                    if (el.gerencias && gerenciasNome?.length) return el.gerencias.some(gerId => gerenciasNome.some(ger => ger.value === gerId));
                    else return false;
                  })
                  ?.filter((elem) => !formNome?.some(tag => tag.value === elem.id))
                  ?.sort((a, b) => { return a.nome?.toLowerCase() > b.nome?.toLowerCase() ? 1 : - 1 })
                  ?.map((option) => ({
                    label: option.nome,
                    value: option.id,
                  }))}
                onChange={(event: any, newValue: { label: string, value: string }[] | null) => {
                  setFormNome(newValue)
                }}
                renderInput={(params) => <TextField {...params} label={'Selecione um ou mais formulários específicos:'} />}
              />
            </div>
            <div className='columns' style={{ padding: '0px 10px 0px 10px' }}>
              <Autocomplete
                onClick={() => trackEventMatomo('Relatórios', 'click', 'select', 'seleciona valor')}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.value}>
                    <span>{option.label}</span>
                  </li>)}
                className='column'
                noOptionsText={'Sem locais correspondentes'}
                size="small"
                multiple
                value={localNome as { value: string, label: string }[]}
                id="disable-close-on-select"
                filterSelectedOptions
                options={data?.locais
                  ?.filter((el) => {
                    if (!gerenciasNome?.length) return el
                    if (el.gerencias && gerenciasNome?.length) return el.gerencias.some(gerId => gerenciasNome.some(ger => ger.value === gerId));
                    else return false;
                  })
                  ?.filter((elem) => !localNome?.some(tag => tag.value === elem.id))
                  ?.sort((a, b) => { return a.nome?.toLowerCase() > b.nome?.toLowerCase() ? 1 : - 1 })
                  ?.map((option) => ({
                    label: option.nome,
                    value: option.id,
                  }))}
                onChange={(event: any, newValue: { label: string, value: string }[] | null) => {
                  setLocalNome(newValue)
                }}
                renderInput={(params) => <TextField {...params} label={t`${('Reports.multiplePlaceSelect')} - (${hierarchyLabel.local || 'Local'})`} />}
              />
              <Autocomplete
                onClick={() => trackEventMatomo('Relatórios', 'click', 'select', 'seleciona valor')}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.value}>
                    <span>{option.label}</span>
                  </li>)}
                className='column'
                noOptionsText={'Sem equipes correspondentes'}
                size="small"
                multiple
                value={equipeNome as { value: string, label: string }[]}
                id="disable-close-on-select"
                filterSelectedOptions
                options={data?.equipes
                  ?.filter((el) => {
                    if (!gerenciasNome?.length) return el
                    if (el.gerencias && gerenciasNome?.length) return el.gerencias.some(gerId => gerenciasNome.some(ger => ger.value === gerId));
                    else return false;
                  })
                  ?.filter((elem) => !equipeNome?.some(tag => tag.value === elem.id))
                  ?.sort((a, b) => { return a.nome?.toLowerCase() > b.nome?.toLowerCase() ? 1 : - 1 })
                  ?.map((option) => ({
                    label: option.nome,
                    value: option.id,
                  }))}
                onChange={(event: any, newValue: { label: string, value: string }[] | null) => {
                  setEquipeNome(newValue)
                }}
                renderInput={(params) => <TextField {...params} label={t('Reports.multipleTeamSelect')} />}
              />
            </div>
            <div className='columns' style={{ padding: '0px 10px 0px 10px' }}>
              <Autocomplete
                onClick={() => trackEventMatomo('Relatórios', 'click', 'select', 'seleciona valor')}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.value}>
                    <span>{option.label}</span>
                  </li>)}
                className='column'
                noOptionsText={'Sem conjuntos correspondentes'}
                size="small"
                multiple
                value={conjuntoNome as { value: string, label: string }[]}
                id="disable-close-on-select"
                filterSelectedOptions
                options={data?.conjuntos
                  ?.filter((el) => {
                    if (!gerenciasNome?.length) return el
                    if (el.gerencias && gerenciasNome?.length) return el.gerencias.some(gerId => gerenciasNome.some(ger => ger.value === gerId));
                    else return false;
                  })
                  ?.filter((elem) => !conjuntoNome?.some(tag => tag.value === elem.id))
                  ?.sort((a, b) => { return a.nome?.toLowerCase() > b.nome?.toLowerCase() ? 1 : - 1 })
                  ?.map((option) => ({
                    label: option.nome,
                    value: option.id,
                  }))}
                onChange={(event: any, newValue: { label: string, value: string }[] | null) => {
                  setConjuntoNome(newValue)
                }}
                renderInput={(params) => <TextField {...params} label={`${t('Reports.multipleSetSelect')} - (${hierarchyLabel.conjunto || 'Conjunto'})`} />}
              />
              <Autocomplete
                onClick={() => trackEventMatomo('Relatórios', 'click', 'select', 'seleciona valor')}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.value}>
                    <span>{option.label}</span>
                  </li>)}
                className='column'
                noOptionsText={'Sem unidades correspondentes'}
                size="small"
                multiple
                value={unidadeNome as { value: string, label: string }[]}
                id="disable-close-on-select"
                filterSelectedOptions
                options={data?.unidades
                  ?.filter((el) => {
                    if (!gerenciasNome?.length) return el
                    if (el.gerencias && gerenciasNome?.length) return el.gerencias.some(gerId => gerenciasNome.some(ger => ger.value === gerId));
                    else return false;
                  })
                  ?.filter((elem) => !unidadeNome?.some(tag => tag.value === elem.id))
                  ?.sort((a, b) => { return a.nome?.toLowerCase() > b.nome?.toLowerCase() ? 1 : - 1 })
                  ?.map((option) => ({
                    label: option.nome,
                    value: option.id,
                  }))}
                onChange={(event: any, newValue: { label: string, value: string }[] | null) => {
                  setUnidadeNome(newValue)
                }}
                renderInput={(params) => <TextField {...params} label={`${t('Reports.multipleUnitySelect')} - (${hierarchyLabel.unidade || 'Unidade'})`} />}
              />
            </div>
            <div className='columns' style={{ padding: '0px 10px 0px 10px' }}>
              <Autocomplete
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.value}>
                    <span>{option.label}</span>
                  </li>)}
                className='column'
                noOptionsText={'Sem serviços correspondentes'}
                size="small"
                multiple
                value={servicoNome as { value: string, label: string }[]}
                id="disable-close-on-select"
                filterSelectedOptions
                options={data?.tiposServicos
                  ?.filter((elem) => !servicoNome?.some(service => service?.value === elem?.id))
                  ?.sort((a, b) => { return a.nome?.toLowerCase() > b.nome?.toLowerCase() ? 1 : - 1 })
                  ?.map((option) => ({
                    label: option.nome,
                    value: option.id,
                  }))}
                onChange={(event: any, newValue: { label: string, value: string }[] | null) => {
                  setServicoNome(newValue)
                }}
                renderInput={(params) => <TextField {...params} label={t('Reports.multipleServiceSelect')} />}
              />
            </div>
            <div className='columns' style={{ padding: '0px 10px 0px 10px' }}>
              <Autocomplete
                disabled={!identificadorOpcoes?.length}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.value}>
                    <span>{option.label}</span>
                  </li>)}
                className='column'
                noOptionsText={'Nenhum identificador encontrado'}
                size="small"
                multiple
                value={identificadorNome as { value: string, label: string }[]}
                id="disable-close-on-select"
                filterSelectedOptions
                options={identificadorOpcoes
                  ?.filter((elem) => !identificadorNome?.some(identifica => identifica?.label === elem))
                  ?.sort((a, b) => { return a?.toLowerCase() > b?.toLowerCase() ? 1 : - 1 })
                  ?.map((option) => ({
                    label: option,
                    value: option,
                  }))}
                onChange={(event: any, newValue: { label: string, value: string }[] | null) => {
                  setIdentificadorNome(newValue)
                }}
                renderInput={(params) => <TextField {...params} label={!identificadorOpcoes?.length ? 'Faça uma primeira consulta para poder filtrar por identificador' : t('Reports.multipleIdentifierSelect')} />}
              />
            </div>
            <div className='columns' style={{ padding: '0px 10px 0px 10px' }}>
              <label className='label column'>
                Por Pausa:
                <select
                  onClick={() => trackEventMatomo('Relatórios', 'click', 'select', 'seleciona valor')}
                  className='select is-fullwidth'
                  defaultValue={pausa === null ? 'both' : pausa ? 'paused' : 'finished'}
                  onChange={(e) => {
                    const value = e.target.value;
                    switch (value) {
                      case 'both':
                        setPausa(null)
                        break;

                      case 'paused':
                        setPausa(true)
                        break;

                      case 'finished':
                        setPausa(false)
                        break;

                      default:
                        setPausa(null)
                        break;
                    }
                  }}
                >
                  <option value="both">Formulários Pausados e Finalizados</option>
                  <option value="paused">Apeas Formulários Pausados</option>
                  <option value="finished">Apenas Formulários Finalizados</option>
                </select>
              </label>
              <label className='label column'>
                Por Status:
                <select
                  onClick={() => trackEventMatomo('Relatórios', 'click', 'select', 'seleciona valor')}
                  className='select is-fullwidth'
                  defaultValue={status === null ? 'both' : pausa ? 'active' : 'canceled'}
                  onChange={(e) => {
                    const value = e.target.value;
                    switch (value) {
                      case 'both':
                        setStatus(null)
                        break;

                      case 'active':
                        setStatus(true)
                        break;

                      case 'canceled':
                        setStatus(false)
                        break;

                      default:
                        setStatus(null)
                        break;
                    }
                  }}
                >
                  <option value="both">Formulários ativos e cancelados</option>
                  <option value="active">Apeas formulários ativos</option>
                  <option value="canceled">Apenas formulários cancelados</option>
                </select>
              </label>
              <label className='label column'>
                Por Delegação:
                <select
                  onClick={() => trackEventMatomo('Relatórios', 'click', 'select', 'seleciona valor')}
                  className='select is-fullwidth'
                  defaultValue={delegado === null ? 'both' : pausa ? 'delegado' : 'naoDelegado'}
                  onChange={(e) => {
                    const value = e.target.value;
                    switch (value) {
                      case 'both':
                        setDelegado(null)
                        break;

                      case 'delegado':
                        setDelegado(true)
                        break;

                      case 'naoDelegado':
                        setDelegado(false)
                        break;

                      default:
                        setDelegado(null)
                        break;
                    }
                  }}
                >
                  <option value="both">Formulários que foram e não foram delegados</option>
                  <option value="delegado">Apeas formulários que foram delegados</option>
                  <option value="naoDelegado">Apenas formulários que não foram delegados</option>
                </select>
              </label>
            </div>
            <div className='columns' style={{ padding: '0px 10px 10px 10px' }}>
              <div className='column columns' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <label className='label checkbox column' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <input
                    onClick={() => trackEventMatomo('Relatórios', 'click', 'input', 'edita valor')}
                    checked={filteringByScore}
                    type='checkbox'
                    onChange={(e) => setFilteringByScore(e.target.checked)}
                    style={{ marginRight: '5px' }}
                  />
                  Filtrar por range de Score?
                </label>
                <label className='label column'>
                  De:
                  <input
                    onClick={() => trackEventMatomo('Relatórios', 'click', 'input', 'edita valor')}
                    className='input is-fullwidth'
                    type="text"
                    onChange={(e) => {
                      if (!isNaN(Number(e.target.value)) && Number(e.target.value) >= 0 && Number(e.target.value) <= 100) {
                        setScoreDe(Number(e.target.value))
                      }
                    }}
                    disabled={!filteringByScore}
                    value={scoreDe}
                  />
                </label>
                <label className="label column">
                  Até:
                  <input
                    onClick={() => trackEventMatomo('Relatórios', 'click', 'input', 'edita valor')}
                    className='input is-fullwidth'
                    type="text"
                    onChange={(e) => {
                      if (!isNaN(Number(e.target.value)) && Number(e.target.value) >= 0 && Number(e.target.value) <= 100) {
                        setScoreAte(Number(e.target.value))
                      }
                    }}
                    disabled={!filteringByScore}
                    value={scoreAte}
                  />
                </label>
              </div>
            </div>
          </ Accordion>
        </FilterForm >
      </div >
      {
        !isLoading && canShow && (
          <>
            <ReportsTableComponent
              resetRows={resetRows}
              currentItems={filteredData}
              setItensPerPage={setItensPerPage}
              setPageNumber={setPageNumber}
              pageNumber={pageNumber}
              itensPerPage={itensPerPage}
              limit={totalItens}
            />
          </>
        )
      }
    </>
  )
}