import { Card, TextField } from '@material-ui/core';
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UserContext } from '../../../context/UserContext';
import { GenericHeader } from '../../../StyledComponents/Modal/generic';
import { TitleText } from '../../Teams/style/RegisteredTeamsStyle';
import { trackEventMatomo } from '../../../helpers/matomo';

export default function NewModal({
  setModalOpen,
  modalOpen,
  create,
  nameCreate,
  isDisabled
}
  : {
    setModalOpen: Function,
    modalOpen: boolean,
    create: Function,
    nameCreate: string
    isDisabled: boolean
  }) {
  const { openModal, escape } = useContext(UserContext);
  const { t } = useTranslation('translation');
  const { hierarchyLabel } = useContext(UserContext);
  const [name, setName] = useState('');

  return (
    <Card>
      <TitleText>
        <div className={`modal ${modalOpen ? "modal is-active" : "modal"}`}>
          <div
            className="modal-background"
            onKeyDown={() => {
              trackEventMatomo('Modal criação de conjunto/unidade', 'click', 'div', 'fecha modal')
              escape()
            }}
            onClick={() => {
              trackEventMatomo('Modal criação de conjunto/unidade', 'click', 'div', 'fecha modal')
              openModal()
            }} />
          <div className="modal-card" onSubmit={(e: React.FormEvent) => e.preventDefault()}>
            <header className="modal-card-head" style={{ display: "flex", flexDirection: "column" }}>
              <GenericHeader>
                Adicionar {nameCreate === 'conjunto' ? hierarchyLabel.conjunto : hierarchyLabel.unidade}
              </GenericHeader>
            </header>
            <div className="modal-card-body">
              <TextField
                onClick={() => trackEventMatomo('Modal criação de conjunto/unidade', 'click', 'textfield', 'editar valor')}
                style={{ marginBottom: '20px' }}
                fullWidth
                label={t('name')}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className='modal-card-foot' style={{ display: "flex", justifyContent: "space-between" }}>
              <button
                disabled={name === '' || isDisabled}
                className='button is-success is-small'
                onClick={() => {
                  trackEventMatomo('Modal criação de conjunto/unidade', 'click', 'button', 'salvar')
                  create(name)
                }}>
                {t('save')}
              </button>
              <button
                className='button is-warning is-small'
                onClick={() => {
                  trackEventMatomo('Modal criação de conjunto/unidade', 'click', 'button', 'fecha modal')
                  setModalOpen(false)
                }}>
                {t('cancel')}
              </button>
            </div>
          </div>
        </div>
      </TitleText>
    </Card>
  )
}
