import { useState } from "react";
import { Button } from "../../StyledComponents/Input/generic";
import { useMutation, useQuery } from "@tanstack/react-query";
import AttToken from "../../helpers/attToken";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { RiDeleteBin5Line, RiPencilFill } from "react-icons/ri";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import Table from "../Skeletons/Table";
import { tableOptions } from '../TableHelpers/options';
import { StyledDiv } from "../TableHelpers/TableStyle";
import { toast } from "react-toastify";
import queryClient from "../../service/query";
import { ThemeProvider } from '@mui/material/styles';
import { getMuiTheme } from "../TableHelpers/options";
import NovaNotificacao, { newNotificationType } from "../../service/NovaNotificacao";
import NewNotificationsModal from "./Modal/NewNotificationModal";
import { trackEventMatomo } from "../../helpers/matomo";

const notifService = new NovaNotificacao();

export default function NewNotifications() {
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [notificationEdit, setNotificationEdit] = useState<newNotificationType | undefined>(undefined)
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const { t } = useTranslation('translation');

  const { data, isLoading } = useQuery({
    queryKey: ['GET_NOTIFICATION_LIST'],
    queryFn: async () => {
      const token = await AttToken()
      if (token) {
        const response = await notifService.getAll(token)
        return response
      }
    },
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    onError: () => {
      console.log(AxiosError)
    }
  })

  const { mutate } = useMutation({
    mutationKey: ['DELETE_NOTIFICATION'],
    mutationFn: async (value: string) => {
      toast.warn(`Removendo modelo de notificação`)
      const token = await AttToken()
      if (token) {
        const response = await notifService.delete(token, value)
        return response
      }
    },
    onSuccess: () => {
      queryClient.resetQueries(['GET_NOTIFICATION_LIST'])
      toast.success(`Notificação removida`)
    },
    onError: () => {
      toast.error(`Erro ao remover notificação`)
    }
  })

  const columns = [
    {
      name: "name",
      label: t('name'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "message",
      label: t('message'),
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true
      }
    },
    {
      name: "id",
      label: t('edit'),
      options: {
        customBodyRender: (value: string) => {
          return (
            <button
              onClick={() => {
                trackEventMatomo('tela de notificação 2.0', 'click', 'button', 'abre modal')
                setIsEdit(true)
                setNotificationEdit(data?.find((elem: newNotificationType) => elem.id === value))
                setOpenModal(!openModal)
              }}
              className="button is-small"
            >
              <RiPencilFill />
              <span>{t('edit')}</span>
            </button>
          )
        },
        filter: true,
        sort: false,
      }
    },
    {
      name: "id",
      label: t('delete'),
      options: {
        customBodyRender: (value: string) => {
          return (
            <button
              onClick={() => {
                trackEventMatomo('tela de notificação 2.0', 'click', 'button', 'deleta')
                mutate(value)
              }}
              className="button is-small is-danger"
            >
              <RiDeleteBin5Line />
              <span>{t('delete')}</span>
            </button>
          )
        },
        filter: true,
        sort: false,
      }
    },
  ];

  const translatedTextLabels: MUIDataTableOptions = {
    textLabels: {
      body: {
        noMatch: `${t('table.noMatch')}`,
        toolTip: `${t('table.toolTip')}`,
        columnHeaderTooltip: column =>
          `${t('table.columnHeaderTooltip')} ${column.label}`
      },
      pagination: {
        next: `${t('table.next')}`,
        previous: `${t('table.previous')}`,
        rowsPerPage: `${t('table.rowsPerPage')}`,
        displayRows: `${t('table.displayRows')}`
      },
      toolbar: {
        search: `${t('table.search')}`,
        filterTable: `${t('table.filterTable')}`
      },
      filter: {
        title: `${t('table.title')}`,
        reset: `${t('table.reset')}`
      },
      viewColumns: {
        title: `${t('table.viewColumnsTitle')}`
      },
    },
  };

  return (
    <>
      <Button
        className="button is-small is-primary"
        style={{ fontWeight: "x-bold", fontSize: "20px" }}
        type="button"
        name='add'
        onClick={(event) => {
          trackEventMatomo('tela de notificação 2.0', 'click', 'button', 'abre modal')
          event.preventDefault()
          setIsEdit(false)
          setNotificationEdit(undefined)
          setOpenModal(!openModal)
        }}
      >
        Novo modelo de notificação +
      </Button>
      {openModal && (
        <NewNotificationsModal
          isEdit={isEdit}
          openModal={openModal}
          setOpenModal={setOpenModal}
          notificationEdit={notificationEdit}
          setNotificationEdit={setNotificationEdit}
        />
      )}
      {isLoading ? <Table /> : (
        <StyledDiv>
          <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              title={'Notificação modelo novo'}
              data={data}
              columns={columns}
              options={{ ...tableOptions, ...translatedTextLabels }}
            />
          </ThemeProvider>
        </StyledDiv>
      )}
    </>
  )
}