import axios, { AxiosError, AxiosResponse } from "axios";
import { AuthBodyType } from "./types";
import { toast } from "react-toastify";

export default class GetFilters {
  private api = axios.create({
    baseURL: `${process.env.REACT_APP_API_HOST}`,
  });

  getUsuarios = async (body: AuthBodyType, filtersForReports: boolean) => {
    try {
      const data: AxiosResponse = await this.api.post("/usuarios/getFilters", {
        ...body,
        filtersForReports,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error("Falha!");
        return error.response?.status;
      }
    }
  };

  getClientes = async (body: AuthBodyType) => {
    try {
      const data: AxiosResponse = await this.api.post("/clientes/getFilters", {
        ...body,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error("Falha!");
        return error.response?.status;
      }
    }
  };

  getLocais = async (body: AuthBodyType) => {
    try {
      const data: AxiosResponse = await this.api.post("/local/getFilters", {
        ...body,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error("Falha!");
        return error.response?.status;
      }
    }
  };

  getConjuntos = async (body: AuthBodyType) => {
    try {
      const data: AxiosResponse = await this.api.post("/conjunto/getFilters", {
        ...body,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error("Falha!");
        return error.response?.status;
      }
    }
  };

  getUnidades = async (body: AuthBodyType) => {
    try {
      const data: AxiosResponse = await this.api.post("/unidade/getFilters", {
        ...body,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error("Falha!");
        return error.response?.status;
      }
    }
  };

  getChamados = async (body: AuthBodyType, chamadosLegado: boolean) => {
    try {
      const data: AxiosResponse = await this.api.post("/chamados/getFilters", {
        ...body,
        chamadosLegado,
      });
      return data.data;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        console.error(error.message);
        console.log("erro --->", error);
        toast.error("Falha!");
        return error.response?.status;
      }
    }
  };
}
