import React, { useContext } from 'react'
import { GrCircleAlert } from 'react-icons/gr';
import { UserContext } from '../../context/UserContext';
import { trackEventMatomo } from '../../helpers/matomo';

export default function ModalInvalid({ openModal }: { openModal: Function }) {
  const { popUp, escape } = useContext(UserContext);

  return (
    <div>
      <div>
        <div className={`modal ${popUp ? "modal is-active" : "modal"}`}>
          <div className="modal-background" onKeyDown={() => {
            trackEventMatomo('dashboard', 'click', 'modal', 'fecha modal')
            escape()
          }} onClick={() => {
            trackEventMatomo('dashboard', 'click', 'modal', 'fecha modal')
            openModal()
          }} />
          <div className="modal-card" onSubmit={(e: React.FormEvent) => e.preventDefault()}>
            <header className="modal-card-head" style={{ display: "flex", flexDirection: "column" }}>
              <GrCircleAlert style={{ color: 'yellow', fontSize: '5em' }} />
            </header>
            <div className="modal-card-body" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <p style={{ fontSize: '2em', fontWeight: '700' }}>
                Empresa ainda não tem workspace registrado.
              </p>
              <button
                style={{ width: 'fit-content' }}
                onClick={() => {
                  trackEventMatomo('dashboard', 'click', 'button', 'fecha modal')
                  openModal()
                }}
                className='button is-danger is-medium'>
                ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}