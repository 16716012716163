import styled from "styled-components";

export const ModalCardbody = styled.section`
  display: flex;
  flex-direction: column;
`;

export const ModalCard = styled.div`
  //max-width: 700px;
  display: flex;
  min-height: 500px;
  select {
    background-color: white;
    border: 1px solid #dbdbdb;
  }
`;
export const GenericHeader = styled.h1`
  padding: 5px;
  color: grey;
  font-size: x-large;
`;
export const GenericText = styled.span`
  padding: 5px;
  color: grey;
  font-size: small;
`;
export const ModalTitleDiv = styled.div`
  background-color: #f8fafb;
  border-bottom: 1px solid #cccccc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
`;
export const TagSpan = styled.span`
  background-color: lightgray;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin: 5px 5px 5px 0;
  align-self: flex-start;
  font-size: 13px;
  text-align: initial;
  word-wrap: break-word;
  word-break: break-word;
  :hover {
    cursor: pointer;
  }
`;

export const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 6px;
  max-width: 80%;
  flex-wrap: wrap;
`;
