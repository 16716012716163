import { trackEventMatomo } from "../../../../../helpers/matomo"

type props = {
  cancel: boolean,
  setCancel: Function,
  setOpenModal: Function,
  save: Function
}

export default function ModalSave({ cancel, setCancel, setOpenModal, save }: props) {

  return (
    <div className={`${cancel ? "modal is-active" : "modal"}`}>
      <div className="modal-background"></div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="modal-card">
        <section style={{ height: '10%', width: '50%' }} className="modal-card-body">
          <b>
            Atenção!<br />
          </b>
          Deseja salvar essa assinatura?
          <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'end', color: '#A400CF', fontWeight: '700', height: '50%' }}>
            <span onClick={() => {
              trackEventMatomo('preenchimento de assinatura', 'click', 'button', 'cancela')
              setCancel(false)
            }} >NÃO</span><span onClick={() => {
              trackEventMatomo('preenchimento de assinatura', 'click', 'button', 'salvar')
              setCancel(true); setOpenModal(false); save()
            }}>SIM</span>
          </div>
        </section>
      </div>
    </div>
  )
}
