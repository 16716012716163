import SignatureCanvas from 'react-signature-canvas';
import CloseIcon from '@mui/icons-material/Close';
import ReplayIcon from '@mui/icons-material/Replay';
import CheckIcon from '@mui/icons-material/Check';
import { Button } from './AssinaturaStyle';
import { useEffect, useRef, useState } from 'react';
import ModalCancel from './ModalCancel';
import { SubItemFormFilling } from '../../../Types';
import ModalSave from './ModalSave';
import ModalReassign from './ModalReassign';
import { trackEventMatomo } from '../../../../../helpers/matomo';

type props = {
  openModal: boolean,
  setOpenModal: Function,
  subItem: SubItemFormFilling,
  olhar: boolean,
  setOpen: Function,
  setOlhar: Function,
  onChange?: () => void
}

export default function ModalSignature({ openModal, setOpenModal, subItem, olhar, setOpen, setOlhar, onChange }: props) {
  const sigRef = useRef() as React.MutableRefObject<any>;
  const [cancel, setCancel] = useState(false);
  const [save, setSave] = useState(false);
  const [signature, setSignature] = useState<string | undefined>()

  const handleSignatureEnd = () => {
    setSignature(sigRef.current.toDataURL())
    subItem.assinatura = sigRef.current.toDataURL()
    setOpenModal(false)
  }

  const clearSignature = () => {
    sigRef.current.clear();
    setSignature(undefined)
  }

  const cancelSingature = () => {
    setCancel(false)
  }

  const saveSignature = () => {
    setSave(false)
  }

  useEffect(() => {
    onChange && onChange()
  }, [signature])

  return (
    <div className={`${openModal ? "modal is-active" : "modal"}`}>
      {save && <ModalSave cancel={save} setCancel={saveSignature} setOpenModal={setOpenModal} save={handleSignatureEnd} />}
      {cancel && <ModalCancel cancel={cancel} setCancel={cancelSingature} setOpenModal={setOpenModal} clear={clearSignature} />}
      <div className="modal-background"></div>
      <div className="modal-card">
        <header style={{ display: 'flex', justifyContent: 'space-around' }} className="modal-card-head">
          <Button onClick={signature === undefined ? () => {
            trackEventMatomo('preenchimento de assinatura', 'click', 'button', 'cancelar')
            setCancel(true)
          } : () => {
            trackEventMatomo('preenchimento de assinatura', 'click', 'button', 'abrir modal')
            setOpenModal(false); setOlhar(false); setOpen(); handleSignatureEnd()
          }}
            className='button is-small'>
            <CloseIcon />
          </Button>
          {!olhar &&
            <Button onClick={() => {
              trackEventMatomo('preenchimento de assinatura', 'click', 'button', 'abrir modal')
              clearSignature()
            }}
              className='button is-small'>
              <ReplayIcon />
            </Button>}
          {!olhar && <Button onClick={() => {
            trackEventMatomo('preenchimento de assinatura', 'click', 'button', 'salvar')
            setSave(true); setOpen(false)
          }} className='button is-small'><CheckIcon /></Button>}
        </header>
        <section style={{ height: '100svh' }} className="modal-card-body">
          <SignatureCanvas ref={sigRef} penColor='black' canvasProps={{ width: 600, height: 600, className: 'sigCanvas' }} />
        </section>
      </div>
    </div >
  )
}
