import { GenericHeader } from "../../../StyledComponents/Modal/generic";
import AttToken from "../../../helpers/attToken";
import { useState } from "react";
import Tickets, { TicketsType } from "../../../service/Tickets";
import { toast } from "react-toastify";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { ButtonsDiv, MotivoSpan, NameSpan } from "../style/AllNotificationsStyle";
import { trackEventMatomo } from "../../../helpers/matomo";

type props = {
  open: boolean,
  setOpen: Function,
  chamados: TicketsType[] | undefined
}
const ticketsService = new Tickets();

export default function RedirectListModal({
  open,
  setOpen,
  chamados

}: props) {
  const [showAccpet, setShowAccept] = useState(true);
  const [showReject, setShowReject] = useState(true);

  const acceptRejectTicket = async (action: 'rejeita' | 'aceita', chamadoId: string) => {
    const token = await AttToken();
    toast.warn('Fazendo ação...')
    if (token) {
      await ticketsService.redirecionaChamado(token, chamadoId, null, null, action)
      if (action === 'aceita') toast.success('Chamado Aceito!')
      else toast.success('Chamado Rejeitado!')
    } else {
      toast.error('Erro na ação!');
      setOpen(false);
    }
  } 

  return (
    <div className={`modal ${open ? "modal is-active" : "modal"}`}>
      <div className="modal-background"></div>
      <div className="modal-card"  style={{ height: '450px' }}>
        <header style={{ display: 'flex', justifyContent: 'center' }} className="modal-card-head">
          <GenericHeader>Chamados Redirecionados</GenericHeader>
        </header>
        <div className="modal-card-body">
          {chamados && chamados.map((ticket) => (
            <div style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                backgroundColor: '#f0f0f0',
                border: 'none',
                borderRadius: '0 0 20px 20px',
                margin: '10px',
                flexDirection: 'column',
              }}>
              <NameSpan>{ticket.nome.toLocaleLowerCase()}</NameSpan>
              <div style={{ marginTop: '5px', marginBottom: '10px', width: '90%', wordBreak: 'break-all' }}>
                <MotivoSpan>Motivo para Redirecionamento:</MotivoSpan>
                <span style={{ maxWidth: '70%' }}>{ticket.motivoEncaminhamento}</span>
              </div>
              <ButtonsDiv>
                <button
                  className="accept"
                  onMouseEnter={() => setShowReject(false)}
                  onMouseLeave={() => setShowReject(true)}
                  onClick={() => {
                    trackEventMatomo('modal redireciona chamado', 'click', 'buttob', 'redireciona')
                    acceptRejectTicket('aceita', ticket.id);
                  }}
                  style={{ width: showAccpet ? '100%' : '5%'}}
                >
                  <AiOutlineCheck />
                </button>
                <button
                  className="reject"
                  onMouseEnter={() => setShowAccept(false)}
                  onMouseLeave={() => setShowAccept(true)}
                  onClick={() => {
                    trackEventMatomo('modal redireciona chamado', 'click', 'buttob', 'cancela')
                    acceptRejectTicket('rejeita', ticket.id);
                  }}
                  style={{ width: showReject ? '100%' : '5%'}}
                >
                  <AiOutlineClose />
                </button>
              </ButtonsDiv>
            </div>
          ))}
        </div>
        <footer className='modal-card-foot' style={{ display: "flex", justifyContent: "center" }}>
          <button
            className="button is-danger"
            onClick={() => {
              trackEventMatomo('modal redireciona chamado', 'click', 'buttob', 'fecha')
              setOpen(false)
            }}>Fechar</button>
        </footer>
      </div>
    </ div>
  )
}
