export type avaliantionType = {
  id: number,
  text: string,
  checked: boolean
}

export type FormSubitemType = {
  id: number,
  name: string,
  questionType: string,
  mandatory: boolean,
  defaultValue?: string,
  optQty: number,
  allowPhoto: boolean,
  allowComent: boolean,
  weight: number,
  allowGallaryphoto: boolean,
  fieldNotApply: boolean,
  according: {
    mandatoryPhoto: boolean,
    mandatoryComent: boolean,
  },
  notAccording: {
    mandatoryPhoto: boolean,
    mandatoryComent: boolean,
  }
  help: {
    sendPhoto: boolean,
    sendVideo: boolean,
    description: string,
  },
  avaliations: avaliantionType[]
}

export type FormItemType = {
  id: number,
  isExpanded: boolean,
  name: string,
  weight: number,
  subItemsList: FormSubitemType[]
}

export type FormType = {
  id: number,
  name: string,
  isExpanded: boolean,
  createdBy: string,
  createdAt: Date,
  updatedAt: Date,
  version: number,
  identifier: string,
  weight: number,
  isActive: boolean,
  clonable: boolean,
  allowCancel: boolean,
  rangeScoring: boolean,
  general: {
    associetedCustomer: string,
    tickets: string[],
    ticketsLimit: number,
    mandatoryTicket: boolean,
    takeLocation: boolean,
    locationLimit: number,
    validTime?: string,
    numberOfDays: number,
    allowQrCode: boolean,
    allowFormDelegation: boolean
  },
  subitemConfig: {
    associetedClient: string,
    subitem: string,
    tickets: string[],
    ticketsLimit: number,
    mandatoryTicket: string,
  },
  itemsList: FormItemType[]
}

export const initialSubitem: FormSubitemType = {
  id: 0,
  name: 'Subitem',
  questionType: 'Avaliação',
  mandatory: false,
  defaultValue: '',
  optQty: 0,
  allowPhoto: false,
  allowComent: false,
  weight: 0,
  allowGallaryphoto: false,
  fieldNotApply: false,
  according: {
    mandatoryPhoto: false,
    mandatoryComent: false,
  },
  notAccording: {
    mandatoryPhoto: false,
    mandatoryComent: false,
  },
  help: {
    sendPhoto: false,
    sendVideo: false,
    description: '',
  },
  avaliations: [{ id: 0, text: '', checked: false}, { id: 0, text: '', checked: false}]
}

export const initialItem: FormItemType = {
  id: 0,
  isExpanded: false,
  name: 'Item (clique para alterar)',
  weight: 0,
  subItemsList: [initialSubitem]
}

export const InitialFormItem: FormType = {
  id: 0,
  isExpanded: false,
  name: '',
  createdBy: '',
  createdAt: new Date(),
  updatedAt: new Date(),
  version: 0,
  identifier: '',
  weight: 0,
  isActive: false,
  clonable: false,
  allowCancel: false,
  rangeScoring: false,
  general: {
    associetedCustomer: '',
    tickets: [''],
    ticketsLimit: 0,
    mandatoryTicket: false,
    takeLocation: false,
    locationLimit: 0,
    validTime: '',
    numberOfDays: 0,
    allowQrCode: false,
    allowFormDelegation: false,
  },
  subitemConfig: {
    associetedClient: '',
    subitem: '',
    tickets: [''],
    ticketsLimit: 0,
    mandatoryTicket: ''
  },
  itemsList: [initialItem]
}

export type PrintTemplateType = {
  id?:string,
  name: string;
  dafaultTemplate?: boolean;
  cliente?: string;
  empresa?: string,
  headerText?: {
    bold?: boolean;
    italic?: boolean;
    size?: number;
    text?: string;
  };
  footerText?: {
    bold?: boolean;
    italic?: boolean;
    size?: number;
    text?: string;
  };
  color?: string;
  logo?: string;
  status: boolean;
}

export const initialPrintTemplate = {
  name: 'Novo template',
  dafaultTemplate: false,
  cliente: '',
  headerText: {
    bold: false,
    italic: false,
    size: 10,
    text: '',
  },
  footerText: {
    bold: false,
    italic: false,
    size: 10,
    text: '',
  },
  color: "#fffff",
  logo: '',
  status: false,
}
