import { PrintTemplateType } from "../../Checklists/Types";
import '../styles.css'

export default function FooterModalDetailPrint({
  printTemplate,
  checkBrightness
}: {
  printTemplate?: PrintTemplateType | undefined,
  checkBrightness: Function
}) {
  return (
    <tfoot className="tfoot">
      <tr>
        <th
          className="th2"
          style={{ backgroundColor: printTemplate?.color }}>
          <th
            style={{
              border:'none',
              fontSize: `${printTemplate?.footerText?.size}px`,
              fontWeight: printTemplate?.footerText?.bold ? 'bold' : 500,
              fontStyle: printTemplate?.footerText?.italic ? 'italic' : 'normal',
              color: (!printTemplate?.color ? 'black' : printTemplate?.color && checkBrightness(printTemplate?.color) ? 'black' : 'white')
            }}
          >
            {printTemplate?.footerText?.text}
          </th>
        </th>
      </tr>
    </tfoot>
  )
}
