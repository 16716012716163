import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';

class CheckToken {

  private api = axios.create( {
    baseURL: `${process.env.REACT_APP_API_HOST}/login`
  });

  validateToken = async (token: string) => {
    try {
      const response = await this.api.post('/validateToken', { userToken: token });
      return response;
    } catch (err) {
      const error = err as Error | AxiosError;
      if(axios.isAxiosError(error)){
        console.log('erro --->', error.response, err)
        return false;
      }
    }
  }
}

export default CheckToken;
