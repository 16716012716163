import { useQuery } from "@tanstack/react-query";
import { GenericHeader } from "../../../StyledComponents/Modal/generic";
import AttToken from "../../../helpers/attToken";
import { useState } from "react";
import Users from "../../../service/Users";
import { AxiosError } from "axios";
import Select from "../../Skeletons/Select";
import { userBackObj } from "../../Users/UserSearch";
import { useTranslation } from "react-i18next";
import { Label } from "../style/SingleNotificationStyle";
import Tickets from "../../../service/Tickets";
import { toast } from "react-toastify";
import { trackEventMatomo } from "../../../helpers/matomo";

type props = {
  openRedirect: boolean,
  setOpenRedirect: Function,
  chamadoId: string
}

const usersService = new Users();
const ticketsService = new Tickets();

export default function RedirectModal({
  openRedirect,
  setOpenRedirect,
  chamadoId

}: props) {
  const { t } = useTranslation('translation');
  const [encaminhadoPara, setEncaminhadoPara] = useState<string>('');
  const [motivoEncaminhamento, setMotivoEncaminhamento] = useState<string>('');

  const {
    data,
    isLoading,
  } = useQuery({
    queryKey: ['Users'],
    queryFn: async () => {
      const token = await AttToken();
      if (token) {
        const body = { ...token }
        const result = await usersService.getAllUsers(body);
        return result.users
      }
    },
    keepPreviousData: true,
    retry: 5,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    onError: () => {
      console.log(AxiosError)
    }
  });

  const handleClickRedireciona = async () => {
    const token = await AttToken();
    if (token) {
      toast.warn('Redirecionando Chamado...')
      await ticketsService.redirecionaChamado(token, chamadoId, motivoEncaminhamento, encaminhadoPara, 'redireciona')
      toast.success('Chamado Redirecionado')
      setOpenRedirect()
    } else {
      setOpenRedirect()
      toast.error('Erro ao redirecionar')
    }
  }

  return (
    <div className={`modal ${openRedirect ? "modal is-active" : "modal"}`}>
      <div className="modal-background"></div>
      <div className="modal-card" style={{ height: '450px' }}>
        <header style={{ display: 'flex', justifyContent: 'center' }} className="modal-card-head">
          <GenericHeader>Redirecionar chamado</GenericHeader>
        </header>
        <div className="modal-card-body">
          <Label>Selecione o usuário:</Label>
          {isLoading ? <Select /> :
            <div className="select is-small is-fullwidth">
              <select
                onClick={() => trackEventMatomo('modal redireciona chamado', 'click', 'select', 'selciona valor')}
                onChange={(e) => {
                  setEncaminhadoPara(e.target.value);
                }}>
                <option value='' >{t('select')}</option>
                {data.sort((a: userBackObj, b: userBackObj) => { return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : - 1 })
                  .filter((e: userBackObj) => e.status === 'ativo')
                  .map((e: userBackObj, i: number) => (
                    <option value={e.email} key={i}>{e.nome}</option>
                  ))}
              </select>
              <Label>Escreva o porquê está redirecionando o chamado:</Label>
              <textarea
                onClick={() => trackEventMatomo('modal redireciona chamado', 'click', 'textarea', 'edita valor')}
                className="textarea"
                value={motivoEncaminhamento}
                onChange={(e) => setMotivoEncaminhamento(e.target.value)}
                style={{ resize: 'none' }}
              />
            </div>}
        </div>
        <footer className='modal-card-foot' style={{ display: "flex", justifyContent: "space-between" }}>
          <button onClick={() => {
            trackEventMatomo('modal redireciona chamado', 'click', 'button', 'redireciona')
            handleClickRedireciona();
          }} className="button is-success">Redirecionar</button>
          <button
            className="button is-danger"
            onClick={() => {
              trackEventMatomo('modal redireciona chamado', 'click', 'button', 'cancela')
              setOpenRedirect()
            }}>Cancelar</button>
        </footer>
      </div>
    </ div>
  )
}
